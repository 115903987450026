import React from "react";
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import { Provider } from 'react-redux';
import store from '../store.js';
// import socketIOClient from "socket.io-client/dist/socket.io";
import { SERVER } from '../variables/CONTRACT_ADDRESSES.js';

// web3
// import { ethers } from 'ethers';

// CSS
import "assets/css/m_w.css";

// reactstrap Components
import { Container, Row, Col } from "reactstrap";

// Components (+HOC)
import withRouter from "../views/WithRouterHOC.jsx";
// import MainSite from "./MemeWarsSite.jsx"; 
import MainSite from "./MainSite.jsx";
// import ContributorsArea from "components/ContributorsArea/ContributorsArea.jsx";

// RainbowKit Imports
import '@rainbow-me/rainbowkit/styles.css';
import { Chain } from 'wagmi'
// import { base, baseSepolia } from 'viem/chains'

import {
  getDefaultWallets,
  connectorsForWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { walletConnectWallet, rainbowWallet, metaMaskWallet, coinbaseWallet } from '@rainbow-me/rainbowkit/wallets';
// import { rainbowWeb3AuthConnector } from "./RainbowWeb3authConnector";
// import { TorusConnector } from "@toruslabs/torus-wagmi-connector";
import { configureChains, createClient, createStorage, WagmiConfig } from 'wagmi';
// TODO: import base and baseSepolia from 'viem/chains';
import { mainnet, polygon, optimism, arbitrum, goerli, localhost } from 'wagmi/chains';
// additional chains in wagmi format not supportable without breaking upgrades to wagmi / rainbowkit
import { baseSepolia, base } from '../variables/chains.js'
import { InjectedConnector } from 'wagmi/connectors/injected';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

const { chains, provider, webSocketProvider } = configureChains(
  // [mainnet, polygon, optimism, arbitrum, goerli, localhost],
  [mainnet, localhost, goerli, baseSepolia, base],
  [
    // alchemyProvider({ apiKey: process.env.ALCHEMY_ID }),
    publicProvider(),
  ]
);

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [rainbowWallet({ chains }), 
              walletConnectWallet({ chains }), 
              metaMaskWallet({ chains }), 
              coinbaseWallet({ chains }),
      // rainbowWeb3AuthConnector({ chains })
    ],
  },]);

const wagmiClient = createClient({
  autoConnect: false,
  storage: createStorage({ storage: window.localStorage }), // TODO: What is being stored?
  // connectors: [connectors, new InjectedConnector({ chains })],
  connectors,
  provider,
  webSocketProvider
})

// console.log("wagmiClient: ")
// console.log(wagmiClient)

// const axios = require('axios'); // TODO: use fetch() instead ?

const dotenv = require('dotenv').config(); // TODO: use instead of CONTRACT_ADDRESSES for global vars?

// Global variables
var socket;     // socket.io client <--> server connection
var firstVisit; // true if user's first site visit TODO (make rules pop up each time unless user doesn't want)

class App extends React.Component {
  state = {
    numPlayersOnline: 0, // TODO: fetch from express serve port 5000
    // TODO: could put ipfs link to download past matches here?
    serverEndpoint: SERVER,
    matchesAddress: "",
    // urlExtension: "", // represents the url <extension> (e.g. memewa.rs/<denver?code=0x1234>) that the user is currently on
  };

  componentDidMount() {
    document.body.classList.add("index-page");
    const { serverEndpoint } = this.state;

    // TODO: Uncomment below when working on server again (search here and in MemewarsSite.jsx: SERVERTODO to re-enable)
    // socket = socketIOClient(SERVER);
    // socket.on("FromAPI", data => this.setState({numPlayersOnline: data}));
    // socket.on("contractAddress", data => this.setState({matchesAddress: data}));

    // Check if this is site user's first visit (TODO: use to determine if greeting modal appears)
    firstVisit = localStorage.getItem('firstVisit') == null;
    // console.log("first Visit: " + firstVisit);
    localStorage.setItem('firstVisit', firstVisit);

    // // get urlExtension 
    // const location = useLocation();
    // console.log("this.props.router")
    // console.log(this.props.router)
    // const { search } = location;
    // const params = new URLSearchParams(search);
    // const code = params.get('code');
    // console.log("location: " + this.props.location)
    // console.log("code: " + code)
    // // const { search } = this.props.location;
    // // const params = new URLSearchParams(search);
    // // const myParam = params.get("myParam");
    // console.log("search: " + search);
    // // console.log("params: " + params);
    // // console.log("myParam: " + myParam);
    // this.setState({urlExtension: params});
  }

  componentDidUpdate() {
    // console.log("players online:");
    // console.log(this.state.numPlayersOnline);
  }

  componentWillUnmount() {
    document.body.classList.remove("index-page");
    // SERVERTODO
    // socket.disconnect();
  }

  // Fetches our GET route from the Express server. (Note the route we are fetching matches the GET route from server.js
  // callBackendAPI = async () => {
  //   const response = await fetch('/express_backend');
  //   const body = await response.json();

  //   if (response.status !== 200) {
  //     throw Error(body.message) 
  //   }
  //   return body;
  // };

  render() {

    // get URL arguments from Browser
    const params = this.props.params
    // console.log("params: ")
    // console.log(params)
    const location = this.props.location
    // console.log("location: ")
    // console.log(location)
    const navigate = this.props.navigate
    // console.log("navigate: ")
    // console.log(navigate)

    const search = location.search  // ex: ?code=0x1234
    const nftCode = search.substring(search.indexOf("=") + 1);
    const urlPath = location.pathname.toString() // ex: /beta
    // const viewAddress = urlPath.substring(search.indexOf("/0x"));
    const viewAddress = urlPath.split('/u/')[1];
    // console.log("nftCode: " + nftCode)
    // console.log("urlPath: " + urlPath)
    // console.log("viewAddress: " + viewAddress)

    const siteProps = {
      nftCode,
      urlPath,
      firstVisit,
      socket,
      matchesContractAddress: this.state.matchesAddress,
      numPlayers: this.state.numPlayersOnline,
      urlExtension: this.state.urlExtension,
      viewAddress,
    };

    return (
      // <BrowserRouter> 
        <WagmiConfig client={wagmiClient}>
          <RainbowKitProvider chains={chains}>
            <Provider store={store}>
            <Routes>
              <Route path="*" element={<MainSite path={urlPath} {...siteProps} />} />
            </Routes>
            </Provider>
          </RainbowKitProvider>
        </WagmiConfig>
      // </BrowserRouter>
    );
  }
}

export default withRouter(App);



// TODO: trying to get Torus into the Wagmi modal --> could simplify a lot of the code which detects differences between Torus and wagmi in contractScripts + other places
// https://github.com/torusresearch/torus-wagmi-connector
// const connectors = 
//   new TorusConnector({ 
//     chains: chains,
//     options: {
//       chainId: "0x1",
//       host: "mainnet",
//     },
//   });



//


// version where urlPath is detected within MemewarsSite, avoiding a total re-render:

// render() {

//   // get URL arguments from Browser
//   const params = this.props.params
//   // console.log("params: ")
//   // console.log(params)
//   const location = this.props.location
//   // console.log("location: ")
//   // console.log(location)
//   const navigate = this.props.navigate
//   // console.log("navigate: ")
//   // console.log(navigate)

//   // const search = location.search  // ex: ?code=0x1234
//   // const nftCode = search.substring(search.indexOf("=") + 1);
//   // const urlPath = location.pathname.toString() // ex: /beta
//   // const viewAddress = urlPath.substring(search.indexOf("/0x") - 2);
//   // console.log("nftCode: " + nftCode)
//   // console.log("urlPath: " + urlPath)
//   console.log("viewAddress: " + viewAddress)

//   const siteProps = {
//     // nftCode,
//     // urlPath,
//     firstVisit,
//     socket,
//     matchesContractAddress: this.state.matchesAddress,
//     numPlayers: this.state.numPlayersOnline,
//     // urlExtension: this.state.urlExtension,
//     // viewAddress,
//   };
// SurveyResults.jsx:

import React, { Component } from 'react';
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  FormGroup,
  Label,
  Input,
  Form,
  Card,
  CardHeader,
  CardBody,
  FormText,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Alert,
  CustomInput,
  Table,
  Progress
} from 'reactstrap';
import Select from 'react-select';

// Importing styles
import styles from './SurveyResults.module.scss';
import "../../assets/css/m_w.css";
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faCaretUp, faCaretDown, faCheck, faLock, faTimes, faArrowLeft, faArrowRight, faQuestionCircle, faSpinner, faSearch, faExpand, faExternalLinkAlt, faFilter, faExclamationCircle, faClipboard, faCog } from '@fortawesome/free-solid-svg-icons';

// Crypto Functionality (encrypt/decrypt) and variables
import { ARWEAVE_ACTIVE } from '../../variables/CONTRACT_ADDRESSES';
import contractScripts from '../Buttons/contractScripts.js';
import { encrypt } from '@metamask/eth-sig-util';
import { ethers, utils } from 'ethers';
import sha256 from 'crypto-js/sha256';
import Slider from 'react-rangeslider';
import proposalScripts from 'components/UpcomingMatches/proposalScripts';

// SBTs
import SBTFilter from '../SBTs/SBTFilter.jsx';

// QuestionFilter
import QuestionFilter from './QuestionFilter';

// Import the new PolisReport component
import PolisReport from '../PolisReport/PolisReport';

// Import the new SingleQuestionResponse component
import SingleQuestionResponse from './SingleQuestionResponse';

/**
 * Helper to unify aggregator keys in lowercase and ensure zero-response QIDs are included if desired.
 * We also merge any repeated QIDs (e.g., uppercase vs. lowercase) into one array of responses.
 */
function unifyAggregatorWithAllQuestionIDs(baseAggregator, allKnownQuestionIds = []) {
  // 1) Force aggregator keys to lowercase, merging as needed
  const loweredMap = {};
  for (const key of Object.keys(baseAggregator)) {
    const lowerKey = key.toLowerCase();
    // Merge arrays if key already exists
    if (!loweredMap[lowerKey]) {
      loweredMap[lowerKey] = baseAggregator[key];
    } else {
      loweredMap[lowerKey] = loweredMap[lowerKey].concat(baseAggregator[key]);
    }
  }

  // 2) Make sure we have an entry for zero-response question IDs if allKnownQuestionIds provided:
  // This ensures that questions with zero responses remain visible.
  for (const qId of allKnownQuestionIds) {
    const qLower = qId.toLowerCase();
    if (!loweredMap[qLower]) {
      loweredMap[qLower] = []; // empty array for no responses
    }
  }

  return loweredMap;
}

class SurveyResults extends Component {
  constructor(props) {
    super(props);
    // We also track bookmarkedSurveyIDs for the new bookmark feature
    const localBookmarks = JSON.parse(localStorage.getItem('bookmarks')) || { questionIDs: [], surveyIDs: [] };

    this.state = {
      responses: [],
      sbtFilteredResponses: [],
      csvData: '',
      exportType: 'Polis Report',
      activeToggles: {},
      modalOpen: true,
      alertMessage: '',
      loading: false,
      surveyTitle: '',
      surveyId: '',
      activeQuestionToggles: {},
      questionResponses: {},
      sbtFilteredQuestionResponses: {},
      aggregatorQuestionResponses: {},
      sbtFilteredAggregatorQuestionResponses: {},
      viewMode: this.props.viewMode,
      filterLoading: false,
      showQuestionFilter: false,
      filterState: this.props.filterState || {},
      bookmarkedQuestionIDs: JSON.parse(localStorage.getItem('bookmarks'))?.questionIDs || [],
      // New line for bookmarked surveys
      bookmarkedSurveyIDs: localBookmarks.surveyIDs || [],
      questionIdSortBy: 'responses',
      questionIdSortAsc: true,
      totalQuestionsCount: 0,
      filteredQuestionsCount: 0,
      totalResponsesCount: 0,
      filteredResponsesCount: 0,
      surveyViewMode: 'individuals',
      aggregateQuestionResponses: {},
      sbtFilteredAggregateQuestionResponses: {},
      polisReportSelected: false,

      // Partial / chunk-based progress
      partialLoading: false,
      partialProgress: 0,
      partialTotal: 0,
      networkLatestBlock: 0
    };

    this.questionIdTableRef = React.createRef();
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    window.addEventListener('popstate', this.handleUrlChange);
    this.handleUrlBasedView();

    // 1) Attempt to load aggregator or responses from localStorage first (preload),
    //    so we can show all QIDs (including zero-response) from the start:
    if (this.state.viewMode === 'survey' && this.props.surveyId) {
      await this.preloadSurveyModeAggregatorFromCache(this.props.surveyId.toLowerCase());
    } else if (this.state.viewMode === 'questions') {
      await this.preloadQuestionModeAggregatorFromCache();
    }

    // 2) Then fetch fresh data from chain:
    await this.fetchResponses();

    // 3) If we have an initial filterState from props, apply question filter logic if in question mode:
    if (this.state.viewMode === 'questions' && this.state.filterState) {
      this.applyQuestionModeFilterOnMount();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('popstate', this.handleUrlChange);
  }

  handleUrlChange = () => {
    this.handleUrlBasedView();
  };

  handleUrlBasedView = () => {
    const path = window.location.pathname;
    let newViewMode = 'survey';
    if (path === '/questions' || path.includes('/questions')) {
      newViewMode = 'questions';
    }
    if (this.state.viewMode !== newViewMode) {
      this.setState({ viewMode: newViewMode }, this.fetchResponses);
    }
  };

  applyQuestionModeFilterOnMount = () => {
    const { questionResponses } = this.state;
    const allQuestions = Object.keys(questionResponses).map(qId => {
      let q = null;
      let networkID = this.props.network?.id?.toString() || '';
      let questionsCacheStr = localStorage.getItem('questionsCache');
      let questionsCache = {};
      if (questionsCacheStr) {
        try {
          questionsCache = JSON.parse(questionsCacheStr);
        } catch (e) {
          console.error('Error parsing questionsCache in applyQuestionModeFilterOnMount:', e);
          questionsCache = {};
        }
      }
      if (questionsCache[networkID] && questionsCache[networkID].questions && questionsCache[networkID].questions[qId]) {
        q = questionsCache[networkID].questions[qId];
      } else {
        q = { id: qId, creator: '', type: '', prompt: '' };
      }
      return q;
    });
    this.forceUpdate();
  };

  parseResponse = (responseData) => {
    try {
      return typeof responseData === 'string' ? JSON.parse(responseData) : responseData;
    } catch (error) {
      console.error('Error parsing response data:', error);
      return null;
    }
  };

  /**
   * preloadSurveyModeAggregatorFromCache(surveyIdLower) - loads aggregator info from localStorage surveysCache
   * so that we have QIDs before we fetch from chain. This helps display questions with zero responses, etc.
   */
  async preloadSurveyModeAggregatorFromCache(surveyIdLower) {
    if (!this.props.network || !this.props.network.id) {
      return;
    }
    const networkID = this.props.network.id.toString();
    let surveysCacheStr = localStorage.getItem('surveysCache');
    if (!surveysCacheStr) {
      return;
    }
    let surveysCache;
    try {
      surveysCache = JSON.parse(surveysCacheStr);
    } catch (e) {
      console.error('Error parsing surveysCache in preloadSurveyModeAggregatorFromCache:', e);
      return;
    }
    if (!surveysCache[networkID] || !surveysCache[networkID].surveys[surveyIdLower]) {
      return;
    }

    const surveyData = surveysCache[networkID].surveys[surveyIdLower];
    if (!surveyData || !surveyData.questionIDs) {
      return;
    }

    // unify aggregator with known questionIDs for that survey:
    let allSurveyQIDs = surveyData.questionIDs.map(q => q.toLowerCase());

    // Also unify any local question IDs from questionsCache that share this associatedSurveyId
    let questionsCacheStr = localStorage.getItem('questionsCache');
    if (questionsCacheStr) {
      try {
        const questionsCache = JSON.parse(questionsCacheStr);
        if (questionsCache[networkID] && questionsCache[networkID].questions) {
          for (const qId in questionsCache[networkID].questions) {
            const qObj = questionsCache[networkID].questions[qId];
            if (
              qObj.associatedSurveyId &&
              qObj.associatedSurveyId.toLowerCase() === surveyIdLower
            ) {
              allSurveyQIDs.push(qId.toLowerCase());
            }
          }
        }
      } catch (err) {
        console.error('Error parsing questionsCache in preloadSurveyModeAggregatorFromCache(merge step):', err);
      }
    }

    let aggregatorMap = {};
    const srMap = surveysCache[networkID].surveyResponses[surveyIdLower] || {};
    const rawResponses = Object.keys(srMap).map(responder => ({
      responder,
      surveyId: surveyIdLower,
      response: srMap[responder]
    }));

    rawResponses.forEach(resp => {
      const parsed = this.parseResponse(resp.response);
      if (parsed && parsed.responses) {
        parsed.responses.forEach(r => {
          const qIdLower = (r.questionID || '').toLowerCase();
          if (!aggregatorMap[qIdLower]) {
            aggregatorMap[qIdLower] = [];
          }
          aggregatorMap[qIdLower].push({
            responder: resp.responder,
            questionId: qIdLower,
            response: JSON.stringify(r),
            timestamp: r.timeStamp || parsed.timeStamp || Date.now()
          });
        });
      }
    });

    const unifiedMap = unifyAggregatorWithAllQuestionIDs(aggregatorMap, allSurveyQIDs);
    const allQIDs = Object.keys(unifiedMap);
    const totalQ = allQIDs.length;
    let totalR = rawResponses.length;

    // Also set the local state for the surveyTitle and surveyId if present
    let foundSurveyTitle = surveyData.title || '';
    this.setState({
      aggregateQuestionResponses: unifiedMap,
      sbtFilteredAggregateQuestionResponses: unifiedMap,
      surveyTitle: foundSurveyTitle,
      surveyId: surveyIdLower,
      totalQuestionsCount: totalQ,
      filteredQuestionsCount: totalQ,
      totalResponsesCount: totalR,
      filteredResponsesCount: totalR
    }, () => {
      this.generateCSVForSurveyMode();
    });
  }

  /**
   * preloadQuestionModeAggregatorFromCache() - loads aggregator info from localStorage questionsCache
   * so that we have questionIDs before fetching from chain. Helps show zero-response questions.
   */
  async preloadQuestionModeAggregatorFromCache() {
    if (!this.props.network || !this.props.network.id) {
      return;
    }
    const networkID = this.props.network.id.toString();
    let questionsCacheStr = localStorage.getItem('questionsCache');
    if (!questionsCacheStr) {
      return;
    }
    let questionsCache;
    try {
      questionsCache = JSON.parse(questionsCacheStr);
    } catch (e) {
      console.error('Error parsing questionsCache in preloadQuestionModeAggregatorFromCache:', e);
      return;
    }
    if (!questionsCache[networkID]) {
      return;
    }

    const partialQuestionResponses = questionsCache[networkID].questionResponses || {};
    const aggregatorMapQ = {};
    let totalRespCount = 0;
    const qIds = Object.keys(partialQuestionResponses);

    // all known questionIDs
    const allKnownQuestionIDs = Object.keys(questionsCache[networkID].questions || {});

    qIds.forEach(qId => {
      const lowered = qId.toLowerCase();
      if (!aggregatorMapQ[lowered]) {
        aggregatorMapQ[lowered] = [];
      }
      const responderMap = partialQuestionResponses[qId] || {};
      const responderAddrs = Object.keys(responderMap);
      responderAddrs.forEach(rAddr => {
        const rData = responderMap[rAddr];
        const parsed = this.parseResponse(rData);
        if (parsed) {
          aggregatorMapQ[lowered].push({
            responder: rAddr,
            questionId: lowered,
            response: JSON.stringify(parsed),
            timestamp: parsed.timeStamp || Date.now()
          });
        }
      });
      totalRespCount += responderAddrs.length;
    });

    const aggregatorUnified = unifyAggregatorWithAllQuestionIDs(aggregatorMapQ, allKnownQuestionIDs);
    const totalQ = Object.keys(aggregatorUnified).length;
    let finalRespCount = 0;
    Object.keys(aggregatorUnified).forEach(k => {
      finalRespCount += aggregatorUnified[k].length;
    });

    this.setState({
      aggregatorQuestionResponses: aggregatorUnified,
      sbtFilteredAggregatorQuestionResponses: aggregatorUnified,
      questionResponses: partialQuestionResponses,
      sbtFilteredQuestionResponses: partialQuestionResponses,
      totalQuestionsCount: totalQ,
      filteredQuestionsCount: totalQ,
      totalResponsesCount: finalRespCount,
      filteredResponsesCount: finalRespCount
    }, () => {
      this.generateCSVForQuestionMode();
    });
  }

  /**
   * fetchResponses() - decides whether to fetch in "survey" or "questions" mode
   */
  async fetchResponses() {
    if (!this.props.network || !this.props.network.id) {
      console.error('Network ID is undefined in fetchResponses. Cannot proceed.');
      return;
    }

    if (this.state.viewMode === 'survey') {
      await this.fetchSurveyModeResponses();
    } else {
      await this.fetchQuestionModeResponses();
    }
  }

  /**
   * fetchSurveyModeResponses() - for a single known survey ID
   */
  async fetchSurveyModeResponses() {
    const surveyID = this.props.surveyId ? this.props.surveyId.toLowerCase() : null;
    if (!surveyID) {
      console.warn('No survey ID provided in survey viewMode.');
      return;
    }

    this.setState({
      partialLoading: true,
      partialProgress: 0,
      partialTotal: 0
    });

    try {
      const chainCurrentBlock = await contractScripts.getLatestBlockNumber(this.props.provider);
      this.setState({ networkLatestBlock: chainCurrentBlock });

      let surveyResponses = await contractScripts.fetchAllSurveyResponses(this.props.provider, surveyID);
      if (!Array.isArray(surveyResponses)) {
        surveyResponses = [];
      }

      // Deduplicate by responder => keep newest
      const addressMap = new Map();
      surveyResponses.forEach(response => {
        const existing = addressMap.get(response.responder);
        if (!existing || existing.timestamp < response.timestamp) {
          addressMap.set(response.responder, response);
        }
      });
      const latestResponses = Array.from(addressMap.values());

      // aggregator map
      const aggregatorMap = {};
      latestResponses.forEach(resp => {
        const parsed = this.parseResponse(resp.response);
        if (parsed && parsed.responses) {
          parsed.responses.forEach(r => {
            const qIdLower = (r.questionID || '').toLowerCase();
            if (!aggregatorMap[qIdLower]) {
              aggregatorMap[qIdLower] = [];
            }
            aggregatorMap[qIdLower].push({
              responder: resp.responder,
              questionId: qIdLower,
              response: JSON.stringify(r),
              timestamp: r.timeStamp || parsed.timeStamp || Date.now()
            });
          });
        }
      });

      // Also unify aggregator with known questionIDs from local storage for this survey
      let surveysCacheStr = localStorage.getItem('surveysCache');
      let surveysCache = {};
      let allSurveyQIDs = [];
      if (surveysCacheStr) {
        try {
          surveysCache = JSON.parse(surveysCacheStr);
        } catch (e) {
          console.error('Error parsing surveysCache in fetchSurveyModeResponses:', e);
          surveysCache = {};
        }
      }
      const networkID = this.props.network.id.toString();
      if (surveysCache[networkID] && surveysCache[networkID].surveys[surveyID]) {
        const surveyData = surveysCache[networkID].surveys[surveyID];
        if (surveyData && surveyData.questionIDs) {
          allSurveyQIDs = surveyData.questionIDs.map((id) => id.toLowerCase());
          // Also set state for surveyTitle and surveyId if found
          if (surveyData.title) {
            this.setState({ surveyTitle: surveyData.title, surveyId: surveyID });
          }
        }
      }

      // Also unify questionIDs from questionsCache if associatedSurveyId matches this survey
      let questionsCacheStr = localStorage.getItem('questionsCache');
      if (questionsCacheStr) {
        try {
          const questionsCache = JSON.parse(questionsCacheStr);
          if (questionsCache[networkID] && questionsCache[networkID].questions) {
            for (const qId in questionsCache[networkID].questions) {
              const qObj = questionsCache[networkID].questions[qId];
              if (
                qObj.associatedSurveyId &&
                qObj.associatedSurveyId.toLowerCase() === surveyID
              ) {
                allSurveyQIDs.push(qId.toLowerCase());
              }
            }
          }
        } catch (err) {
          console.error('Error parsing questionsCache in fetchSurveyModeResponses (merge step):', err);
        }
      }

      const unifiedMap = unifyAggregatorWithAllQuestionIDs(aggregatorMap, allSurveyQIDs);
      const allQIDs = Object.keys(unifiedMap);

      let totalR = latestResponses.length;
      let totalQ = allQIDs.length;

      this.setState({
        responses: latestResponses,
        sbtFilteredResponses: latestResponses,
        aggregateQuestionResponses: unifiedMap,
        sbtFilteredAggregateQuestionResponses: unifiedMap,
        totalResponsesCount: totalR,
        filteredResponsesCount: totalR,
        totalQuestionsCount: totalQ,
        filteredQuestionsCount: totalQ,
        partialLoading: false,
        partialProgress: this.state.partialTotal
      }, () => {
        this.generateCSVForSurveyMode();
      });
    } catch (err) {
      console.error('Error fetching survey mode responses:', err);
      this.setState({
        partialLoading: false
      });
    }
  }

  /**
   * fetchQuestionModeResponses() - for "questions" tab aggregator
   */
  async fetchQuestionModeResponses() {
    this.setState({
      partialLoading: true,
      partialProgress: 0,
      partialTotal: 0
    });

    try {
      const chainCurrentBlock = await contractScripts.getLatestBlockNumber(this.props.provider);
      this.setState({ networkLatestBlock: chainCurrentBlock });

      const networkID = this.props.network.id.toString();
      let questionsCacheStr = localStorage.getItem('questionsCache');
      let questionsCache = {};
      if (questionsCacheStr) {
        try {
          questionsCache = JSON.parse(questionsCacheStr);
        } catch (e) {
          console.error('Error parsing questionsCache in fetchQuestionModeResponses:', e);
          questionsCache = {};
        }
      }
      let fromBlock = 0;
      if (questionsCache[networkID]) {
        fromBlock = questionsCache[networkID].lastBlock || 0;
      }

      await contractScripts.getQuestionResponsesChunkedWithCallback(
        this.props.provider,
        fromBlock,
        'latest',
        (progressInfo) => {
          const { doneSoFarBlocks, totalRangeBlocks } = progressInfo;
          this.setState({
            partialProgress: doneSoFarBlocks,
            partialTotal: totalRangeBlocks
          });
        },
        (partialAggregator, currentToBlock) => {
          // Merge partialAggregator into aggregatorQuestionResponses
          const existingAgg = { ...this.state.aggregatorQuestionResponses };
          for (const qId in partialAggregator) {
            if (!existingAgg[qId]) {
              existingAgg[qId] = [];
            }
            const arr = partialAggregator[qId];
            arr.forEach(obj => {
              const idx = existingAgg[qId].findIndex(x => x.responder === obj.responder);
              if (idx === -1) {
                existingAgg[qId].push(obj);
              } else {
                existingAgg[qId][idx] = obj;
              }
            });
          }

          // Also unify aggregator with known questionIDs from local storage
          let allKnownQIDs = [];
          if (questionsCache[networkID] && questionsCache[networkID].questions) {
            allKnownQIDs = Object.keys(questionsCache[networkID].questions).map(s => s.toLowerCase());
          }
          const aggregatorFinal = unifyAggregatorWithAllQuestionIDs(existingAgg, allKnownQIDs);

          let totalQ = Object.keys(aggregatorFinal).length;
          let totalR = 0;
          Object.keys(aggregatorFinal).forEach(k => {
            totalR += aggregatorFinal[k].length;
          });

          let finalQCount = Math.max(totalQ, this.state.totalQuestionsCount);
          let finalRCount = Math.max(totalR, this.state.totalResponsesCount);

          this.setState({
            aggregatorQuestionResponses: aggregatorFinal,
            sbtFilteredAggregatorQuestionResponses: aggregatorFinal,
            totalQuestionsCount: finalQCount,
            filteredQuestionsCount: finalQCount,
            totalResponsesCount: finalRCount,
            filteredResponsesCount: finalRCount
          }, () => {
            this.generateCSVForQuestionMode();
          });
        }
      );

      this.setState({
        partialLoading: false,
        partialProgress: this.state.partialTotal
      });
    } catch (err) {
      console.error("Error in fetchQuestionModeResponses chunk-based logic:", err);
      this.setState({ partialLoading: false });
    }
  }

  getLatestResponses(responses) {
    const addressMap = new Map();
    responses.forEach(response => {
      const existingResponse = addressMap.get(response.responder);
      if (!existingResponse || existingResponse.timestamp < response.timestamp) {
        addressMap.set(response.responder, response);
      }
    });
    return Array.from(addressMap.values());
  }

  generateCSVForSurveyMode = () => {
    const { responses } = this.state;
    if (!responses.length) {
      if (this._isMounted) {
        this.setState({ csvData: '' });
      }
      return;
    }

    const header = 'responderAddress,questionID,questionPrompt,type,importance,answer,answerHash,additionalComments,answerEncrypted,additionalEncrypted,additionalHash\n';
    const csvRows = [];

    responses.forEach(response => {
      const parsedResponse = this.parseResponse(response.response);
      if (parsedResponse && parsedResponse.responses) {
        parsedResponse.responses.forEach(answer => {
          const row = [
            response.responder,
            answer.questionID || '',
            answer.prompt || '',
            answer.type || '',
            answer.importance || '',
            answer.answer ? answer.answer.value : '',
            answer.answer ? answer.answer.hash : '',
            answer.additional ? answer.additional.value : '',
            answer.answer ? answer.answer.encrypted : '',
            answer.additional ? answer.additional.encrypted : '',
            answer.additional ? answer.additional.hash : ''
          ]
            .map(value => `"${value !== undefined ? value : ''}"`)
            .join(',');
          csvRows.push(row);
        });
      }
    });

    if (this._isMounted) {
      this.setState({ csvData: header + csvRows.join('\n') });
    }
  };

  generateCSVForQuestionMode = () => {
    const { aggregatorQuestionResponses } = this.state;
    const allQIDs = Object.keys(aggregatorQuestionResponses);
    if (!allQIDs.length) {
      if (this._isMounted) {
        this.setState({ csvData: '' });
      }
      return;
    }

    const header = 'questionID,questionPrompt,type,responderAddress,importance,answer,answerHash,additionalComments,answerEncrypted,additionalEncrypted,additionalHash\n';
    const csvRows = [];

    allQIDs.forEach(qId => {
      const arr = aggregatorQuestionResponses[qId] || [];
      arr.forEach(respObj => {
        const parsed = this.parseResponse(respObj.response);
        if (parsed) {
          const questionId = parsed.questionID || '';
          const questionPrompt = parsed.prompt || '';
          const questionType = parsed.type || '';
          const answerVal = parsed.answer ? parsed.answer.value : '';
          const answerHash = parsed.answer ? parsed.answer.hash : '';
          const answerEnc = parsed.answer ? parsed.answer.encrypted : '';
          const additionalVal = parsed.additional ? parsed.additional.value : '';
          const additionalEnc = parsed.additional ? parsed.additional.encrypted : '';
          const additionalHash = parsed.additional ? parsed.additional.hash : '';
          const importance = parsed.importance || '';

          const row = [
            questionId,
            questionPrompt,
            questionType,
            respObj.responder,
            importance,
            answerVal,
            answerHash,
            additionalVal,
            answerEnc,
            additionalEnc,
            additionalHash
          ].map(value => `"${value !== undefined ? value : ''}"`).join(',');
          csvRows.push(row);
        }
      });
    });

    if (this._isMounted) {
      this.setState({ csvData: header + csvRows.join('\n') });
    }
  };

  downloadCSV = () => {
    const { exportType, csvData, viewMode } = this.state;
    const timestamp = new Date().toISOString().replace(/[:.-]/g, '_');

    if (exportType === 'Polis Report') {
      this.setState({ alertMessage: 'Generated Polis Report below.' }, () => {
        this.setState({ polisReportSelected: true }, () => {
          setTimeout(() => {
            this.scrollToPolisReport();
          }, 300);
        });
      });
      return;
    }

    if (exportType === 'TalkToTheCity Report') {
      const lines = csvData.split('\n');
      if (!lines.length || !csvData.trim()) {
        this.setState({ alertMessage: 'No data available for TalkToTheCity Report.' });
        return;
      }
      const headerLine = lines[0];
      const columns = headerLine.split(',');
      const indicesToRemove = [];
      columns.forEach((col, idx) => {
        const clean = col.replace(/"/g, '').toLowerCase();
        if (clean.includes('answerhash') || clean.includes('additionalencrypted') || clean.includes('additionalhash')) {
          indicesToRemove.push(idx);
        }
      });
      const revisedLines = lines.map((ln) => {
        const parts = ln.split(',');
        const filtered = parts.filter((item, idx) => !indicesToRemove.includes(idx));
        return filtered.join(',');
      });
      const finalCSV = revisedLines.join('\n');

      this.setState({
        alertMessage:
          'You can paste your CSV into https://talktothecity.org/new-report after signing in, to generate a talk-to-the-city style report.'
      });

      const blob = new Blob([finalCSV], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', `talktothecity_${timestamp}.csv`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      navigator.clipboard
        .writeText(finalCSV)
        .then(() => console.log('Copied TalkToTheCity CSV to clipboard'))
        .catch(err => console.error('Failed to copy to clipboard', err));

      return;
    }

    if (!csvData.trim().length) {
      this.setState({ alertMessage: 'No data to download.' });
      return;
    }

    const blob = new Blob([csvData], { type: 'text/csv' });
    let modeLabel = viewMode === 'survey' ? 'surveyMode' : 'questionsMode';
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `survey_responses_${modeLabel}_${timestamp}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  scrollToPolisReport = () => {
    const element = document.getElementById('polisReportSection');
    if (element && element.scrollIntoView) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  handleQuestionFilter = (filteredQuestionsOrCombined, newFilterState) => {
    if (Array.isArray(filteredQuestionsOrCombined)) {
      // Just an array of questions
      const allowedIds = new Set(filteredQuestionsOrCombined.map(q => q.id.toLowerCase()));
      if (this.state.viewMode === 'survey' && this.state.surveyViewMode === 'aggregate') {
        const newObj = {};
        for (const qId in this.state.aggregateQuestionResponses) {
          if (allowedIds.has(qId.toLowerCase())) {
            newObj[qId] = this.state.aggregateQuestionResponses[qId];
          }
        }
        let totalR = 0;
        for (const qId in newObj) {
          totalR += newObj[qId].length;
        }
        this.setState({
          sbtFilteredAggregateQuestionResponses: newObj,
          filterState: newFilterState,
          filteredQuestionsCount: filteredQuestionsOrCombined.length,
          filteredResponsesCount: this.state.responses.length
        });
      } else if (this.state.viewMode === 'survey' && this.state.surveyViewMode === 'individuals') {
        this.setState({
          filterState: newFilterState
        });
      } else {
        const newObj = {};
        for (const qId in this.state.aggregatorQuestionResponses) {
          if (allowedIds.has(qId.toLowerCase())) {
            newObj[qId] = this.state.aggregatorQuestionResponses[qId];
          }
        }
        let totalR = 0;
        let filteredQCount = 0;
        for (const qId in newObj) {
          totalR += newObj[qId].length;
          filteredQCount++;
        }
        const realTotalQ = Object.keys(this.state.aggregatorQuestionResponses).length;
        const finalFilteredQCount = filteredQCount > realTotalQ ? realTotalQ : filteredQCount;
        this.setState({
          sbtFilteredAggregatorQuestionResponses: newObj,
          filterState: newFilterState,
          filteredQuestionsCount: finalFilteredQCount,
          filteredResponsesCount: totalR > this.state.totalResponsesCount ? this.state.totalResponsesCount : totalR
        });
      }
    } else if (
      filteredQuestionsOrCombined &&
      Array.isArray(filteredQuestionsOrCombined.filteredQuestions)
    ) {
      const { filteredQuestions, filteredResponsesByQuestion } = filteredQuestionsOrCombined;
      const allowedIds = new Set(filteredQuestions.map(q => q.id.toLowerCase()));
      if (this.state.viewMode === 'survey' && this.state.surveyViewMode === 'aggregate') {
        const newObj = {};
        for (const qId in this.state.aggregateQuestionResponses) {
          if (allowedIds.has(qId.toLowerCase())) {
            if (filteredResponsesByQuestion[qId]) {
              newObj[qId] = filteredResponsesByQuestion[qId];
            } else {
              newObj[qId] = this.state.aggregateQuestionResponses[qId];
            }
          }
        }
        let totalR = 0;
        let filteredQCount = 0;
        for (const qId in newObj) {
          totalR += newObj[qId].length;
          filteredQCount++;
        }
        const realTotalQ = Object.keys(this.state.aggregateQuestionResponses).length;
        const finalFilteredQCount = filteredQCount > realTotalQ ? realTotalQ : filteredQCount;
        this.setState({
          sbtFilteredAggregateQuestionResponses: newObj,
          filterState: newFilterState,
          filteredQuestionsCount: finalFilteredQCount,
          filteredResponsesCount: this.state.responses.length
        });
      } else if (this.state.viewMode === 'survey' && this.state.surveyViewMode === 'individuals') {
        this.setState({
          filterState: newFilterState
        });
      } else {
        const newObj = {};
        for (const qId in this.state.aggregatorQuestionResponses) {
          if (allowedIds.has(qId.toLowerCase())) {
            if (filteredResponsesByQuestion[qId]) {
              newObj[qId] = filteredResponsesByQuestion[qId];
            } else {
              newObj[qId] = this.state.aggregatorQuestionResponses[qId];
            }
          }
        }
        let totalR = 0;
        let filteredQCount = 0;
        for (const qId in newObj) {
          totalR += newObj[qId].length;
          filteredQCount++;
        }
        const realTotalQ = Object.keys(this.state.aggregatorQuestionResponses).length;
        const finalFilteredQCount = filteredQCount > realTotalQ ? realTotalQ : filteredQCount;
        this.setState({
          sbtFilteredAggregatorQuestionResponses: newObj,
          filterState: newFilterState,
          filteredQuestionsCount: finalFilteredQCount,
          filteredResponsesCount: totalR > this.state.totalResponsesCount ? this.state.totalResponsesCount : totalR
        });
      }
    }
  };

  setFilterLoading = (loading) => {
    this.setState({ filterLoading: loading });
    if (this.props.setFilterLoading) {
      this.props.setFilterLoading(loading);
    }
  };

  handleFilteredResponses = (filteredResponses) => {
    if (this.state.viewMode === 'survey') {
      if (this.state.surveyViewMode === 'individuals') {
        if (Array.isArray(filteredResponses)) {
          this.setState({
            sbtFilteredResponses: filteredResponses
          }, () => {
            this.setState({ filteredResponsesCount: filteredResponses.length });
          });
        } else {
          console.error("Expected an array of responses in survey mode (individuals), but got object:", filteredResponses);
          this.setState({ sbtFilteredResponses: [], filteredResponsesCount: 0 });
        }
      } else {
        if (filteredResponses && typeof filteredResponses === 'object') {
          this.setState({
            sbtFilteredAggregateQuestionResponses: filteredResponses
          }, () => {
            let totalR = 0;
            const questionIds = Object.keys(filteredResponses);
            questionIds.forEach(qId => {
              totalR += filteredResponses[qId].length;
            });
            const realTotalQ = Object.keys(this.state.aggregateQuestionResponses).length;
            const finalQCount = questionIds.length > realTotalQ ? realTotalQ : questionIds.length;
            this.setState({
              filteredResponsesCount: this.state.responses.length,
              filteredQuestionsCount: finalQCount
            });
          });
        } else {
          console.error("Expected an object of question-responses in aggregator mode, but got array or undefined:", filteredResponses);
        }
      }
    } else {
      // question mode aggregator
      if (filteredResponses && typeof filteredResponses === 'object') {
        this.setState({
          sbtFilteredAggregatorQuestionResponses: filteredResponses
        }, () => {
          let totalQ = Object.keys(filteredResponses).length;
          let totalR = 0;
          for (const qId in filteredResponses) {
            totalR += filteredResponses[qId].length;
          }
          const realTotalQ = Object.keys(this.state.aggregatorQuestionResponses).length;
          const finalQCount = totalQ > realTotalQ ? realTotalQ : totalQ;
          const finalRCount = totalR > this.state.totalResponsesCount ? this.state.totalResponsesCount : totalR;
          this.setState({
            filteredQuestionsCount: finalQCount,
            filteredResponsesCount: finalRCount
          });
        });
      } else {
        console.error("Expected an aggregator object for question mode, got:", filteredResponses);
      }
    }
  };

  toggleQuestionSummary = (questionId) => {
    this.setState(prevState => ({
      activeQuestionToggles: {
        ...prevState.activeQuestionToggles,
        [questionId]: !prevState.activeQuestionToggles[questionId],
      }
    }));
  };

  toggleResponse = index => {
    this.setState(prevState => ({
      activeToggles: {
        ...prevState.activeToggles,
        [index]: !prevState.activeToggles[index]
      }
    }));
  };

  // NEW function to bookmark or un-bookmark a survey ID in localStorage
  toggleSurveyBookmark = (surveyId) => {
    let bookmarks = JSON.parse(localStorage.getItem('bookmarks')) || { questionIDs: [], surveyIDs: [] };
    if (!bookmarks.surveyIDs) bookmarks.surveyIDs = [];

    if (bookmarks.surveyIDs.includes(surveyId)) {
      bookmarks.surveyIDs = bookmarks.surveyIDs.filter(id => id !== surveyId);
    } else {
      bookmarks.surveyIDs.push(surveyId);
    }

    localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
    this.setState({ bookmarkedSurveyIDs: bookmarks.surveyIDs });
  };

  renderQuestionSummary = (questionId, responses) => {
    const netId = this.props.network?.id?.toString() || '';
    const lowerQId = questionId.toLowerCase();

    let questionsCacheStr = localStorage.getItem('questionsCache');
    let questionsCache = {};
    if (questionsCacheStr) {
      try {
        questionsCache = JSON.parse(questionsCacheStr);
      } catch (e) {
        console.error('Error parsing questionsCache in renderQuestionSummary:', e);
        questionsCache = {};
      }
    }

    if (!questionsCache[netId] || !questionsCache[netId].questions) {
      return (
        <Card key={questionId} id={`questionCard-${questionId}`} className={styles.aggregatorSummaryCard}>
          <CardHeader onClick={() => this.toggleQuestionSummary(questionId)} className={styles.questionSummaryHeader}>
            <span className={styles.questionTitle}>
              Unknown question: {questionId} ({responses.length} responses)
            </span>
            <FontAwesomeIcon
              icon={faBookmark}
              className={styles.biggerIcon}
              onClick={(e) => {
                e.stopPropagation();
                this.toggleQuestionBookmark(questionId);
              }}
              color={this.state.bookmarkedQuestionIDs.includes(questionId) ? 'gold' : 'white'}
              style={{ marginLeft: '10px', cursor: 'pointer' }}
            />
            <FontAwesomeIcon
              icon={this.state.activeQuestionToggles[questionId] ? faCaretUp : faCaretDown}
              className={styles.biggerIcon}
              style={{ marginLeft: '10px' }}
            />
          </CardHeader>
          <Collapse isOpen={this.state.activeQuestionToggles[questionId]} id={styles.surveyResultsCollapse}>
            <CardBody className={styles.aggregatorDarkCardBody}>
              <p style={{ fontStyle: 'italic', color: '#bbb' }}>
                No metadata found for this question in local cache.
              </p>
              <div className={styles.surveyResultsOverride}>
                <SingleQuestionResponse
                  aggregatorResponseMode={true}
                  question={{ id: questionId, prompt: 'Unknown question' }}
                  allResponses={responses}
                  network={this.props.network}
                />
              </div>
            </CardBody>
          </Collapse>
        </Card>
      );
    }

    const question = questionsCache[netId].questions[lowerQId];
    if (!question) {
      return (
        <Card key={questionId} id={`questionCard-${questionId}`} className={styles.aggregatorSummaryCard}>
          <CardHeader onClick={() => this.toggleQuestionSummary(questionId)} className={styles.questionSummaryHeader}>
            <span className={styles.questionTitle}>
              Unknown question: {questionId} ({responses.length} responses)
            </span>
            <FontAwesomeIcon
              icon={faBookmark}
              className={styles.biggerIcon}
              onClick={(e) => {
                e.stopPropagation();
                this.toggleQuestionBookmark(questionId);
              }}
              color={this.state.bookmarkedQuestionIDs.includes(questionId) ? 'gold' : 'white'}
              style={{ marginLeft: '10px', cursor: 'pointer' }}
            />
            <FontAwesomeIcon
              icon={this.state.activeQuestionToggles[questionId] ? faCaretUp : faCaretDown}
              className={styles.biggerIcon}
              style={{ marginLeft: '10px' }}
            />
          </CardHeader>
          <Collapse isOpen={this.state.activeQuestionToggles[questionId]} id={styles.surveyResultsCollapse}>
            <CardBody className={styles.aggregatorDarkCardBody}>
              <p style={{ fontStyle: 'italic', color: '#bbb' }}>
                No metadata found for this question in local cache.
              </p>
              <div className={styles.surveyResultsOverride}>
                <SingleQuestionResponse
                  aggregatorResponseMode={true}
                  question={{ id: questionId, prompt: 'Unknown question' }}
                  allResponses={responses}
                  network={this.props.network}
                />
              </div>
            </CardBody>
          </Collapse>
        </Card>
      );
    }

    const isActive = this.state.activeQuestionToggles[questionId];
    return (
      <Card key={questionId} id={`questionCard-${questionId}`} className={styles.aggregatorSummaryCard}>
        <CardHeader onClick={() => this.toggleQuestionSummary(questionId)} className={styles.questionSummaryHeader}>
          <span className={styles.questionTitle}>
            {question.prompt} ({responses.length} responses)
          </span>
          <FontAwesomeIcon
            icon={faBookmark}
            className={styles.biggerIcon}
            onClick={(e) => {
              e.stopPropagation();
              this.toggleQuestionBookmark(questionId);
            }}
            color={this.state.bookmarkedQuestionIDs.includes(questionId) ? 'gold' : 'white'}
            style={{ marginLeft: '10px', cursor: 'pointer' }}
          />
          <FontAwesomeIcon
            icon={isActive ? faCaretUp : faCaretDown}
            className={styles.biggerIcon}
            style={{ marginLeft: '10px' }}
          />
        </CardHeader>
        <Collapse isOpen={isActive} id={styles.surveyResultsCollapse}>
          <CardBody className={styles.aggregatorDarkCardBody}>
            <div className={styles.surveyResultsOverride}>
              <SingleQuestionResponse
                aggregatorResponseMode={true}
                question={question}
                allResponses={responses}
                network={this.props.network}
              />
            </div>
          </CardBody>
        </Collapse>
      </Card>
    );
  };

  renderQuestionIDsTable = (questionMap) => {
    let questionsCacheStr = localStorage.getItem('questionsCache');
    let questionsCache = {};
    if (questionsCacheStr) {
      try {
        questionsCache = JSON.parse(questionsCacheStr);
      } catch (e) {
        console.error('Error parsing questionsCache in renderQuestionIDsTable:', e);
        questionsCache = {};
      }
    }

    if (!this.props.network || !this.props.network.id) {
      console.error('Network ID undefined in renderQuestionIDsTable');
      return null;
    }

    const networkID = this.props.network.id.toString();
    const questionIds = Object.keys(questionMap);

    const questionEntries = questionIds.map(qId => {
      const responses = questionMap[qId] || [];
      let type = '';
      let prompt = '';
      const lowerQ = qId.toLowerCase();
      if (questionsCache[networkID] && questionsCache[networkID].questions && questionsCache[networkID].questions[lowerQ]) {
        type = questionsCache[networkID].questions[lowerQ].type;
        prompt = questionsCache[networkID].questions[lowerQ].prompt;
      }
      return {
        questionId: qId,
        responsesCount: responses.length,
        type,
        prompt
      };
    });

    const { questionIdSortBy, questionIdSortAsc } = this.state;
    questionEntries.sort((a, b) => {
      let cmp = 0;
      if (questionIdSortBy === 'responses') {
        cmp = a.responsesCount - b.responsesCount;
      } else if (questionIdSortBy === 'type') {
        cmp = a.type.localeCompare(b.type);
      } else if (questionIdSortBy === 'prompt') {
        cmp = a.prompt.localeCompare(b.prompt);
      }
      return questionIdSortAsc ? cmp : -cmp;
    });

    return (
      <div className={styles.questionIdTableWrapper}>
        <Table striped bordered hover size="sm" className={styles.questionIdTable}>
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>Question ID</th>
              <th
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={() => this.changeQuestionIdSort('prompt')}
              >
                Prompt {questionIdSortBy === 'prompt' ? (questionIdSortAsc ? '▲' : '▼') : '▲▼'}
              </th>
              <th
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={() => this.changeQuestionIdSort('type')}
              >
                Type {questionIdSortBy === 'type' ? (questionIdSortAsc ? '▲' : '▼') : '▲▼'}
              </th>
              <th
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={() => this.changeQuestionIdSort('responses')}
              >
                # Responses {questionIdSortBy === 'responses' ? (questionIdSortAsc ? '▲' : '▼') : '▲▼'}
              </th>
              <th style={{ textAlign: 'center' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {questionEntries.map(entry => {
              const shortened = proposalScripts.getShortenedQuestionID(entry.questionId, false);
              const bookmarked = this.state.bookmarkedQuestionIDs.includes(entry.questionId);
              return (
                <tr key={entry.questionId}>
                  <td style={{ textAlign: 'center' }}>
                    <FontAwesomeIcon
                      icon={faBookmark}
                      style={{ marginRight: '6px', cursor: 'pointer' }}
                      color={bookmarked ? 'gold' : 'white'}
                      onClick={() => this.toggleQuestionBookmark(entry.questionId)}
                    />
                    <a
                      href={`/question/${entry.questionId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.clickableQuestionId}
                    >
                      {shortened}
                    </a>
                  </td>
                  <td className={styles.promptColumn}>
                    {entry.prompt || '(No prompt)'}
                  </td>
                  <td style={{ textAlign: 'center' }}>{entry.type}</td>
                  <td style={{ textAlign: 'center' }}>{entry.responsesCount}</td>
                  <td style={{ textAlign: 'center' }}>
                    <Button
                      size="sm"
                      onClick={() => {
                        this.toggleQuestionSummary(entry.questionId);
                        setTimeout(() => {
                          this.scrollToQuestion(entry.questionId);
                        }, 200);
                      }}
                      className={styles.tableActionButton}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  scrollToQuestion = (questionId) => {
    const element = document.getElementById(`questionCard-${questionId}`);
    if (element && element.scrollIntoView) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  changeQuestionIdSort = (column) => {
    this.setState(prevState => {
      let newAsc = true;
      if (prevState.questionIdSortBy === column) {
        newAsc = !prevState.questionIdSortAsc;
      }
      return {
        questionIdSortBy: column,
        questionIdSortAsc: newAsc
      };
    });
  };

  toggleQuestionFilter = () => {
    this.setState(prevState => ({ showQuestionFilter: !prevState.showQuestionFilter }));
  };

  toggleSurveyViewMode = (mode) => {
    this.setState({ surveyViewMode: mode });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleExportTypeChange = type => {
    this.setState({ exportType: type, polisReportSelected: false });
  }

  render() {
    const {
      responses,
      sbtFilteredResponses,
      csvData,
      exportType,
      activeToggles,
      modalOpen,
      alertMessage,
      loading,
      viewMode,
      questionResponses,
      aggregatorQuestionResponses,
      sbtFilteredAggregatorQuestionResponses,
      surveyTitle,
      surveyId,
      filterLoading,
      showQuestionFilter,
      totalQuestionsCount,
      filteredQuestionsCount,
      totalResponsesCount,
      filteredResponsesCount,
      surveyViewMode,
      aggregateQuestionResponses,
      sbtFilteredAggregateQuestionResponses,
      polisReportSelected,
      partialLoading,
      partialProgress,
      partialTotal,
      networkLatestBlock
    } = this.state;

    // Enhanced title with Survey ID and Title if in "survey" mode
    const modalTitle = `Survey Results${surveyTitle ? ` - ${surveyTitle}` : ''}`;
    const modalSubtitle = (viewMode === 'survey' && surveyId) ? `ID: ${surveyId}` : '';

    return (
      <Modal isOpen={modalOpen} toggle={this.closeModal} className={styles.resultsModal}>
        <ModalHeader toggle={this.closeModal} className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>
            {viewMode === 'survey' ? modalTitle : 'Question Results'}
          </h2>
          {viewMode === 'survey' && modalSubtitle && (
            <div
              className={styles.modalSubtitle}
              style={{ color: 'grey', display: 'flex', alignItems: 'center' }}
            >
              {modalSubtitle}
              {/* Bookmark icon for the survey ID */}
              <FontAwesomeIcon
                icon={faBookmark}
                style={{ marginLeft: '8px', cursor: 'pointer' }}
                color={this.state.bookmarkedSurveyIDs.includes(surveyId) ? 'gold' : 'grey'}
                onClick={(e) => {
                  e.stopPropagation();
                  this.toggleSurveyBookmark(surveyId);
                }}
              />
            </div>
          )}
        </ModalHeader>

        <ModalBody className={styles.modalBody}>

          {/* If we are in SURVEY mode, put 'Individuals View' / 'Aggregate View' at the top */}
          {viewMode === 'survey' && (
            <div className={styles.surveyViewModeTopButtons}>
              <Button
                color={surveyViewMode === 'individuals' ? 'primary' : 'secondary'}
                onClick={() => this.toggleSurveyViewMode('individuals')}
                style={{ marginRight: '10px' }}
              >
                Individuals View
              </Button>
              <Button
                color={surveyViewMode === 'aggregate' ? 'primary' : 'secondary'}
                onClick={() => this.toggleSurveyViewMode('aggregate')}
              >
                Aggregate View
              </Button>
            </div>
          )}

          {/* Partial loading / chunk progress UI */}
          {partialLoading && (
            <div className={styles.loadingContainer} style={{ marginTop: '0', paddingTop: '0' }}>
              <FontAwesomeIcon icon={faSpinner} spin size="2x" />
              <p style={{ marginBottom: '5px' }}>
                Processing blocks...
              </p>
              <Progress
                animated
                color="info"
                value={partialTotal > 0 ? (partialProgress / partialTotal) * 100 : 0}
                style={{ width: '80%', margin: '0 auto' }}
              />
              <p style={{ fontSize: '0.9rem', marginTop: '5px' }}>
                Block Range: {partialProgress} / {partialTotal}
                {networkLatestBlock > 0 && (
                  <span>
                    &nbsp;(Network Latest: {networkLatestBlock})
                  </span>
                )}
              </p>
            </div>
          )}

          {filterLoading && (
            <div className={styles.loadingContainer}>
              <FontAwesomeIcon icon={faSpinner} spin size="2x" />
              <p>Applying filter...</p>
            </div>
          )}

          {!filterLoading && !partialLoading && alertMessage && (
            <Alert color="info" className={styles.alertMessage}>
              {alertMessage}
            </Alert>
          )}

          {/* If in SURVEY mode, we can show question table if in 'aggregate' view */}
          {viewMode === 'survey' && surveyViewMode === 'aggregate' && (
            <Card className={styles.questionListCard}>
              <CardHeader
                onClick={() => this.toggleQuestionSummary('__questionList__')}
                className={styles.questionSummaryHeader}
              >
                <span className={styles.questionTitle}>
                  Expand to view question IDs
                </span>
                <FontAwesomeIcon
                  icon={this.state.activeQuestionToggles['__questionList__'] ? faCaretUp : faCaretDown}
                  className={styles.biggerIcon}
                  style={{ marginLeft: '10px' }}
                />
              </CardHeader>
              <Collapse isOpen={this.state.activeQuestionToggles['__questionList__']} id={styles.surveyResultsCollapse}>
                <CardBody className={styles.aggregatorDarkCardBody}>
                  {Object.keys(sbtFilteredAggregateQuestionResponses).length === 0 && !filterLoading ? (
                    <p>No questions found.</p>
                  ) : (
                    <div ref={this.questionIdTableRef}>
                      {this.renderQuestionIDsTable(sbtFilteredAggregateQuestionResponses)}
                    </div>
                  )}
                </CardBody>
              </Collapse>
            </Card>
          )}

          {/* If in QUESTION mode, we also have an expander for question list. */}
          {viewMode === 'questions' && (
            <Card className={styles.questionListCard}>
              <CardHeader
                onClick={() => this.toggleQuestionSummary('__questionList__')}
                className={styles.questionSummaryHeader}
              >
                <span className={styles.questionTitle}>
                  Expand to view question IDs
                </span>
                <FontAwesomeIcon
                  icon={this.state.activeQuestionToggles['__questionList__'] ? faCaretUp : faCaretDown}
                  className={styles.biggerIcon}
                  style={{ marginLeft: '10px' }}
                />
              </CardHeader>
              <Collapse isOpen={this.state.activeQuestionToggles['__questionList__']} id={styles.surveyResultsCollapse}>
                <CardBody className={styles.aggregatorDarkCardBody}>
                  {Object.keys(sbtFilteredAggregatorQuestionResponses).length === 0 && !filterLoading ? (
                    <p>No questions found.</p>
                  ) : (
                    <div ref={this.questionIdTableRef}>
                      {this.renderQuestionIDsTable(sbtFilteredAggregatorQuestionResponses)}
                    </div>
                  )}
                </CardBody>
              </Collapse>
            </Card>
          )}

          <div className={styles.filterSummaryBox}>
            <p style={{ fontStyle: 'italic' }}>
              Total Questions: {totalQuestionsCount}, Filtered Questions: {filteredQuestionsCount}
              <br />
              Total Responses: {totalResponsesCount}, Filtered Responses: {filteredResponsesCount}
            </p>
          </div>

          <div className={styles.exportAndFilterContainer}>

            <div className={styles.filterBox}>
              {viewMode === 'survey' && surveyViewMode === 'individuals' && (
                <Label className={styles.filterBoxLabel}>Filter (Survey Individuals):</Label>
              )}
              {viewMode === 'survey' && surveyViewMode === 'aggregate' && (
                <Label className={styles.filterBoxLabel}>Filter (Survey Aggregate):</Label>
              )}
              {viewMode === 'questions' && (
                <Label className={styles.filterBoxLabel}>Filter Questions &amp; Responses:</Label>
              )}

              {viewMode === 'survey' && surveyViewMode === 'aggregate' && (
                <SBTFilter
                  items={aggregateQuestionResponses}
                  mode="responder"
                  provider={this.props.provider}
                  network={this.props.network}
                  onFilter={this.handleFilteredResponses}
                  setFilterLoading={this.setFilterLoading}
                  autoExpand={false}
                />
              )}
              {viewMode === 'survey' && surveyViewMode === 'individuals' && (
                <SBTFilter
                  items={responses}
                  mode="responder"
                  provider={this.props.provider}
                  network={this.props.network}
                  onFilter={this.handleFilteredResponses}
                  setFilterLoading={this.setFilterLoading}
                  autoExpand={false}
                />
              )}
              {viewMode === 'questions' && (
                <QuestionFilter
                  resultsMode={true}
                  filterModalOpen={true}
                  toggleFilterModal={this.toggleQuestionFilter}
                  questions={Object.keys(questionResponses).map(qId => {
                    let q = null;
                    let netID = this.props.network?.id?.toString() || '';
                    let qCacheStr = localStorage.getItem('questionsCache');
                    let qCache = {};
                    if (qCacheStr) {
                      try {
                        qCache = JSON.parse(qCacheStr);
                      } catch (e) {
                        console.error('Error parsing questionsCache in SurveyResults QuestionFilter area:', e);
                        qCache = {};
                      }
                    }
                    const lowerQ = qId.toLowerCase();
                    if (qCache[netID] && qCache[netID].questions && qCache[netID].questions[lowerQ]) {
                      q = qCache[netID].questions[lowerQ];
                    } else {
                      q = { id: qId, creator: '', type: '', prompt: '' };
                    }
                    return q;
                  })}
                  questionResponses={questionResponses}
                  provider={this.props.provider}
                  network={this.props.network}
                  onFilter={this.handleQuestionFilter}
                  setFilterLoading={this.setFilterLoading}
                  filterState={this.state.filterState}
                  creatorAndResponderMode={true}
                  showQuestionFilter={showQuestionFilter}
                />
              )}
            </div>

            <div className={styles.exportDataBox}>
              <Label for="exportType" className={styles.exportLabel}>
                Export Data:
              </Label>
              <div id={styles.exportOptions}>
                <UncontrolledDropdown direction="down" className={styles.exportDropdownBox}>
                  <DropdownToggle caret className={styles.exportDropdown}>
                    {exportType}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.handleExportTypeChange('CSV')}>
                      CSV
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleExportTypeChange('TalkToTheCity Report')}>
                      TalkToTheCity Report
                    </DropdownItem>
                    <DropdownItem onClick={() => this.handleExportTypeChange('Polis Report')}>
                      Polis Report
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <Button onClick={this.downloadCSV} className={styles.downloadButton}>
                  {exportType === 'Polis Report' ? 'Generate' : 'Download'}
                </Button>
              </div>
            </div>
          </div>

          {viewMode === 'survey' && surveyViewMode === 'individuals' && (
            <>
              <div className={styles.responseList}>
                {sbtFilteredResponses.length === 0 && !filterLoading ? (
                  <p>No results yet.</p>
                ) : (
                  sbtFilteredResponses.map((response, index) => {
                    const parsedResponse = this.parseResponse(response.response);
                    const openToggle = !!activeToggles[index];
                    return (
                      <Card key={index} className={styles.singleResponseCard}>
                        <CardHeader
                          onClick={() => this.toggleResponse(index)}
                          className={styles.responseHeader}
                        >
                          <span className={styles.responderAddress}>
                            {proposalScripts.getShortenedAddress(response.responder)}
                            <a
                              href={`/survey/${this.props.surveyId}/${response.responder}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={styles.externalLink}
                            >
                              <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </a>
                            {response.response && response.response.encryptedPortion && (
                              <FontAwesomeIcon icon={faLock} className={styles.lockIcon} />
                            )}
                          </span>
                          <FontAwesomeIcon
                            icon={openToggle ? faCaretUp : faCaretDown}
                            className={styles.biggerIcon}
                          />
                        </CardHeader>
                        <Collapse isOpen={openToggle} id={styles.surveyResultsCollapse}>
                          <CardBody className={styles.responseCard}>
                            {parsedResponse && parsedResponse.responses && parsedResponse.responses.length > 0 ? (
                              parsedResponse.responses.map((answerItem, aIndex) => {
                                const questionId = (answerItem.questionID || '').toLowerCase();
                                let qCache = {};
                                let localQC = localStorage.getItem('questionsCache');
                                if (localQC) {
                                  try {
                                    qCache = JSON.parse(localQC);
                                  } catch (err) {
                                    console.error('Error parsing questionsCache in single-person survey render:', err);
                                  }
                                }
                                const netId = this.props.network?.id?.toString() || '';
                                let questionData = { id: questionId, creator: '', type: '', prompt: '' };
                                if (qCache[netId] && qCache[netId].questions && qCache[netId].questions[questionId]) {
                                  questionData = qCache[netId].questions[questionId];
                                }
                                return (
                                  <div key={aIndex} className={styles.surveyResultsOverride}>
                                    <SingleQuestionResponse
                                      aggregatorResponseMode={false}
                                      question={questionData}
                                      response={answerItem}
                                      mode="fullscreen"
                                      isOwnResponse={this.props.account?.toLowerCase() === response.responder?.toLowerCase()}
                                      network={this.props.network}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p>No question-level responses found for this user.</p>
                            )}
                          </CardBody>
                        </Collapse>
                      </Card>
                    );
                  })
                )}
              </div>
            </>
          )}

          {viewMode === 'survey' && surveyViewMode === 'aggregate' && (
            <>
              <div className={styles.questionSummaries}>
                {Object.entries(sbtFilteredAggregateQuestionResponses).map(([questionId, resp]) => {
                  return (
                    <div key={questionId}>
                      {this.renderQuestionSummary(questionId, resp)}
                    </div>
                  );
                })}
                {Object.keys(sbtFilteredAggregateQuestionResponses).length === 0 && !filterLoading && (
                  <p>No results yet.</p>
                )}
              </div>
            </>
          )}

          {viewMode === 'questions' && (
            <div className={styles.questionSummaries}>
              {Object.entries(sbtFilteredAggregatorQuestionResponses).map(([questionId, arr]) => {
                return (
                  <div key={questionId}>
                    {this.renderQuestionSummary(questionId, arr)}
                  </div>
                );
              })}
              {Object.keys(sbtFilteredAggregatorQuestionResponses).length === 0 && !filterLoading && (
                <p>No results yet.</p>
              )}
            </div>
          )}

          {polisReportSelected && (
            <div id="polisReportSection">
              <PolisReport
                questionResponses={
                  this.state.viewMode === 'survey' && this.state.surveyViewMode === 'aggregate'
                    ? this.state.sbtFilteredAggregateQuestionResponses
                    : this.state.viewMode === 'survey' && this.state.surveyViewMode === 'individuals'
                      ? {}
                      : this.state.sbtFilteredAggregatorQuestionResponses
                }
                network={this.props.network}
                disclaimersActive={true}
                filterState={this.state.filterState}
              />
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          {/* We keep the modal footer just for a close button, if desired. Or you can remove it if not needed. */}
        </ModalFooter>
      </Modal>
    );
  }
}

export default SurveyResults;

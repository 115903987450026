import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faChevronRight, faChevronDown, faBookmark, faTimes, faComment, faCopy, faCheck, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { FormGroup, Label, Input } from 'reactstrap';
import AudioInput from '../SurveyTool/AudioInput.jsx';
import contractScripts from '../Buttons/contractScripts.js';
import treeData from '../../variables/AIPolicy_treeData.json';
import historicalData from '../../variables/historical_figures_tree_qs_and_votes.json';
import historicalUsers from '../../variables/historical_figure_users.json';
import styles from './AIPolicyAtlas.module.scss';

const calculateNetUpvotes = (votes) => {
  if (!votes) return 0;
  return Object.entries(votes).reduce((sum, [, vote]) => sum + parseInt(vote), 0);
};

const TreeNode = ({ node, depth = 0, onNodeClick, onBookmark, bookmarkedNodes, demoMode, onSuggestNode }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasChildren = node.children && node.children.length > 0;
  const netUpvotes = calculateNetUpvotes(node.votes);
  const isBookmarked = bookmarkedNodes.includes(node.id);
  const commentCount = (node.questions ? node.questions.length : 0) + (node.comments ? node.comments.length : 0);

  const toggleExpand = useCallback((e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const nodeClass = `${styles.treeNode} ${styles[`depth${depth}`]}`;

  return (
    <div className={nodeClass}>
      <div className={styles.nodeContent} onClick={toggleExpand}>
        {hasChildren && (
          <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} className={styles.expandButton} />
        )}
        <span className={styles.nodeName}>{node.name}</span>
        <span className={styles.upvotes}>
          <FontAwesomeIcon icon={faThumbsUp} /> {netUpvotes}
        </span>
        {commentCount > 0 && (
          <span className={styles.commentCount}>
            <FontAwesomeIcon icon={faComment} /> {commentCount}
          </span>
        )}
        <FontAwesomeIcon 
          icon={faBookmark} 
          className={`${styles.bookmark} ${isBookmarked ? styles.bookmarked : ''}`} 
          onClick={(e) => { e.stopPropagation(); onBookmark(node.id); }} 
        />
        <FontAwesomeIcon 
          icon={faChevronRight} 
          className={styles.expandModal} 
          onClick={(e) => { e.stopPropagation(); onNodeClick(node); }} 
        />
      </div>
      {hasChildren && isExpanded && (
        <div className={styles.children}>
          {node.children.map((child, index) => (
            <TreeNode 
              key={index} 
              node={child} 
              depth={depth + 1} 
              onNodeClick={onNodeClick} 
              onBookmark={onBookmark}
              bookmarkedNodes={bookmarkedNodes}
              demoMode={demoMode}
              onSuggestNode={onSuggestNode}
            />
          ))}
          <div className={styles.suggestNode} onClick={() => onSuggestNode(node)}>
            <FontAwesomeIcon icon={faPlus} /> Suggest new node
          </div>
        </div>
      )}
    </div>
  );
};

const FlatNode = ({ node, parentPath = [], onNodeClick, onBookmark, bookmarkedNodes, demoMode }) => {
  const netUpvotes = calculateNetUpvotes(node.votes);
  const isBookmarked = bookmarkedNodes.includes(node.id);
  const commentCount = (node.questions ? node.questions.length : 0) + (node.comments ? node.comments.length : 0);

  return (
    <div className={`${styles.flatNode} ${styles[`depth${parentPath.length}`]}`}>
      <div className={styles.flatNodePath}>
        {parentPath.map((pathNode, index) => (
          <React.Fragment key={index}>
            {index > 0 && <FontAwesomeIcon icon={faChevronRight} className={styles.pathArrow} />}
            <button 
              className={`${styles.pathButton} ${styles[`depth${index}`]}`}
              onClick={() => onNodeClick(pathNode)}
            >
              {pathNode.name}
            </button>
          </React.Fragment>
        ))}
        <FontAwesomeIcon icon={faChevronRight} className={styles.pathArrow} />
        <button 
          className={`${styles.pathButton} ${styles[`depth${parentPath.length}`]}`}
          onClick={() => onNodeClick(node)}
        >
          {node.name}
        </button>
      </div>
      <span className={styles.upvotes}>
        <FontAwesomeIcon icon={faThumbsUp} /> {netUpvotes}
      </span>
      {commentCount > 0 && (
        <span className={styles.commentCount}>
          <FontAwesomeIcon icon={faComment} /> {commentCount}
        </span>
      )}
      <FontAwesomeIcon 
        icon={faBookmark} 
        className={`${styles.bookmark} ${isBookmarked ? styles.bookmarked : ''}`} 
        onClick={() => onBookmark(node.id)} 
      />
      <FontAwesomeIcon 
        icon={faChevronRight} 
        className={styles.expandModal} 
        onClick={() => onNodeClick(node)} 
      />
    </div>
  );
};

const Modal = ({ isOpen, onClose, content, onVote, onAddComment, demoMode, copied, onCopy }) => {
  const [userText, setUserText] = useState("");
  const [encryptBoxChecked, setEncryptBoxChecked] = useState(false);
  const [voteCount, setVoteCount] = useState(0);
  const [relatedNodeIDs, setRelatedNodeIDs] = useState([]);
  const [answers, setAnswers] = useState({});
  const [selectedStatements, setSelectedStatements] = useState([]);
  const [relatedDocuments, setRelatedDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (content) {
      const getRelatedNodeIDs = (nodeID) => {
        console.log(`Fetching related nodeIDs for ${nodeID}`);
        return []; // Return an empty array for now
      };

      setRelatedNodeIDs(getRelatedNodeIDs(content.id));
      setRelatedDocuments(findRelatedDocuments(content.id));
    }
  }, [content]);

  const findRelatedDocuments = (nodeID) => {
    console.log(`Finding related documents for nodeID: ${nodeID}`);
    return [
      { id: 1, title: "AI Ethics in Policy Making", relevance: 0.95 },
      { id: 2, title: "The Future of AI Governance", relevance: 0.87 },
      { id: 3, title: "AI and Its Impact on Society", relevance: 0.82 },
    ];
  };

  const getHighlightedText = (documentId) => {
    console.log(`Getting highlighted text for document ID: ${documentId}`);
    return "In crafting policy we must consider the incentives we are creating for various actors, including AI agents themselves.";
  };

  const handleDocumentClick = (document) => {
    setSelectedDocument({
      ...document,
      highlightedText: getHighlightedText(document.id),
    });
  };

  const handleUploadClick = () => {
    navigate('/upload');
  };

  const handleAddComment = async () => {
    if (userText) {
      const arweaveId = await contractScripts.uploadDataToArweave(userText, encryptBoxChecked);
      onAddComment(content.id, arweaveId);
      setUserText("");
    }
  };

  const updateFunction = (text) => {
    setUserText(text);
  };

  const toggleEncryption = () => {
    setEncryptBoxChecked(prev => !prev);
  };

  const handleCastVotes = () => {
    onVote(content.id, 'quadratic', voteCount);
    setVoteCount(0);
  };

  const getQuestionsAndComments = () => {
    let questions = [];
    let comments = [];

    Object.entries(historicalData).forEach(([username, userData]) => {
      const userQuestions = userData.questions.filter(q => q.id === content.id);
      const userComments = userData.comments.filter(c => c.id === content.id);

      questions = [...questions, ...userQuestions.map(q => ({ ...q, username }))];
      comments = [...comments, ...userComments.map(c => ({ ...c, username }))];
    });

    return { questions, comments };
  };

  const handleAnswerChange = (index, value) => {
    setAnswers(prev => ({ ...prev, [index]: value }));
  };

  const handleMultichoiceChange = (index, option) => {
    setAnswers(prev => {
      const currentAnswers = prev[index] || [];
      if (currentAnswers.includes(option)) {
        return { ...prev, [index]: currentAnswers.filter(item => item !== option) };
      } else {
        return { ...prev, [index]: [...currentAnswers, option] };
      }
    });
  };

  const handleStatementSelection = (index) => {
    setSelectedStatements(prev => {
      if (prev.includes(index)) {
        return prev.filter(item => item !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const renderQuestionDisplay = (statement, index) => {
    return (
      <div key={index} className={styles.questionDisplay}>
        <div className={styles.questionText}>{statement.question}</div>
        <div className={styles.answerDisplay}>
          {statement.questionType === 'binary' && (
            <FormGroup id={styles.binaryChoice}>
              {['Agree', 'Pass', 'Disagree'].map((option) => (
                <Label check key={option} className={`${styles.radioOptionText} ${styles[option.toLowerCase()]} ${answers[index] === option ? styles.selected : ''}`}>
                  <Input
                    type="radio"
                    name={`question-${index}`}
                    value={option}
                    checked={answers[index] === option}
                    onChange={() => handleAnswerChange(index, option)}
                  />
                  {option === 'Agree' && <FontAwesomeIcon icon={faCheck} className={styles.optionIcon} />}
                  {option === 'Disagree' && <FontAwesomeIcon icon={faTimes} className={styles.optionIcon} />}
                  {option}
                </Label>
              ))}
            </FormGroup>
          )}
          {statement.questionType === 'freeform' && (
            <Input
              type="textarea"
              value={answers[index] || ''}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
              placeholder="Your answer here..."
              className={styles.freeformAnswer}
            />
          )}
          {statement.questionType === 'rating' && (
            <div className={styles.ratingAnswer}>
              <input 
                type="range" 
                min="0" 
                max="10" 
                value={answers[index] || 5} 
                onChange={(e) => handleAnswerChange(index, parseInt(e.target.value))} 
                className={styles.ratingSlider} 
              />
              <span className={styles.ratingValue}>{answers[index] || 5}/10</span>
            </div>
          )}
          {statement.questionType === 'multichoice' && (
            <div className={styles.multichoiceAnswer}>
              {statement.options && statement.options.map((option, optionIndex) => (
                <Label check key={optionIndex} className={styles.multichoiceOption}>
                  <Input
                    type="checkbox"
                    checked={(answers[index] || []).includes(option)}
                    onChange={() => handleMultichoiceChange(index, option)}
                  />
                  {option}
                </Label>
              ))}
            </div>
          )}
        </div>
        <input
          type="checkbox"
          checked={selectedStatements.includes(index)}
          onChange={() => handleStatementSelection(index)}
          className={styles.statementCheckbox}
        />
      </div>
    );
  };

  if (!isOpen || !content) return null;

  const { questions, comments } = getQuestionsAndComments();

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>{content.name}</h2>
          <div className={styles.modalIdContainer}>
            <span className={styles.modalIdLabel}>ID: </span>
            <span className={styles.modalId}>{content.id}</span>
            <button onClick={onCopy} className={styles.copyButton}>
              <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
            </button>
          </div>
          <FontAwesomeIcon icon={faTimes} onClick={onClose} className={styles.closeIcon} />
        </div>
        <div className={styles.voteSection}>
          <button className={styles.upvoteButton} onClick={() => onVote(content.id, 'up')}>
            <FontAwesomeIcon icon={faThumbsUp} /> {calculateNetUpvotes(content.votes)}
          </button>
          <button className={styles.downvoteButton} onClick={() => onVote(content.id, 'down')}>
            <FontAwesomeIcon icon={faThumbsDown} /> {content.downvotes || 0}
          </button>
          <input 
            type="number" 
            value={voteCount} 
            onChange={(e) => setVoteCount(parseInt(e.target.value) || 0)}
            className={`${styles.voteInput} ${voteCount < 0 ? styles.negativeVote : ''}`}
          />
          <button className={styles.castVotesButton} onClick={handleCastVotes}>Cast Votes</button>
        </div>
        
        {relatedNodeIDs.length > 0 && (
          <div className={styles.relatedNodesSection}>
            <h3 className={styles.sectionTitle}>Related Node IDs</h3>
            <ul className={styles.relatedNodesList}>
              {relatedNodeIDs.map((nodeID, index) => (
                <li key={index}>{nodeID}</li>
              ))}
            </ul>
          </div>
        )}

        {questions.length > 0 && (
          <div className={styles.questionsSection}>
            <h3 className={styles.sectionTitle}>Questions</h3>
            {questions.map((question, index) => renderQuestionDisplay(question, index))}
          </div>
        )}

        {comments.length > 0 && (
          <div className={styles.commentsSection}>
            <h3 className={styles.sectionTitle}>Comments</h3>
            {comments.map((comment, index) => {
              const user = historicalUsers.find(u => u.username === comment.username);
              return (
                <div key={index} className={styles.comment}>
                  {user && <img src={user.avatar} alt={user.name} className={styles.userAvatar} />}
                  <div className={styles.commentContent}>
                    <strong className={styles.commentUser}>{user ? user.name : comment.username}</strong>
                    <p className={styles.commentText}>{comment.comment}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <div className={styles.relatedDocumentsSection}>
          <h3 className={styles.sectionTitle}>Related Documents</h3>
          <ul className={styles.relatedDocumentsList}>
            {relatedDocuments.map((doc) => (
              <li key={doc.id} onClick={() => handleDocumentClick(doc)} className={styles.relatedDocument}>
                <span className={styles.documentTitle}>{doc.title}</span>
                <span className={styles.documentRelevance}>Relevance: {doc.relevance.toFixed(2)}</span>
              </li>
            ))}
          </ul>
          {selectedDocument && (
            <div className={styles.highlightedTextContainer}>
              <h4>{selectedDocument.title}</h4>
              <p className={styles.highlightedText}>{selectedDocument.highlightedText}</p>
            </div>
          )}
          <button className={styles.uploadButton} onClick={handleUploadClick}>
            <FontAwesomeIcon icon={faUpload} /> Upload Document
          </button>
        </div>

        <div className={styles.addCommentSection}>
          <h3 className={styles.addCommentTitle}>Add a comment</h3>
          <AudioInput 
            placeholder="Type or speak your comment here"
            updateFunction={updateFunction}
            toggleEncryption={toggleEncryption}
          />
          <div className={styles.actionButtons}>
            <button className={styles.submitCommentButton} onClick={handleAddComment} disabled={!userText}>
              Submit Comment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SuggestNodeModal = ({ isOpen, onClose, parentNode, onSubmit }) => {
  const [title, setTitle] = useState("");

  const handleSubmit = () => {
    onSubmit(parentNode, title);
    setTitle("");
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>Suggest New Node</h2>
          <FontAwesomeIcon icon={faTimes} onClick={onClose} className={styles.closeIcon} />
        </div>
        <div className={styles.suggestNodeContent}>
          <p id={styles.parentNodeText}> Parent Node: {parentNode ? parentNode.name : 'Root'}</p>
          <p id={styles.parentNodeText}> Parent Node ID: {parentNode ? parentNode.id : 'Root'}</p>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter node title"
            className={styles.suggestNodeInput}
          />
          <button onClick={handleSubmit} className={styles.suggestNodeButton}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const Legend = () => (
  <div className={styles.legendContainer}>
    <div className={styles.legendItem}>
      <div className={`${styles.legendDot} ${styles.category}`}></div>
      <span className={styles.legendText}>Category</span>
    </div>
    <div className={styles.legendItem}>
      <div className={`${styles.legendDot} ${styles.subcategory}`}></div>
      <span className={styles.legendText}>Sub-category</span>
    </div>
    <div className={styles.legendItem}>
      <div className={`${styles.legendDot} ${styles.topic}`}></div>
      <span className={styles.legendText}>Topic</span>
    </div>
    <div className={styles.legendItem}>
      <div className={`${styles.legendDot} ${styles.instance}`}></div>
      <span className={styles.legendText}>Instance</span>
    </div>
  </div>
);

const AIPolicyAtlas = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [orderByUpvotes, setOrderByUpvotes] = useState(false);
  const [bookmarkedNodes, setBookmarkedNodes] = useState([]);
  const [demoMode, setDemoMode] = useState(false);
  const [treeDataState, setTreeDataState] = useState(treeData);
  const [nodeTypeFilter, setNodeTypeFilter] = useState('all');
  const [sbtFilter, setSbtFilter] = useState('all');
  const [copied, setCopied] = useState(false);
  const [suggestNodeModalOpen, setSuggestNodeModalOpen] = useState(false);
  const [suggestNodeParent, setSuggestNodeParent] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);

  const navigate = useNavigate();
  const { nodeId } = useParams();

  useEffect(() => {
    const savedBookmarks = localStorage.getItem('bookmarkedNodes');
    if (savedBookmarks) {
      setBookmarkedNodes(JSON.parse(savedBookmarks));
    }
  }, []);

  useEffect(() => {
    if (demoMode) {
      const combinedData = treeData.map(category => {
        const updateNodeWithHistoricalData = (node) => {
          const historicalNode = Object.values(historicalData).find(hd => hd.questions.some(q => q.id === node.id));
          if (historicalNode) {
            return {
              ...node,
              votes: historicalNode.votes || {},
              questions: historicalNode.questions.filter(q => q.id === node.id) || [],
              comments: historicalNode.comments || [],
              children: node.children ? node.children.map(updateNodeWithHistoricalData) : undefined
            };
          }
          return {
            ...node,
            children: node.children ? node.children.map(updateNodeWithHistoricalData) : undefined
          };
        };
        
        return updateNodeWithHistoricalData(category);
      });
      setTreeDataState(combinedData);
    } else {
      setTreeDataState(treeData);
    }
    setSelectedCategory(null);
  }, [demoMode]);

  useEffect(() => {
    if (nodeId) {
      const findNode = (nodes) => {
        for (let node of nodes) {
          if (node.id === nodeId) {
            return node;
          }
          if (node.children) {
            const found = findNode(node.children);
            if (found) return found;
          }
        }
        return null;
      };

      const node = findNode(treeDataState);
      if (node) {
        setModalContent(node);
      }
    }
  }, [nodeId, treeDataState]);

  const handleCategoryClick = useCallback((category) => {
    if (orderByUpvotes) {
      setActiveFilters(prev => {
        if (prev.includes(category.id)) {
          return prev.filter(id => id !== category.id);
        } else {
          return [category.id];
        }
      });
    } else {
      setSelectedCategory(category);
    }
  }, [orderByUpvotes]);

  const handleNodeClick = useCallback((node) => {
    setModalContent(node);
  }, []);

  const handleVote = useCallback((nodeId, voteType, voteCount = 1) => {
    setTreeDataState(prevData => {
      const updateNode = (nodes) => {
        return nodes.map(node => {
          if (node.id === nodeId) {
            const currentVotes = node.votes || {};
            if (voteType === 'up' || voteType === 'quadratic') {
              return { ...node, votes: { ...currentVotes, [voteType]: (parseInt(currentVotes[voteType]) || 0) + voteCount } };
            } else if (voteType === 'down') {
              return { ...node, downvotes: (parseInt(node.downvotes) || 0) + Math.abs(voteCount) };
            }
          }
          if (node.children) {
            return { ...node, children: updateNode(node.children) };
          }
          return node;
        });
      };
      return updateNode(prevData);
    });
  }, []);

  const handleAddComment = useCallback((nodeId, arweaveId) => {
    setTreeDataState(prevData => {
      const updateNode = (nodes) => {
        return nodes.map(node => {
          if (node.id === nodeId) {
            return { 
              ...node, 
              comments: [
                ...(node.comments || []),
                { id: arweaveId, user: 'Current User', comment: 'New comment' } // You may want to replace 'Current User' with actual user data
              ]
            };
          }
          if (node.children) {
            return { ...node, children: updateNode(node.children) };
          }
          return node;
        });
      };
      return updateNode(prevData);
    });
  }, []);

  const handleBookmark = useCallback((nodeId) => {
    setBookmarkedNodes(prev => {
      const newBookmarks = prev.includes(nodeId)
        ? prev.filter(id => id !== nodeId)
        : [...prev, nodeId];
      localStorage.setItem('bookmarkedNodes', JSON.stringify(newBookmarks));
      return newBookmarks;
    });
  }, []);

  const closeModal = useCallback(() => {
    setModalContent(null);
  }, []);

  const copyToClipboard = useCallback(() => {
    if (modalContent) {
      navigator.clipboard.writeText(modalContent.id).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2500);
      });
    }
  }, [modalContent]);

  const handleSuggestNode = useCallback((parentNode) => {
    setSuggestNodeParent(parentNode);
    setSuggestNodeModalOpen(true);
  }, []);

  const handleSubmitSuggestedNode = useCallback((parentNode, title) => {
    // This is a placeholder function. In a real implementation, you would send this data to a smart contract.
    console.log(`Suggesting new node: ${title} under parent: ${parentNode ? parentNode.name : 'Root'}`);
    // Here you would typically call a function to interact with a smart contract
    // For example: contractScripts.suggestNewNode(parentNode ? parentNode.id : null, title);
  }, []);

  const flattenTree = useCallback((node, parentPath = [], depth = 0) => {
    let result = [{ ...node, parentPath, depth }];
    if (node.children) {
      node.children.forEach(child => {
        result = result.concat(flattenTree(child, [...parentPath, node], depth + 1));
      });
    }
    return result;
  }, []);

  const filterBySBT = useCallback((nodes) => {
    console.log(`Filtering by SBT: ${sbtFilter}`);
    if (sbtFilter === 'all') return nodes;
    return nodes.filter((node, index) => index % 2 === 0);
  }, [sbtFilter]);
  
  const sortedNodes = useMemo(() => {
    if (!orderByUpvotes || !Array.isArray(treeDataState)) return [];
    const flattened = treeDataState.flatMap(category => flattenTree(category));
    return filterBySBT(flattened)
      .filter(node => {
        if (activeFilters.length === 0) return true;
        return activeFilters.some(filterId => node.parentPath.some(p => p.id === filterId) || node.id === filterId);
      })
      .filter(node => {
        if (nodeTypeFilter === 'all') return true;
        if (nodeTypeFilter === 'category') return node.depth === 0;
        if (nodeTypeFilter === 'subcategory') return node.depth === 1;
        if (nodeTypeFilter === 'topic') return node.depth === 2;
        if (nodeTypeFilter === 'instance') return node.depth === 3;
        return true;
      })
      .sort((a, b) => calculateNetUpvotes(b.votes) - calculateNetUpvotes(a.votes));
  }, [orderByUpvotes, flattenTree, treeDataState, nodeTypeFilter, activeFilters, filterBySBT, sbtFilter]);

  return (
    <div className={styles.aiPolicyAtlasWrapper}>
      <div className={styles.aiPolicyAtlas}>
        <h1 className={styles.title}>AI Policy Atlas</h1>
        <Legend />
        <div className={styles.controls}>
          <div className={styles.orderToggle}>
            <span className={styles.toggleLabel}>Order by upvotes</span>
            <input
              type="checkbox"
              checked={orderByUpvotes}
              onChange={(e) => setOrderByUpvotes(e.target.checked)}
              className={styles.toggleCheckbox}
            />
          </div>
          <div className={styles.demoToggle}>
            <span className={styles.toggleLabel}>Demo mode</span>
            <input
              type="checkbox"
              checked={demoMode}
              onChange={(e) => setDemoMode(e.target.checked)}
              className={styles.toggleCheckbox}
            />
          </div>
          {orderByUpvotes && (
            <>
              <div className={styles.nodeTypeFilter}>
                <span className={styles.filterLabel}>Filter by:</span>
                <select 
                  value={nodeTypeFilter} 
                  onChange={(e) => setNodeTypeFilter(e.target.value)}
                  className={styles.filterSelect}
                >
                  <option value="all">All</option>
                  <option value="category">Category</option>
                  <option value="subcategory">Sub-category</option>
                  <option value="topic">Topic</option>
                  <option value="instance">Instance</option>
                </select>
              </div>
              <div className={styles.sbtFilter}>
                <span className={styles.filterLabel}>Filter by SBTs:</span>
                <select 
                  value={sbtFilter} 
                  onChange={(e) => setSbtFilter(e.target.value)}
                  className={styles.filterSelect}
                >
                  <option value="all">All</option>
                  <option value="eacc">e/acc</option>
                  <option value="dacc">d/acc</option>
                  <option value="pauseAI">pauseAI</option>
                </select>
              </div>
            </>
          )}
        </div>
        <div className={styles.categorySelector}>
          {Array.isArray(treeDataState) && treeDataState.map((category, index) => {
            const isActive = orderByUpvotes
              ? activeFilters.includes(category.id)
              : selectedCategory && selectedCategory.id === category.id;
            return (
              <button
                key={index}
                className={`${styles.categoryButton} ${isActive ? styles.active : ''}`}
                onClick={() => handleCategoryClick(category)}
              >
                {category.name}
              </button>
            );
          })}
        </div>
        <div className={styles.nodesContainer}>
          {orderByUpvotes ? (
            <>
              {sortedNodes.map((node, index) => (
                <FlatNode 
                  key={index} 
                  node={node} 
                  parentPath={node.parentPath} 
                  onNodeClick={handleNodeClick}
                  onBookmark={handleBookmark}
                  bookmarkedNodes={bookmarkedNodes}
                  demoMode={demoMode}
                />
              ))}
              <div className={styles.suggestNode} onClick={() => handleSuggestNode(null)}>
                <FontAwesomeIcon icon={faPlus} /> Suggest new node
              </div>
            </>
          ) : (
            selectedCategory && (
              <div className={styles.treeContainer}>
                {selectedCategory.children.map((node, index) => (
                  <TreeNode 
                    key={index} 
                    node={node} 
                    onNodeClick={handleNodeClick}
                    onBookmark={handleBookmark}
                    bookmarkedNodes={bookmarkedNodes}
                    demoMode={demoMode}
                    onSuggestNode={handleSuggestNode}
                  />
                ))}
                <div className={styles.suggestNode} onClick={() => handleSuggestNode(selectedCategory)}>
                  <FontAwesomeIcon icon={faPlus} /> Suggest new node
                </div>
              </div>
            )
          )}
        </div>
        <Modal 
          isOpen={!!modalContent} 
          onClose={closeModal} 
          content={modalContent}
          onVote={handleVote}
          onAddComment={handleAddComment}
          demoMode={demoMode}
          copied={copied}
          onCopy={copyToClipboard}
        />
        <SuggestNodeModal
          isOpen={suggestNodeModalOpen}
          onClose={() => setSuggestNodeModalOpen(false)}
          parentNode={suggestNodeParent}
          onSubmit={handleSubmitSuggestedNode}
        />
      </div>
    </div>
  );
};

export default AIPolicyAtlas;

export const seedGenPrompt = `
Source Document: <SourceDocContent>

-----

numberOfSeedStatementsOrPrompts: <NumSeedStatements> (default 10)

-----

TypeOfQuestionsToInclude: <Types> (of possible binary, multichoice, rating, freeform question types). The default is all 4 but the output must follow the specified types.

-----

Given the Source Document provided above, analyze its content and generate the specified number of seed questions that capture the most pertinent issues, concerns, and topics raised by the material. Focus on creating questions of the types specified in TypeOfQuestionsToInclude.
Your task is to distill the core ideas and implications from the source material into thought-provoking questions. These should not be about the document itself, but rather reflect the key issues and considerations that arise from its content.
For each generated question:
1. Ensure it matches one of the specified question types (binary, rating, freeform, or multichoice).
2. Make the question understandable to an intelligent public while remaining relevant and meaningful to domain experts in AI safety, policy, and related fields.
3. For multichoice questions, provide 3-5 relevant and distinct options that cover a range of potential viewpoints or solutions.
4. Phrase binary questions as clear statements that can be agreed or disagreed with.
5. For rating questions, ensure they are asking about likelihood, importance, or degree of concern that can be meaningfully quantified on a scale.
6. Craft freeform questions to elicit detailed, nuanced responses on complex issues that don't fit neatly into other categories.
Format your output as a valid JSON object with the following structure for each question:
{
  "questions": [
    {
      "prompt": "The question or statement text",
      "questionType": "binary" | "rating" | "freeform" | "multichoice",
      "options": ["Option 1", "Option 2", "Option 3"], // Only for multichoice questions
      "answer": {
        "value": "",
        "encrypted": false,
        "hash": ""
      },
      "additional": {
        "value": "",
        "encrypted": false,
        "hash": ""
      }
    },
    // ... additional questions ...
  ]
}
Ensure that the generated questions:
- Explore the implications, challenges, and potential solutions raised by the content
- Encourage critical thinking and discussion on the subject matter
- Are diverse in their focus, covering various aspects of the topic
- Allow for interesting follow-on questions or discussions
- Are phrased neutrally to avoid bias
- Are specific enough to be actionable, or
- Are broad enough to be widely applicable
- Are directly inspired by the source material but not containing portions like "as described in the document" or explicitly about the document itself (they should be answerable by someone who has not read source material – define any novel terms)
Your output should be a complete, well-formed JSON object containing the specified number of questions, ready for direct use in a survey or discussion platform.
{
  "questions": [
    // Your generated questions here
  ]
}
`;

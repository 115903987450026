import React from 'react';
import { FormGroup, Label, Input, Button, UncontrolledTooltip } from 'reactstrap';
import styles from './SBTSelector.module.scss';
import SBTSelector from './SBTSelector.jsx';
import contractScripts from '../Buttons/contractScripts.js';
import proposalScripts from '../UpcomingMatches/proposalScripts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSpinner, faTimes, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

class SBTFilter extends React.Component {
  constructor(props) {
    super(props);
    // We store all local filter states in one object. If parent passes `externalSBTFilterState`, we restore from it.
    this.state = {
      selectedSBTGroupsCreator:
        (props.externalSBTFilterState && props.externalSBTFilterState.selectedSBTGroupsCreator) || [],
      excludedSBTGroupsCreator:
        (props.externalSBTFilterState && props.externalSBTFilterState.excludedSBTGroupsCreator) || [],
      selectedSBTGroupsResponder:
        (props.externalSBTFilterState && props.externalSBTFilterState.selectedSBTGroupsResponder) || [],
      excludedSBTGroupsResponder:
        (props.externalSBTFilterState && props.externalSBTFilterState.excludedSBTGroupsResponder) || [],
      selectedSBTGroups:
        (props.externalSBTFilterState && props.externalSBTFilterState.selectedSBTGroups) || [],
      excludedSBTGroups:
        (props.externalSBTFilterState && props.externalSBTFilterState.excludedSBTGroups) || [],
      onlyVerifiedHumans:
        (props.externalSBTFilterState && props.externalSBTFilterState.onlyVerifiedHumans) || false,
      showFilterOptions: props.autoExpand || false,
      loading: false,

      // last applied filter snapshot to avoid repeated loops
      lastAppliedFilterSnapshot: null
    };
  }

  componentDidMount() {
    console.log('SBTFilter.jsx network:', this.props.network);
    // We apply the filter once on mount if there's any existing external state
    if (this.hasAnyFilterActive()) {
      this.applyFilter();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // If any local state changes, or if items/mode change, re-apply filter
    const fieldsToCheck = [
      'selectedSBTGroupsCreator',
      'excludedSBTGroupsCreator',
      'selectedSBTGroupsResponder',
      'excludedSBTGroupsResponder',
      'selectedSBTGroups',
      'excludedSBTGroups',
      'onlyVerifiedHumans'
    ];

    let shouldReapply = false;
    for (let f of fieldsToCheck) {
      if (prevState[f] !== this.state[f]) {
        shouldReapply = true;
        break;
      }
    }

    if (prevProps.items !== this.props.items || prevProps.mode !== this.props.mode) {
      shouldReapply = true;
    }

    if (shouldReapply) {
      this.applyFilter();
    }
  }

  hasAnyFilterActive = () => {
    const {
      selectedSBTGroupsCreator,
      excludedSBTGroupsCreator,
      selectedSBTGroupsResponder,
      excludedSBTGroupsResponder,
      selectedSBTGroups,
      excludedSBTGroups,
      onlyVerifiedHumans
    } = this.state;

    if (
      selectedSBTGroupsCreator.length > 0 ||
      excludedSBTGroupsCreator.length > 0 ||
      selectedSBTGroupsResponder.length > 0 ||
      excludedSBTGroupsResponder.length > 0 ||
      selectedSBTGroups.length > 0 ||
      excludedSBTGroups.length > 0 ||
      onlyVerifiedHumans
    ) {
      return true;
    }
    return false;
  };

  getLocalFilterState = () => {
    return {
      selectedSBTGroupsCreator: this.state.selectedSBTGroupsCreator,
      excludedSBTGroupsCreator: this.state.excludedSBTGroupsCreator,
      selectedSBTGroupsResponder: this.state.selectedSBTGroupsResponder,
      excludedSBTGroupsResponder: this.state.excludedSBTGroupsResponder,
      selectedSBTGroups: this.state.selectedSBTGroups,
      excludedSBTGroups: this.state.excludedSBTGroups,
      onlyVerifiedHumans: this.state.onlyVerifiedHumans
    };
  };

  applyFilter = async () => {
    const { items, mode, provider, network } = this.props;
    const {
      selectedSBTGroupsCreator,
      excludedSBTGroupsCreator,
      selectedSBTGroupsResponder,
      excludedSBTGroupsResponder,
      selectedSBTGroups,
      excludedSBTGroups,
      onlyVerifiedHumans
    } = this.state;

    const networkID = network?.id;
    console.log('Network ID used in SBTFilter:', networkID);

    // Create a small snapshot to compare
    const newFilterSnapshot = JSON.stringify({
      selectedSBTGroupsCreator,
      excludedSBTGroupsCreator,
      selectedSBTGroupsResponder,
      excludedSBTGroupsResponder,
      selectedSBTGroups,
      excludedSBTGroups,
      onlyVerifiedHumans,
      mode,
      itemCount: Array.isArray(items) ? items.length : (typeof items === 'object' ? Object.keys(items).length : items)
    });

    if (this.state.lastAppliedFilterSnapshot === newFilterSnapshot) {
      // Means we already applied these exact filters; avoid re-render loops
      return;
    }

    this.setState({ lastAppliedFilterSnapshot: newFilterSnapshot });

    this.setState({ loading: true });

    if (!networkID) {
      console.error('Network ID is undefined in SBTFilter. Cannot proceed.');
      this.setState({ loading: false });
      if (this.props.onFilter) {
        this.props.onFilter([], this.getLocalFilterState());
      }
      return;
    }

    if (!this.hasAnyFilterActive()) {
      if (!items) {
        this.setState({ loading: false });
        if (this.props.onFilter) {
          this.props.onFilter([], this.getLocalFilterState());
        }
        return;
      }
      // Pass items straight through
      if (this.props.onFilter) {
        this.props.onFilter(items, this.getLocalFilterState());
      }
      this.setState({ loading: false });
      return;
    }

    const allSBTAddresses = new Set([
      ...selectedSBTGroupsCreator.map((sbt) => sbt.address.toLowerCase()),
      ...excludedSBTGroupsCreator.map((sbt) => sbt.address.toLowerCase()),
      ...selectedSBTGroupsResponder.map((sbt) => sbt.address.toLowerCase()),
      ...excludedSBTGroupsResponder.map((sbt) => sbt.address.toLowerCase()),
      ...selectedSBTGroups.map((sbt) => sbt.address.toLowerCase()),
      ...excludedSBTGroups.map((sbt) => sbt.address.toLowerCase())
    ]);

    const sbtCache = JSON.parse(localStorage.getItem('sbtCache')) || {};
    console.log('sbtCache:', sbtCache);

    let sbtListData = {};
    if (sbtCache[networkID] && sbtCache[networkID].sbtList) {
      sbtListData = sbtCache[networkID].sbtList;
    }

    const sbtHoldersMap = {};
    for (const sbtAddress of allSBTAddresses) {
      let mintedAddresses = [];
      let burnedAddresses = [];
      let netMintedAddresses = [];
      if (
        sbtListData[sbtAddress] &&
        sbtListData[sbtAddress].mintedAddresses &&
        sbtListData[sbtAddress].burnedAddresses
      ) {
        mintedAddresses = sbtListData[sbtAddress].mintedAddresses.map((addr) => addr.toLowerCase());
        burnedAddresses = sbtListData[sbtAddress].burnedAddresses.map((addr) => addr.toLowerCase());
        netMintedAddresses = mintedAddresses.filter((addr) => !burnedAddresses.includes(addr));
        sbtHoldersMap[sbtAddress] = new Set(netMintedAddresses);
      } else {
        try {
          mintedAddresses = await contractScripts.getAddressesWhoMintedSBT(provider, sbtAddress);
          burnedAddresses = await contractScripts.getAddressesWhoBurnedSBT(provider, sbtAddress);
          mintedAddresses = mintedAddresses.map((addr) => addr.toLowerCase());
          burnedAddresses = burnedAddresses.map((addr) => addr.toLowerCase());
          netMintedAddresses = mintedAddresses.filter((addr) => !burnedAddresses.includes(addr));
          sbtHoldersMap[sbtAddress] = new Set(netMintedAddresses);

          if (!sbtCache[networkID]) {
            sbtCache[networkID] = { sbtList: {} };
          }
          if (!sbtCache[networkID].sbtList[sbtAddress]) {
            sbtCache[networkID].sbtList[sbtAddress] = {};
          }
          sbtCache[networkID].sbtList[sbtAddress].mintedAddresses = mintedAddresses;
          sbtCache[networkID].sbtList[sbtAddress].burnedAddresses = burnedAddresses;
          localStorage.setItem('sbtCache', JSON.stringify(sbtCache));
        } catch (error) {
          console.error('Error fetching SBT holders:', error);
          sbtHoldersMap[sbtAddress] = new Set();
        }
      }
    }

    console.log('sbtHoldersMap:', sbtHoldersMap);

    const doesAddressPassFilters = (address, selectedSBTs, excludedSBTs) => {
      if (!address) return true;
      const lowerAddr = address.toLowerCase();

      // Exclude logic
      for (const sbt of excludedSBTs) {
        const sbtAddr = sbt.address.toLowerCase();
        const holdersSet = sbtHoldersMap[sbtAddr];
        if (holdersSet && holdersSet.has(lowerAddr)) {
          return false;
        }
      }
      // Include logic
      if (selectedSBTs.length > 0) {
        let holdsIncludedSBT = false;
        for (const sbt of selectedSBTs) {
          const sbtAddr = sbt.address.toLowerCase();
          const holdersSet = sbtHoldersMap[sbtAddr];
          if (holdersSet && holdersSet.has(lowerAddr)) {
            holdsIncludedSBT = true;
            break;
          }
        }
        if (!holdsIncludedSBT) {
          return false;
        }
      }
      return true;
    };

    if (mode === 'creatorAndResponder') {
      // items are presumably question objects
      if (!Array.isArray(items)) {
        console.warn('In creatorAndResponder mode, expected items to be an array of question objects.');
      }

      let filteredQuestions = [];
      let filteredResponsesByQuestion = {};

      if (Array.isArray(items)) {
        for (let questionObj of items) {
          if (
            doesAddressPassFilters(
              questionObj.creator,
              selectedSBTGroupsCreator,
              excludedSBTGroupsCreator
            )
          ) {
            filteredQuestions.push(questionObj);
          }
        }

        for (let qObj of filteredQuestions) {
          const qID = qObj.id?.toLowerCase();

          let questionsCacheStr = localStorage.getItem('questionsCache');
          let questionsCache = {};
          if (questionsCacheStr) {
            try {
              questionsCache = JSON.parse(questionsCacheStr);
            } catch (e) {
              console.error('Error parsing questionsCache in SBTFilter:', e);
              questionsCache = {};
            }
          }
          let networkIDLocal = this.props.network?.id?.toString() || '';
          let qResponses = [];
          if (
            questionsCache[networkIDLocal] &&
            questionsCache[networkIDLocal].questionResponses &&
            questionsCache[networkIDLocal].questionResponses[qID]
          ) {
            const addresses = Object.keys(questionsCache[networkIDLocal].questionResponses[qID]);
            for (let addr of addresses) {
              const responseData = questionsCache[networkIDLocal].questionResponses[qID][addr];
              qResponses.push({
                responder: addr,
                questionId: qID,
                response: responseData
              });
            }
          }

          let finalFilteredResponses = [];
          for (let resp of qResponses) {
            if (
              doesAddressPassFilters(
                resp.responder,
                selectedSBTGroupsResponder,
                excludedSBTGroupsResponder
              )
            ) {
              finalFilteredResponses.push(resp);
            }
          }
          filteredResponsesByQuestion[qID] = finalFilteredResponses;
        }
      }

      if (onlyVerifiedHumans) {
        // Possibly do more checks
      }

      if (this.props.onFilterCreators) {
        this.props.onFilterCreators(filteredQuestions, this.getLocalFilterState());
      }
      if (this.props.onFilterResponders) {
        this.props.onFilterResponders(filteredResponsesByQuestion, this.getLocalFilterState());
      }

      if (this.props.onFilter) {
        const combinedResult = {
          filteredQuestions,
          filteredResponsesByQuestion
        };
        this.props.onFilter(combinedResult, this.getLocalFilterState());
      }

      this.setState({ loading: false });
      return;
    }

    const filterItem = (item) => {
      if (!item) return false;

      if (mode === 'addresses') {
        if (typeof item !== 'string') {
          console.error('Expected item to be a string in addresses mode, but got:', item);
          return false;
        }
        const address = item.toLowerCase();

        for (const sbt of excludedSBTGroups) {
          const sbtAddr = sbt.address.toLowerCase();
          const holdersSet = sbtHoldersMap[sbtAddr];
          if (holdersSet && holdersSet.has(address)) {
            return false;
          }
        }
        if (selectedSBTGroups.length > 0) {
          let holdsIncludedSBT = false;
          for (const sbt of selectedSBTGroups) {
            const sbtAddr = sbt.address.toLowerCase();
            const holdersSet = sbtHoldersMap[sbtAddr];
            if (holdersSet && holdersSet.has(address)) {
              holdsIncludedSBT = true;
              break;
            }
          }
          if (!holdsIncludedSBT) {
            return false;
          }
        }
        if (onlyVerifiedHumans) {
        }
        return true;
      } else {
        let addressToCheckCreator = null;
        let addressToCheckResponder = null;

        if (item.creator) {
          addressToCheckCreator = item.creator.toLowerCase();
        }
        if (item.responder) {
          addressToCheckResponder = item.responder.toLowerCase();
        }

        // For question creators
        if (mode === 'creator' || mode === 'questions') {
          if (addressToCheckCreator) {
            if (!doesAddressPassFilters(addressToCheckCreator, selectedSBTGroupsCreator, excludedSBTGroupsCreator)) {
              return false;
            }
          }
        }
        // For question responders
        if (mode === 'responder' || mode === 'questionResponses') {
          if (addressToCheckResponder) {
            if (!doesAddressPassFilters(addressToCheckResponder, selectedSBTGroupsResponder, excludedSBTGroupsResponder)) {
              return false;
            }
          }
        }

        if (onlyVerifiedHumans && (addressToCheckCreator || addressToCheckResponder)) {
        }
        return true;
      }
    };

    let filteredItems = [];
    if (Array.isArray(items)) {
      filteredItems = items.filter((item) => filterItem(item));
    } else if (typeof items === 'object') {
      const newObj = {};
      for (const [key, val] of Object.entries(items)) {
        if (Array.isArray(val)) {
          const filteredArr = val.filter((item) => filterItem(item));
          if (filteredArr.length > 0) {
            newObj[key] = filteredArr;
          }
        } else {
          const entries = Object.entries(val);
          const filtered = entries.filter(([responder, response]) => filterItem(response));
          if (filtered.length > 0) {
            const reconstructed = {};
            for (const [resp, respVal] of filtered) {
              reconstructed[resp] = respVal;
            }
            newObj[key] = reconstructed;
          }
        }
      }
      filteredItems = newObj;
    } else {
      console.warn('SBTFilter received unsupported items type:', typeof items);
      filteredItems = items;
    }

    console.log('Filtered items:', filteredItems);

    this.setState({ loading: false }, () => {
      if (this.props.onFilter) {
        this.props.onFilter(filteredItems, this.getLocalFilterState());
      }
    });
  };

  handleAddSBTIncludeCreator = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.selectedSBTGroupsCreator.find((sbt) => sbt.address === address)) {
      this.setState((prevState) => ({
        selectedSBTGroupsCreator: [...prevState.selectedSBTGroupsCreator, sbtObject]
      }));
    }
  };

  handleRemoveSBTIncludeCreator = (address) => {
    this.setState((prevState) => ({
      selectedSBTGroupsCreator: prevState.selectedSBTGroupsCreator.filter((sbt) => sbt.address !== address)
    }));
  };

  handleAddSBTExcludeCreator = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.excludedSBTGroupsCreator.find((sbt) => sbt.address === address)) {
      this.setState((prevState) => ({
        excludedSBTGroupsCreator: [...prevState.excludedSBTGroupsCreator, sbtObject]
      }));
    }
  };

  handleRemoveSBTExcludeCreator = (address) => {
    this.setState((prevState) => ({
      excludedSBTGroupsCreator: prevState.excludedSBTGroupsCreator.filter((sbt) => sbt.address !== address)
    }));
  };

  handleAddSBTIncludeResponder = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.selectedSBTGroupsResponder.find((sbt) => sbt.address === address)) {
      this.setState((prevState) => ({
        selectedSBTGroupsResponder: [...prevState.selectedSBTGroupsResponder, sbtObject]
      }));
    }
  };

  handleRemoveSBTIncludeResponder = (address) => {
    this.setState((prevState) => ({
      selectedSBTGroupsResponder: prevState.selectedSBTGroupsResponder.filter((sbt) => sbt.address !== address)
    }));
  };

  handleAddSBTExcludeResponder = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.excludedSBTGroupsResponder.find((sbt) => sbt.address === address)) {
      this.setState((prevState) => ({
        excludedSBTGroupsResponder: [...prevState.excludedSBTGroupsResponder, sbtObject]
      }));
    }
  };

  handleRemoveSBTExcludeResponder = (address) => {
    this.setState((prevState) => ({
      excludedSBTGroupsResponder: prevState.excludedSBTGroupsResponder.filter((sbt) => sbt.address !== address)
    }));
  };

  handleAddSBTInclude = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.selectedSBTGroups.find((sbt) => sbt.address === address)) {
      this.setState((prevState) => ({
        selectedSBTGroups: [...prevState.selectedSBTGroups, sbtObject]
      }));
    }
  };

  handleRemoveSBTInclude = (address) => {
    this.setState((prevState) => ({
      selectedSBTGroups: prevState.selectedSBTGroups.filter((sbt) => sbt.address !== address)
    }));
  };

  handleAddSBTExclude = (sbtObject) => {
    const { address } = sbtObject;
    if (!this.state.excludedSBTGroups.find((sbt) => sbt.address === address)) {
      this.setState((prevState) => ({
        excludedSBTGroups: [...prevState.excludedSBTGroups, sbtObject]
      }));
    }
  };

  handleRemoveSBTExclude = (address) => {
    this.setState((prevState) => ({
      excludedSBTGroups: prevState.excludedSBTGroups.filter((sbt) => sbt.address !== address)
    }));
  };

  toggleVerifiedHumans = () => {
    this.setState((prevState) => ({
      onlyVerifiedHumans: !prevState.onlyVerifiedHumans
    }));
  };

  toggleFilterOptions = () => {
    this.setState((prevState) => ({
      showFilterOptions: !prevState.showFilterOptions
    }));
  };

  render() {
    const {
      showFilterOptions,
      selectedSBTGroupsCreator,
      excludedSBTGroupsCreator,
      selectedSBTGroupsResponder,
      excludedSBTGroupsResponder,
      selectedSBTGroups,
      excludedSBTGroups,
      onlyVerifiedHumans,
      loading
    } = this.state;

    const { mode, hideUI } = this.props;

    let buttonText = 'Filter';
    if (mode === 'questions' || mode === 'questionResponses' || mode === 'creatorAndResponder') {
      buttonText = 'Response Filter';
    }
    let loadingText = loading ? 'Applying filter...' : '';

    if (hideUI) {
      return (
        <div style={{ display: 'none' }}>
          {/* Logic remains active, but no visible UI */}
        </div>
      );
    }

    return (
      <div className={styles.sbtFilter}>
        {!this.props.autoExpand && (
          <Button onClick={this.toggleFilterOptions} className={styles.filterButton}>
            <FontAwesomeIcon icon={faCog} /> {buttonText}
          </Button>
        )}

        {(this.props.autoExpand || showFilterOptions) && (
          <div className={styles.filterOptions}>
            {/* -- If mode === 'responder', we ONLY show “Include Responses from SBT Holders” and “Exclude Responses from SBT Holders”. 
                 We do NOT show the question creator UI. -- */}
            {mode === 'responder' && (
              <>
                <h5>SBT Group(s) of Survey Responder</h5>
                <SBTSelector
                  id="includeResponder"
                  network={this.props.network}
                  provider={this.props.provider}
                  onAddSBT={this.handleAddSBTIncludeResponder}
                  selectedSBTs={selectedSBTGroupsResponder}
                  label={'Include Responses from SBT Holders'}
                />

                <div className={styles.selectedAddresses}>
                  {selectedSBTGroupsResponder.map((sbt) => (
                    <div key={sbt.address} className={styles.addressTag}>
                      <span className={styles.sbtName}>{sbt.name || sbt.address}</span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={styles.removeIcon}
                        size="lg"
                        onClick={() => this.handleRemoveSBTIncludeResponder(sbt.address)}
                      />
                    </div>
                  ))}
                </div>

                <SBTSelector
                  id="excludeResponder"
                  network={this.props.network}
                  provider={this.props.provider}
                  onAddSBT={this.handleAddSBTExcludeResponder}
                  selectedSBTs={excludedSBTGroupsResponder}
                  label={'Exclude Responses from SBT Holders'}
                />

                <div className={styles.selectedAddresses}>
                  {excludedSBTGroupsResponder.map((sbt) => (
                    <div key={sbt.address} className={styles.addressTag}>
                      <span className={styles.sbtName}>{sbt.name || sbt.address}</span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={styles.removeIcon}
                        size="lg"
                        onClick={() => this.handleRemoveSBTExcludeResponder(sbt.address)}
                      />
                    </div>
                  ))}
                </div>

                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={onlyVerifiedHumans}
                      onChange={this.toggleVerifiedHumans}
                    />
                    Only see verified human responses
                  </Label>
                </FormGroup>
              </>
            )}

            {/* ------------------------------------------------------------------------------------- */}
            {(mode === 'addresses') && (
              <>
                <SBTSelector
                  id="includeAddresses"
                  network={this.props.network}
                  provider={this.props.provider}
                  onAddSBT={this.handleAddSBTInclude}
                  selectedSBTs={selectedSBTGroups}
                  label={'Include Addresses holding SBT'}
                />

                <div className={styles.selectedAddresses}>
                  {selectedSBTGroups.map((sbt) => (
                    <div key={sbt.address} className={styles.addressTag}>
                      <span className={styles.sbtName}>{sbt.name || sbt.address}</span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={styles.removeIcon}
                        size="lg"
                        onClick={() => this.handleRemoveSBTInclude(sbt.address)}
                      />
                      <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        className={styles.linkIcon}
                        size="lg"
                        onClick={() => window.open(`/sbt/${sbt.address}`, '_blank')}
                      />
                    </div>
                  ))}
                </div>

                <SBTSelector
                  id="excludeAddresses"
                  network={this.props.network}
                  provider={this.props.provider}
                  onAddSBT={this.handleAddSBTExclude}
                  selectedSBTs={excludedSBTGroups}
                  label={'Exclude Addresses holding SBT'}
                />

                <div className={styles.selectedAddresses}>
                  {excludedSBTGroups.map((sbt) => (
                    <div key={sbt.address} className={styles.addressTag}>
                      <span className={styles.sbtName}>{sbt.name || sbt.address}</span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={styles.removeIcon}
                        size="lg"
                        onClick={() => this.handleRemoveSBTExclude(sbt.address)}
                      />
                      <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        className={styles.linkIcon}
                        size="lg"
                        onClick={() => window.open(`/sbt/${sbt.address}`, '_blank')}
                      />
                    </div>
                  ))}
                </div>

                <FormGroup check id={styles.verifiedHumansLabel}>
                  <Label check className={styles.greyedOutLabel}>
                    <Input
                      type="checkbox"
                      checked={onlyVerifiedHumans}
                      onChange={this.toggleVerifiedHumans}
                      id="humanOnlyCheckbox"
                      disabled={true}
                    />
                    <span id="verifiedHumansLabel">Only show verified human addresses<sup>soon</sup></span>
                  </Label>
                  <UncontrolledTooltip placement="right" target="verifiedHumansLabel">
                    We will use standards like OpenPassport soon.
                  </UncontrolledTooltip>
                </FormGroup>
              </>
            )}

            {(mode === 'creator' || mode === 'creatorAndResponder' || mode === 'questions' || mode === 'questionResponses') && (
              <>
                {/* SBT filters for question creators */}
                {(mode === 'creator' || mode === 'creatorAndResponder' || mode === 'questions') && (
                  <>
                    <SBTSelector
                      id="includeCreator"
                      network={this.props.network}
                      provider={this.props.provider}
                      onAddSBT={this.handleAddSBTIncludeCreator}
                      selectedSBTs={selectedSBTGroupsCreator}
                      label={'Include Questions created by SBT Holders'}
                    />

                    <div className={styles.selectedAddresses}>
                      {selectedSBTGroupsCreator.map((sbt) => (
                        <div key={sbt.address} className={styles.addressTag}>
                          <span className={styles.sbtName}>{sbt.name || sbt.address}</span>
                          <FontAwesomeIcon
                            icon={faTimes}
                            className={styles.removeIcon}
                            size="lg"
                            onClick={() => this.handleRemoveSBTIncludeCreator(sbt.address)}
                          />
                        </div>
                      ))}
                    </div>

                    <SBTSelector
                      id="excludeCreator"
                      network={this.props.network}
                      provider={this.props.provider}
                      onAddSBT={this.handleAddSBTExcludeCreator}
                      selectedSBTs={excludedSBTGroupsCreator}
                      label={'Exclude Questions created by SBT Holders'}
                    />

                    <div className={styles.selectedAddresses}>
                      {excludedSBTGroupsCreator.map((sbt) => (
                        <div key={sbt.address} className={styles.addressTag}>
                          <span className={styles.sbtName}>{sbt.name || sbt.address}</span>
                          <FontAwesomeIcon
                            icon={faTimes}
                            className={styles.removeIcon}
                            size="lg"
                            onClick={() => this.handleRemoveSBTExcludeCreator(sbt.address)}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                )}

                {/* SBT filters for question responders */}
                {(mode === 'responder' ||
                  mode === 'creatorAndResponder' ||
                  mode === 'questionResponses') && (
                  <>
                    <h5>SBT Group(s) of Question Responder</h5>
                    <SBTSelector
                      id="includeResponder"
                      network={this.props.network}
                      provider={this.props.provider}
                      onAddSBT={this.handleAddSBTIncludeResponder}
                      selectedSBTs={selectedSBTGroupsResponder}
                      label={'Include Responses from SBT Holders'}
                    />

                    <div className={styles.selectedAddresses}>
                      {selectedSBTGroupsResponder.map((sbt) => (
                        <div key={sbt.address} className={styles.addressTag}>
                          <span className={styles.sbtName}>{sbt.name || sbt.address}</span>
                          <FontAwesomeIcon
                            icon={faTimes}
                            className={styles.removeIcon}
                            size="lg"
                            onClick={() => this.handleRemoveSBTIncludeResponder(sbt.address)}
                          />
                        </div>
                      ))}
                    </div>

                    <SBTSelector
                      id="excludeResponder"
                      network={this.props.network}
                      provider={this.props.provider}
                      onAddSBT={this.handleAddSBTExcludeResponder}
                      selectedSBTs={excludedSBTGroupsResponder}
                      label={'Exclude Responses from SBT Holders'}
                    />

                    <div className={styles.selectedAddresses}>
                      {excludedSBTGroupsResponder.map((sbt) => (
                        <div key={sbt.address} className={styles.addressTag}>
                          <span className={styles.sbtName}>{sbt.name || sbt.address}</span>
                          <FontAwesomeIcon
                            icon={faTimes}
                            className={styles.removeIcon}
                            size="lg"
                            onClick={() => this.handleRemoveSBTExcludeResponder(sbt.address)}
                          />
                        </div>
                      ))}
                    </div>

                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={onlyVerifiedHumans}
                          onChange={this.toggleVerifiedHumans}
                        />
                        Only see verified human responses
                      </Label>
                    </FormGroup>
                  </>
                )}
              </>
            )}
          </div>
        )}

        {loading && (
          <div className={styles.loadingContainer}>
            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
            <p>{loadingText}</p>
          </div>
        )}
      </div>
    );
  }
}

export default SBTFilter;

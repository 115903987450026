import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faVoteYea, faSpinner, faScroll, faUsersCog, faBuilding, faArrowRight, faArrowLeft, faChevronDown, faChevronUp, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import styles from './CommunityTab.module.scss';
import historicalFigures from '../../variables/historical_figure_users.json';
import logoRxC from "../../assets/img/rxcLogo.png";
import logoPolis from "../../assets/img/polisLogo.png";
import { Modal, ModalHeader, ModalBody, Collapse, UncontrolledTooltip } from 'reactstrap';
import proposalScripts from 'components/UpcomingMatches/proposalScripts';
import contractScripts from '../Buttons/contractScripts.js';
import SBTsList from '../SBTs/SBTsList';
import SBTFilter from '../SBTs/SBTFilter';

class CommunityTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displaySection: 'groups',
      showModal: false,
      modalContent: { title: '', content: '' },
      showMoreLeaderboard: false,
      hideSimulatedUsers: false,
      hideHumanUsers: false, 
      sbtsCreatedCount: 0,
      loadingSbtsCreated: true,
      uniqueUsers: [],
      surveysCreatedCount: 0,
      surveyResponsesCount: 0,
      uniqueQuestionsCount: 0,
      loadingSurveyData: true,
      surveysList: [],
      questionsList: [],
      filteredUsers: [],
      loadingFilter: false,
      // Base stats array - counts will be updated dynamically
      stats: [
        { icon: faUsers, count: 0, label: 'Users' },
        { icon: faVoteYea, count: 0, label: 'Questions' },
        { icon: faScroll, count: 0, label: 'Surveys' },
        { icon: faUsersCog, count: 0, label: 'SBT Groups' },
      ],
      lastSbtGroupsCount: null, // track last count to prevent infinite loop
      initialLoadDone: false, // added to track when initial load is done
    };

    this.groups = [
      {
        name: 'RadicalxChange',
        logo: logoRxC,
        description: 'RadicalxChange is a global movement dedicated to reimagining the building blocks of democracy and markets in order to uphold fairness, plurality, and meaningful participation in a rapidly changing world.'
      },
      {
        name: 'Pol.is',
        logo: logoPolis,
        description: 'Pol.is is a pioneering software platform that facilitates large-scale conversations and helps identify areas of consensus and disagreement on complicated issues.'
      },
    ];
  }

  async checkIfInitialLoadDone() {
    // This function checks if the initial load is done by comparing the caches' lastBlock to the latest chain block
    const networkID = this.props.network?.id;
    if (!networkID) return false;

    let surveysCacheStr = localStorage.getItem('surveysCache');
    let questionsCacheStr = localStorage.getItem('questionsCache');
    let sbtCacheStr = localStorage.getItem('sbtCache');

    let surveysCache = {};
    let questionsCache = {};
    let sbtCache = {};

    if (surveysCacheStr) {
      try { surveysCache = JSON.parse(surveysCacheStr); } catch (e) { surveysCache = {}; }
    }
    if (questionsCacheStr) {
      try { questionsCache = JSON.parse(questionsCacheStr); } catch (e) { questionsCache = {}; }
    }
    if (sbtCacheStr) {
      try { sbtCache = JSON.parse(sbtCacheStr); } catch (e) { sbtCache = {}; }
    }

    if (!surveysCache[networkID] || !questionsCache[networkID] || !sbtCache[networkID]) {
      return false;
    }

    const surveyLastBlock = surveysCache[networkID].lastBlock || 0;
    const questionLastBlock = questionsCache[networkID].lastBlock || 0;
    const sbtLastBlock = sbtCache[networkID].lastBlock || 0;

    // Get the latest block number from chain
    const latestBlockNumber = await contractScripts.getLatestBlockNumber(this.props.provider);

    // If the minimum of these lastBlocks is >= latestBlockNumber, we consider initial load done
    const minLastBlock = Math.min(surveyLastBlock, questionLastBlock, sbtLastBlock);
    if (minLastBlock >= latestBlockNumber) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    console.log('CommunityTab mounted. Fetching initial data...');
    this.setState({ initialLoadDone: false });

    this.fetchSbtsCreatedCount();
    this.fetchSurveyDataFromCache();

    // Start updates every 1 second for the first 30 seconds, then switch to every 30 seconds
    this.updateIntervalCount = 0; // to track how many times we've run
    this.periodicStatsInterval = setInterval(() => {
      this.updateStatsPeriodically();
    }, 1000); // every 1 second initially
  }

  componentWillUnmount() {
    if (this.periodicStatsInterval) {
      clearInterval(this.periodicStatsInterval);
    }
  }

  async updateStatsPeriodically() {
    // This function re-fetches and updates from the cache regularly.
    // For the first 30 seconds, it updates every 1 second.
    // After 30 seconds, switch to every 30 seconds until we reach 2 minutes total.
    
    this.updateIntervalCount++;

    console.log('Periodically updating stats from cache...');
    await this.fetchSbtsCreatedCount();
    await this.fetchSurveyDataFromCache();

    // After updating from cache, check if initial load is done
    const loadDone = await this.checkIfInitialLoadDone();
    if (loadDone && !this.state.initialLoadDone) {
      this.setState({ initialLoadDone: true });
    }

    // If we've passed 30 updates (30 seconds), and currently interval is 1s, switch to 30s interval
    // We only do this once.
    if (this.updateIntervalCount === 30) {
      clearInterval(this.periodicStatsInterval);
      // now set interval to 30 seconds for the remainder of the 2 minutes
      // total 2 minutes = 120 seconds, after first 30 seconds, we have 90 seconds left => 3 intervals of 30s each max
      this.periodicStatsInterval = setInterval(() => {
        this.updateIntervalCount += 30; // increment by 30 each time since each interval is 30s
        if (this.updateIntervalCount > 120) {
          // Stop after 2 minutes total
          clearInterval(this.periodicStatsInterval);
          return;
        }
        console.log('Updating stats from cache (30s interval)...');
        this.fetchSbtsCreatedCount();
        this.fetchSurveyDataFromCache().then(async () => {
          const loadCheck = await this.checkIfInitialLoadDone();
          if (loadCheck && !this.state.initialLoadDone) {
            this.setState({ initialLoadDone: true });
          }
        });
      }, 30000); // every 30 seconds
    }

    if (this.updateIntervalCount > 120) {
      // Stop after 2 minutes total
      clearInterval(this.periodicStatsInterval);
    }
  }

  componentDidUpdate(prevProps) {
    // Refetch if network or provider changes
    if (prevProps.network?.id !== this.props.network?.id || prevProps.provider !== this.props.provider) {
      this.fetchSbtsCreatedCount();
    }

    // After updates, re-check SBT Groups count from cache to ensure it's updated
    this.updateSbtGroupsCountFromCache();
  }

  async fetchSbtsCreatedCount() {
    const { network } = this.props;
    this.setState({ loadingSbtsCreated: true });

    const networkID = network?.id;
    if (!networkID) {
      console.error('Network ID is undefined in CommunityTab. Cannot proceed.');
      this.setState({ loadingSbtsCreated: false });
      return;
    }

    try {
      // Read from cache
      const sbtCache = JSON.parse(localStorage.getItem('sbtCache')) || {};

      const cachedNetworkData = sbtCache[networkID];
      if (!cachedNetworkData || !cachedNetworkData.sbtList) {
        console.log(`No SBTs found in cache for network ID ${networkID}`);
        this.setState(prevState => ({
          sbtsCreatedCount: 0,
          loadingSbtsCreated: false,
          stats: prevState.stats.map(stat => stat.label === 'SBT Groups' ? { ...stat, count: 0 } : stat),
          lastSbtGroupsCount: 0,
        }));
        return;
      }

      const sbtList = cachedNetworkData.sbtList;

      // Create a Set to store unique SBT addresses
      const uniqueSbts = new Set(Object.keys(sbtList));

      const sbtsCreated = uniqueSbts.size;
      this.setState(prevState => ({
        sbtsCreatedCount: sbtsCreated,
        loadingSbtsCreated: false,
        stats: prevState.stats.map(stat => stat.label === 'SBT Groups' ? { ...stat, count: sbtsCreated } : stat),
        lastSbtGroupsCount: sbtsCreated,
      }));
    } catch (error) {
      console.error('Error fetching SBT Groups count:', error);
      this.setState(prevState => ({
        sbtsCreatedCount: 0,
        loadingSbtsCreated: false,
        stats: prevState.stats.map(stat => stat.label === 'SBT Groups' ? { ...stat, count: 0 } : stat),
        lastSbtGroupsCount: 0,
      }));
    }
  };

  async fetchSurveyDataFromCache() {
    // Do not remove existing functionality or comments.
    // This function is updated to use the new cache structure (surveysCache, questionsCache, sbtCache).

    // Get the current network ID
    const network = this.props.network;
    const networkID = network?.id;
    if (!networkID) {
      console.error('Network ID is undefined in fetchSurveyDataFromCache. Cannot proceed.');
      this.setState({ loadingSurveyData: false });
      return;
    }

    // Retrieve caches from localStorage
    let surveysCacheStr = localStorage.getItem('surveysCache');
    let questionsCacheStr = localStorage.getItem('questionsCache');
    let sbtCacheStr = localStorage.getItem('sbtCache');

    let surveysCache = {};
    let questionsCache = {};
    let sbtCache = {};

    if (surveysCacheStr) {
      try {
        surveysCache = JSON.parse(surveysCacheStr);
      } catch (e) {
        console.error('Error parsing surveysCache:', e);
        surveysCache = {};
      }
    }

    if (questionsCacheStr) {
      try {
        questionsCache = JSON.parse(questionsCacheStr);
      } catch (e) {
        console.error('Error parsing questionsCache:', e);
        questionsCache = {};
      }
    }

    if (sbtCacheStr) {
      try {
        sbtCache = JSON.parse(sbtCacheStr);
      } catch (e) {
        console.error('Error parsing sbtCache:', e);
        sbtCache = {};
      }
    }

    if (!surveysCache[networkID]) {
      surveysCache[networkID] = {
        lastBlock: 0,
        surveys: {},
        surveyResponses: {},
        surveyResponsesLastBlock: {}
      };
    }

    if (!questionsCache[networkID]) {
      questionsCache[networkID] = {
        lastBlock: 0,
        questions: {},
        questionResponses: {},
        questionResponsesLastBlock: {}
      };
    }

    if (!sbtCache[networkID]) {
      sbtCache[networkID] = {
        lastBlock: 0,
        sbtList: {}
      };
    }

    const surveysData = surveysCache[networkID].surveys || {};
    const surveyResponsesData = surveysCache[networkID].surveyResponses || {};
    const questionsData = questionsCache[networkID].questions || {};
    const questionResponsesData = questionsCache[networkID].questionResponses || {};
    const sbtList = sbtCache[networkID].sbtList || {};

    // Count surveys
    const surveysCreatedCount = Object.keys(surveysData).length;

    // Count questions
    const uniqueQuestionsCount = Object.keys(questionsData).length;

    // Calculate survey responses count: sum of all responders across all surveys
    let surveyResponsesCount = 0;
    // Add responders from survey responses
    for (const sId in surveyResponsesData) {
      const responders = Object.keys(surveyResponsesData[sId]);
      surveyResponsesCount += responders.length;
    }

    // Collect unique users set from various sources
    const uniqueUsersSet = new Set();

    // 1) Add survey creators
    for (const sId in surveysData) {
      if (surveysData[sId].creator) {
        uniqueUsersSet.add(surveysData[sId].creator.toLowerCase());
      }
    }

    // 2) Add survey responders
    for (const sId in surveyResponsesData) {
      const responders = Object.keys(surveyResponsesData[sId]);
      responders.forEach(r => uniqueUsersSet.add(r.toLowerCase()));
    }

    // 3) Add question creators
    for (const qId in questionsData) {
      if (questionsData[qId].creator) {
        uniqueUsersSet.add(questionsData[qId].creator.toLowerCase());
      }
    }

    // 4) Add question responders
    for (const qId in questionResponsesData) {
      const responders = Object.keys(questionResponsesData[qId]);
      responders.forEach(r => uniqueUsersSet.add(r.toLowerCase()));
    }

    // 5) Add SBT creators and minted addresses from sbtCache
    for (const sbtAddress in sbtList) {
      const sbtItem = sbtList[sbtAddress];
      // SBT creators (if available)
      if (sbtItem.sbtInfo && sbtItem.sbtInfo.creator) {
        uniqueUsersSet.add(sbtItem.sbtInfo.creator.toLowerCase());
      }
      // Minted addresses
      const mintedAddresses = sbtItem.mintedAddresses || [];
      mintedAddresses.forEach(addr => uniqueUsersSet.add(addr.toLowerCase()));
    }

    // Convert to array
    const uniqueUsers = Array.from(uniqueUsersSet);

    // Surveys list for modal: We'll list surveys with their IDs, titles, response counts, question counts
    const surveysList = Object.keys(surveysData).map(sId => {
      const survey = surveysData[sId];
      const questionIDs = survey.questionIDs || [];
      // Count responders for this survey from surveyResponsesData
      let numResponses = 0;
      if (surveyResponsesData[sId]) {
        numResponses = Object.keys(surveyResponsesData[sId]).length;
      }

      return {
        id: sId,
        title: survey.title || 'Untitled Survey',
        responsesCount: numResponses,
        questionsCount: questionIDs.length
      };
    });

    this.setState(prevState => {
      const updatedStats = prevState.stats.map(stat => {
        if (stat.label === 'Users') {
          return { ...stat, count: uniqueUsers.length };
        } else if (stat.label === 'Questions') {
          return { ...stat, count: uniqueQuestionsCount };
        } else if (stat.label === 'Surveys') {
          return { ...stat, count: surveysCreatedCount };
        } else {
          return stat;
        }
      });

      return {
        uniqueUsers,
        surveysCreatedCount,
        surveyResponsesCount,
        uniqueQuestionsCount,
        surveysList,
        loadingSurveyData: false,
        stats: updatedStats
      };
    });
  }

  updateSbtGroupsCountFromCache() {
    const { network } = this.props;
    const networkID = network?.id;
    if (!networkID) {
      return;
    }

    try {
      const sbtCache = JSON.parse(localStorage.getItem('sbtCache')) || {};
      const cachedNetworkData = sbtCache[networkID];
      if (!cachedNetworkData || !cachedNetworkData.sbtList) {
        if (this.state.lastSbtGroupsCount !== 0) {
          this.setState(prevState => ({
            stats: prevState.stats.map(stat => stat.label === 'SBT Groups' ? { ...stat, count: 0 } : stat),
            lastSbtGroupsCount: 0
          }));
        }
        return;
      }
      const sbtList = cachedNetworkData.sbtList;
      const uniqueSbts = new Set(Object.keys(sbtList));
      const sbtsCreated = uniqueSbts.size;

      if (sbtsCreated !== this.state.lastSbtGroupsCount) {
        this.setState(prevState => ({
          stats: prevState.stats.map(stat => stat.label === 'SBT Groups' ? { ...stat, count: sbtsCreated } : stat),
          lastSbtGroupsCount: sbtsCreated
        }));
      }
    } catch (error) {
      console.error('Error updating SBT Groups count from cache:', error);
    }
  }

  toggleDisplaySection = () => {
    this.setState(prevState => ({
      displaySection: prevState.displaySection === 'groups' ? 'individuals' : 'groups'
    }));
  }

  handleUserClick = (user) => {
    if (user.username.startsWith('0x')) {
      window.open(`/u/${user.username}`, '_blank');
    } else {
      window.open(`/su/${user.username}`, '_blank');
    }
  }

  handleStatClick(stat) {
    // Do not remove existing functionality or comments.
    // This updated function will display details using the newly stored data (uniqueUsers, surveysList, etc.)

    const { provider, network, account, loginComplete } = this.props;

    console.log('Network ID used in CommunityTab:', network?.id);
    console.log('Network Name used in CommunityTab:', network?.name);

    if (stat.label === 'SBT Groups') {
      // Show SBT details as before
      this.setState({
        modalContent: {
          title: `${stat.label} Details`,
          content: (
            <SBTsList
              provider={provider}
              network={network}
              account={account}
              loginComplete={loginComplete}
              miniaturized={true}
              toggleLoginModal={this.props.toggleLoginModal}
            />
          )
        },
        showModal: true
      });
    } else if (stat.label === 'Users') {
      // Display filtered users with SBTFilter
      this.setState(
        { filteredUsers: this.state.uniqueUsers, loadingFilter: false, showModal: true },
        () => {
          this.setState({
            modalContent: {
              title: `${stat.label} Details`,
              content: () => (
                <div>
                  <SBTFilter
                    items={this.state.uniqueUsers}
                    mode="addresses"
                    provider={provider}
                    network={network}
                    onFilter={(filteredUsers) => {
                      this.setState({ filteredUsers: filteredUsers, loadingFilter: false });
                    }}
                  />
                  {this.state.loadingFilter ? (
                    <div>Loading...</div>
                  ) : (
                    <div className={styles.userList}>
                      {this.state.filteredUsers.map((address, index) => (
                        <div key={index} className={styles.userItem}>
                          {proposalScripts.getShortenedAddress(address, true)}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )
            }
          });
        }
      );
    } else if (stat.label === 'Surveys') {
      // Show surveys list
      this.setState({
        modalContent: {
          title: `${stat.label} Details`,
          content: (
            <div className={styles.surveyList}>
              {this.state.surveysList.map((survey, index) => (
                <div key={index} className={styles.surveyItem}>
                  <a
                    href={`/survey/${survey.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.surveyLink}
                  >
                    {survey.title}
                  </a>
                  <span className={styles.questionsCount}>
                    Questions: {survey.questionsCount}
                  </span>
                  <span
                    className={styles.responsesCount}
                    onClick={() => window.open(`/survey/${survey.id}`, '_blank')}
                  >
                    Responses: {survey.responsesCount}
                  </span>
                </div>
              ))}
              {this.state.surveysList.length === 0 && <p>No surveys found.</p>}
            </div>
          )
        },
        showModal: true
      });
    } else if (stat.label === 'Questions') {
      // Just a link to /questions
      this.setState({
        modalContent: {
          title: `${stat.label} Details`,
          content: (
            <div>
              <p id={styles.seeQuestionsText}>
                See questions{' '}
                <a href="/questions" target="_blank" rel="noopener noreferrer" id={styles.seeQuestionsLinkText}>
                  here
                </a>
              </p>
            </div>
          )
        },
        showModal: true
      });
    } else {
      // Default behavior for other stats if any
      this.setState({
        modalContent: {
          title: `${stat.label} Details`,
          content: `Detailed information about ${stat.label.toLowerCase()} will be displayed here.`
        },
        showModal: true
      });
    }
  }

  getUserInfo(address) {
    // Placeholder function to get user info; replace with actual implementation
    return {
      name: address,
      username: address,
      avatar: `https://robohash.org/${address}`,
    };
  }

  handleGroupClick = (group) => {
    this.setState({
      modalContent: {
        title: group.name,
        content: (
          <div>
            <img src={group.logo} alt={`${group.name} logo`} className={styles.groupLogo} />
            <p>{group.description}</p>
          </div>
        )
      },
      showModal: true
    });
  }

  renderLeaderboard() {
    const { uniqueUsers, hideSimulatedUsers, hideHumanUsers, showMoreLeaderboard } = this.state;
  
    let realUsers = uniqueUsers.map(address => this.getUserInfo(address));
    let simulatedUsers = historicalFigures;
  
    let leaderboardData = [];
  
    if (hideSimulatedUsers && hideHumanUsers) {
      leaderboardData = [];
    } else if (hideSimulatedUsers) {
      leaderboardData = realUsers;
    } else if (hideHumanUsers) {
      leaderboardData = simulatedUsers;
    } else {
      leaderboardData = [...simulatedUsers, ...realUsers];
    }
  
    const uniqueLeaderboardData = Array.from(new Set(leaderboardData.map(user => user.username)))
      .map(username => {
        return leaderboardData.find(user => user.username === username)
      });
  
    // Sort alphabetically by name
    uniqueLeaderboardData.sort((a, b) => a.name.localeCompare(b.name));
  
    const topThree = uniqueLeaderboardData.slice(0, 4);
    const remainingEntries = uniqueLeaderboardData.slice(4);
  
    if (uniqueLeaderboardData.length === 0) {
      return <div className={styles.noUsers}>None yet!</div>;
    }
  
    return (
      <>
        {topThree.map((user, index) => (
          <div key={index} className={styles.leaderboardItem} onClick={() => this.handleUserClick(user)}>
            <img src={user.avatar} alt={user.name} className={styles.avatar} />
            <span className={styles.name}>
              {user.username.startsWith('0x') && (
                <>
                  {proposalScripts.getShortenedAddress(user.name, true)}
                </>
              )}
              {!user.username.startsWith('0x') && (
                <>
                  {user.name}
                  <span className={styles.simBadge} id={`sim-tooltip-${index}`}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </span>
                  <UncontrolledTooltip placement="right" target={`sim-tooltip-${index}`}>
                    This is a simulated user.
                  </UncontrolledTooltip>
                </>
              )}
            </span>
          </div>
        ))}
        <Collapse isOpen={showMoreLeaderboard}>
          {remainingEntries.map((user, index) => (
            <div key={index + 4} className={styles.leaderboardItem} onClick={() => this.handleUserClick(user)}>
              <img src={user.avatar} alt={user.name} className={styles.avatar} />
              <span className={styles.name}>
                {user.username.startsWith('0x') && (
                  <>
                    {proposalScripts.getShortenedAddress(user.name, true)}
                  </>
                )}
                {!user.username.startsWith('0x') && (
                  <>
                    {user.name}
                    <span className={styles.simBadge} id={`sim-tooltip-${index + 4}`}>
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    </span>
                    <UncontrolledTooltip placement="right" target={`sim-tooltip-${index + 4}`}>
                      This is a simulated user.
                    </UncontrolledTooltip>
                  </>
                )}
              </span>
            </div>
          ))}
        </Collapse>
        {uniqueLeaderboardData.length > 4 && (
          <button 
            onClick={() => this.setState(prevState => ({ showMoreLeaderboard: !prevState.showMoreLeaderboard }))} 
            className={styles.showMoreButton}
          >
            {showMoreLeaderboard ? (
              <>
                Show Less <FontAwesomeIcon icon={faChevronUp} />
              </>
            ) : (
              <>
                Show More <FontAwesomeIcon icon={faChevronDown} />
              </>
            )}
          </button>
        )}
      </>
    );
  }

  render() {
    const { displaySection, showModal, modalContent, hideSimulatedUsers, hideHumanUsers, loadingSbtsCreated, stats } = this.state;
    
    return (
      <div className={styles.communityTab}>
        <div className={styles.leaderboardSection}>
          <h2 className={styles.sectionTitle}>Leaderboard</h2>
          <div className={styles.leaderboardHeader}>
            <label className={styles.toggleLabel}>
              <input
                type="checkbox"
                checked={hideSimulatedUsers}
                onChange={() => this.setState(prevState => ({ hideSimulatedUsers: !prevState.hideSimulatedUsers }))}
                className={styles.toggleCheckbox}
              />
              Hide Simulated Users
            </label>
            <label className={styles.toggleLabel}>
              <input
                type="checkbox"
                checked={hideHumanUsers}
                onChange={() => this.setState(prevState => ({ hideHumanUsers: !prevState.hideHumanUsers }))}
                className={styles.toggleCheckbox}
              />
              Hide Human Users
            </label>
          </div>
          <div className={styles.content}>
            <div className={styles.leaderboard}>
              <div className={styles.leaderboardHeader}>
                {/* <span>Name</span>
                <span>Karma</span> */}
              </div>
              {this.renderLeaderboard()}
            </div>
          </div>
        </div>
        <div className={styles.rightSection}>
          <div className={styles.statsSection}>
            <h2 className={styles.sectionTitle}>
              Statistics{' '}
              {!this.state.initialLoadDone && (
                <FontAwesomeIcon icon={faSpinner} spin style={{ marginLeft: '10px' }} />
              )}
            </h2>
            <div className={styles.statsGrid}>
              {stats.map((stat, index) => (
                <div key={index} className={styles.statItem} onClick={() => this.handleStatClick(stat)}>
                  <FontAwesomeIcon icon={stat.icon} size="2x" className={styles.statIcon} />
                  <span className={styles.statCount}>
                    {stat.label === 'SBT Groups' && loadingSbtsCreated ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      stat.count
                    )}
                  </span>
                  <span className={styles.statLabel}>{stat.label}</span>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.recognitionSection}>
            <h2 className={styles.sectionTitle}>Recognition</h2>
            <div className={styles.content}>
              {displaySection === 'groups' ? (
                <>
                  <div className={styles.groupsGrid}>
                    {this.groups.map((group, index) => (
                      <div key={index} className={styles.groupItem} onClick={() => this.handleGroupClick(group)}>
                        <img src={group.logo} alt={`${group.name} logo`} className={styles.groupLogo} />
                        <span>{group.name}</span>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.noIndividuals}>
                    No recognized individuals yet.
                  </div>
                  <button onClick={this.toggleDisplaySection} className={styles.switchButton}>
                    <FontAwesomeIcon icon={faArrowLeft} /> Groups
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
  
        <Modal isOpen={showModal} toggle={() => this.setState({ showModal: false })} className={styles.modal}>
          <ModalHeader toggle={() => this.setState({ showModal: false })} className={styles.modalHeader}>
            {modalContent.title}
          </ModalHeader>
          <ModalBody className={styles.modalBody}>
            {modalContent.content && typeof modalContent.content === 'function'
              ? modalContent.content()
              : modalContent.content}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default CommunityTab;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// react plugin used to create DropdownMenu for selecting items
import Select from 'react-select';
// import SubmitProposalButton from '../Buttons/SubmitProposalButton.jsx';

// Scripts
import proposalScripts from './proposalScripts.js';
import contractScripts from '../Buttons/contractScripts.js';

// CSS
import 'assets/css/m_w.css';
import styles from './UpcomingMatches.module.scss';

// Components

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardFooter, Collapse, Col } from 'reactstrap';

// Formats
const TikTokIcon = proposalScripts.getProposalCardIcon('TIKTOK');
const TikTokLabel = (
  <>
    {TikTokIcon} {' TIKTOK'}
  </>
);

const ImageIcon = proposalScripts.getProposalCardIcon('IMAGE');
const ImageLabel = (
  <>
    {ImageIcon} {' IMAGE'}
  </>
);
const TweetIcon = proposalScripts.getProposalCardIcon('TWEET');
const TweetLabel = (
  <>
    {TweetIcon} {' TWEET'}
  </>
);
const TextIcon = proposalScripts.getProposalCardIcon('TEXT');
const TextLabel = (
  <>
    {TextIcon} {' TEXT'}
  </>
);
// Vote Types
const QuadraticIcon = proposalScripts.getProposalVoteTypeIcon('QUADRATIC');
const QuadraticLabel = (
  <>
    {QuadraticIcon} {' QUADRATIC'}
  </>
);
const RankedChoiceIcon = proposalScripts.getProposalVoteTypeIcon('RCV');
const RankedChoiceLabel = (
  <>
    {RankedChoiceIcon} {' RCV'}
  </>
);
const OnePersonOneVoteIcon = proposalScripts.getProposalVoteTypeIcon('1P1V');
const OnePersonOneVoteLabel = (
  <>
    {OnePersonOneVoteIcon} {' 1P1V'}
  </>
);

class CollapseProposalPrompt extends Component {
  state = {
    visible: true,
    openedCollapses: [''],
    format: '',
    voteType: '',
    prompt: '',
  };

  componentDidMount() {}

  componentDidUpdate() {}

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
  };

  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: this.state.openedCollapses.filter(prop => prop !== collapse),
      });
    } else {
      this.setState({
        openedCollapses: [...this.state.openedCollapses, collapse],
      });
    }
  };


  launchLoginModal = (toggled) => {
    this.props.toggleLoginModal(toggled);
  }

  submitProposalTX = async () => {
    this.collapsesToggle('collapseOne');
    await contractScripts.submitProposal(
      this.props.account,
      this.props.provider,
      this.props.lobby,
      this.props.paid,
      this.state.format.value,
      this.state.prompt,
    );
  };

  render() {
    var cardColor = this.state.format.value == '' ? 'blue' : proposalScripts.getProposalCardColor(this.state.format.value);

    // var warningMessageColor = this.state.format.value == 'IMAGE' ? 'image' : 'tiktok'; // TODO: for more formats
    var warningMessageColor = 'tiktok'; // TODO: for more formats

    const submitWarningMessage = this.props.loginComplete ? (
      <Button className="btn-link ml-1" color={warningMessageColor}>
        Choose format to submit
      </Button>
    ) : (
      <Button onClick={this.launchLoginModal} className="btn-link ml-1" color={warningMessageColor}>
        Log in to submit
      </Button>
    );

    const submitButton =
      this.state.format == '' || this.state.voteType == '' || !this.props.loginComplete ? (
        submitWarningMessage
      ) : (
        <>
          <Button id={styles.submitProposalButton} className="btn-simple" color="primary" type="button" onClick={this.submitProposalTX}>
            SUBMIT
          </Button>
        </>
      );

    // relevantIcon = this.state.format != "TIKTOK" ? <i className="fas fa-image"></i> : <i className="fas fa-music"></i>; // TODO: update
    // var relevantText = this.state.format != "TIKTOK" ? "I" : "T";

    const promptPrompt = (
      <>
        <form onSubmit={this.onSubmit} id={styles.promptInputForm}>
          <label>
            Prompt 
            (Optional) - 
            {/* <div id={styles.pleaseBeKind}> Please Be Kind</div> */}
             
          </label>
          <input id={styles.promptInputField} type="text" name="prompt" onChange={this.onChange} value={this.state.prompt} />
        </form>
      </>
    );

    const selectFormatBox = (
      <Select
        id={styles.proposalFormSelectBox}
        name="singleSelect"
        value={this.state.format}
        onChange={value => this.setState({ format: value })}
        options={[
          { value: 'TEXT', label: TextLabel },
          { value: 'IMAGE', label: ImageLabel },
          { value: 'TWEET', label: TweetLabel, isDisabled: true },
          { value: 'TIKTOK', label: TikTokLabel, isDisabled: true },
        ]}
        placeholder="Format"
      />
    );

    const selectVoteTypeBox = (
      <Select
        id={styles.proposalFormSelectBox}
        name="singleSelect"
        value={this.state.voteType}
        onChange={value => this.setState({ voteType: value })}
        options={[
          { value: 'QUADRATIC', label: QuadraticLabel },
          { value: 'RANKED-CHOICE', label: RankedChoiceLabel, isDisabled: true },
          { value: '1P1V', label: OnePersonOneVoteLabel, isDisabled: true },
        ]}
        placeholder="Vote Type"
      />
    );

    // const proposalEntryDisplay = this.state.openedCollapses.includes("collapseOne") ?

    return (
      <div aria-multiselectable={true} className="card-collapse" id="accordion">
        <Card id={styles.proposalPromptCard}>
          <CardHeader id={styles.proposalPromptHeader} role="tab">
            <a
              className="d-flex"
              href=""
              data-toggle="collapse"
              aria-expanded={this.state.openedCollapses.includes('collapseOne')}
              onClick={e => {
                e.preventDefault();
                this.collapsesToggle('collapseOne');
              }}
            >
              <h3 id={styles.collapseTitleProposal}>
                + ADD
              </h3>
            </a>
          </CardHeader>
          <Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes('collapseOne')} id={styles.collapseBody}>
              <Card id={styles.enterProposalCard} data-background-color={cardColor}>
                <CardBody id={styles.collapseCardBody}>

                  { selectFormatBox }

                  { selectVoteTypeBox }

                  { promptPrompt }

                  {/* </div> */}
                  <div className={styles.normalProposalFooter}>
                    <CardFooter id={styles.enterProposalCardFooter}>
                      <div className="">{submitButton}</div>
                    </CardFooter>
                  </div>
                </CardBody>
              </Card>
          </Collapse>
        </Card>
      </div>
    );
  }
}

export default CollapseProposalPrompt;

import React, { useEffect, useState, useRef } from 'react';
import contractScripts from '../Buttons/contractScripts.js';
import proposalScripts from '../UpcomingMatches/proposalScripts.js';
import styles from './SBTsList.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faExpand, faSync, faTrash, faCircle, faLock, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
import SBTPage from './SBTPage';
import { featured_SBTs_LIST } from '../../variables/CONTRACT_ADDRESSES.js';
import CreateGroup from './CreateSBTGroup';

const SBTsList = ({ provider, modalView, network, account, loginComplete, miniaturized, toggleLoginModal }) => {
  const [sbtList, setSbtList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cache, setCache] = useState(JSON.parse(localStorage.getItem('sbtCache')) || {});
  const [refreshing, setRefreshing] = useState(false);
  const [lastFetchBlock, setLastFetchBlock] = useState(0);
  const [hideFeaturedFromMintingLive, setHideFeaturedFromMintingLive] = useState(true);
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [excludePasswordLocked, setExcludePasswordLocked] = useState(false); // New state for filtering password-locked SBTs
  const isMounted = useRef(true);
  const BATCH_SIZE = 5;

  // Interval reference for cleanup
  const refreshIntervalRef = useRef(null);
  // Load Order (to prevent glitching behavior)
  const originalLoadOrderRef = useRef({});
  const originalLoadCounterRef = useRef(0);

  const fetchSBTs = async (forceRefresh = false, showLoading = true) => {
    if (showLoading) {
      setLoading(true);
    }
    let sbtDetails = [];
    let latestBlock;

    const networkID = network?.id;
    if (!networkID) {
      console.error('Network ID is undefined in SBTsList. Cannot proceed.');
      if (showLoading) {
        if (isMounted.current) setLoading(false);
      }
      return;
    }

    try {
      const cachedNetworkData = cache[networkID];
      const cachedSbtList = cachedNetworkData ? cachedNetworkData.sbtList : {};
      const cacheLatestBlock = cachedNetworkData ? cachedNetworkData.lastBlock : 0;
      latestBlock = await contractScripts.getLatestBlockNumber(provider);

      let newSbts = [];
      if (cacheLatestBlock < latestBlock || forceRefresh) {
        newSbts = await contractScripts.getSbtsCreated(
          provider,
          cacheLatestBlock + 1,
          latestBlock
        );
      }

      const existingSbtAddresses = Object.keys(cachedSbtList).map(addr => addr.toLowerCase());
      const newSbtAddresses = newSbts.map((sbt) => sbt.sbtAddress.toLowerCase());
      const allSbtAddresses = [...new Set([...existingSbtAddresses, ...newSbtAddresses])];

      // Ensure featured SBTs are included
      const featuredLower = featured_SBTs_LIST.map(addr => addr.toLowerCase());
      for (const fAddr of featuredLower) {
        if (!allSbtAddresses.includes(fAddr)) {
          allSbtAddresses.push(fAddr);
        }
      }

      const processedSbts = {};
      for (let i = 0; i < allSbtAddresses.length; i += BATCH_SIZE) {
        const batch = allSbtAddresses.slice(i, i + BATCH_SIZE);
        const batchPromises = batch.map(async (sbtAddressLower) => {
          try {
            const cachedSBT = cachedSbtList[sbtAddressLower];

            let sbtInfo;
            let mintedAddresses;
            let burnedAddresses;
            let fromBlockForFetch = 0;

            // If cached data exists, use it and fetch new events from cachedSBT.blockNumber+1 to latestBlock
            if (cachedSBT && cachedSBT.sbtInfo && cachedSBT.mintedAddresses && cachedSBT.burnedAddresses && typeof cachedSBT.blockNumber === 'number') {
              sbtInfo = cachedSBT.sbtInfo;
              mintedAddresses = cachedSBT.mintedAddresses;
              burnedAddresses = cachedSBT.burnedAddresses;

              if (cachedSBT.blockNumber < latestBlock) {
                fromBlockForFetch = cachedSBT.blockNumber + 1;
              } else {
                fromBlockForFetch = latestBlock;
              }
            } else {
              // Fetch fresh metadata if not in cache
              sbtInfo = await contractScripts.getSbtMetadata(provider, sbtAddressLower);
            }

            // Now fetch any new minted/burned events after fromBlockForFetch
            // If fromBlockForFetch == latestBlock and cached data exists, no need to re-fetch events
            if (!mintedAddresses || !burnedAddresses || fromBlockForFetch < latestBlock) {
              const eventsFromBlock = mintedAddresses && burnedAddresses ? fromBlockForFetch : 0;
              const newlyMinted = await contractScripts.getAddressesWhoMintedSBT(provider, sbtAddressLower, eventsFromBlock, 'latest');
              const newlyBurned = await contractScripts.getAddressesWhoBurnedSBT(provider, sbtAddressLower, eventsFromBlock, 'latest');

              if (!mintedAddresses || !burnedAddresses) {
                mintedAddresses = newlyMinted.map(addr => addr.toLowerCase());
                burnedAddresses = newlyBurned.map(addr => addr.toLowerCase());
              } else {
                const updatedMinted = new Set(mintedAddresses);
                newlyMinted.forEach(addr => updatedMinted.add(addr.toLowerCase()));
                mintedAddresses = Array.from(updatedMinted);

                const updatedBurned = new Set(burnedAddresses);
                newlyBurned.forEach(addr => updatedBurned.add(addr.toLowerCase()));
                burnedAddresses = Array.from(updatedBurned);
              }
            }

            // Assign a stable local load order if not present
            if (typeof originalLoadOrderRef.current[sbtAddressLower] !== 'number') {
              originalLoadCounterRef.current += 1;
              originalLoadOrderRef.current[sbtAddressLower] = originalLoadCounterRef.current;
            }

            return {
              sbtAddress: sbtAddressLower,
              sbtInfo,
              mintedAddresses,
              burnedAddresses,
              blockNumber: latestBlock
              // Note: No originalLoadOrder stored in the returned object. It's kept locally only.
            };
          } catch (error) {
            console.error(`Error processing SBT ${sbtAddressLower}:`, error);
            return null;
          }
        });

        const batchResults = await Promise.all(batchPromises);
        batchResults.forEach((result) => {
          if (result) {
            const sbtAddressLower = result.sbtAddress.toLowerCase();
            processedSbts[sbtAddressLower] = result;
          }
        });

        sbtDetails = Object.values({ ...cachedSbtList, ...processedSbts });
        if (isMounted.current) {
          setSbtList(sbtDetails);
        }
      }

      const newCache = {
        ...cache,
        [networkID]: {
          ...cache[networkID],
          lastBlock: latestBlock,
          sbtList: {
            ...cachedSbtList,
            ...processedSbts,
          },
        },
      };

      localStorage.setItem('sbtCache', JSON.stringify(newCache));
      setCache(newCache);
      setLastFetchBlock(latestBlock);
      sbtDetails = Object.values(newCache[networkID].sbtList);
      // Filter out incomplete data
      sbtDetails = sbtDetails.filter(
        (sbt) => sbt && sbt.sbtAddress && sbt.sbtInfo && sbt.mintedAddresses && sbt.burnedAddresses
      );

      // Sort by net minted descending, then by originalLoadOrderRef ascending to maintain stability
      sbtDetails.sort((a, b) => {
        const netMintedA = a.mintedAddresses.length - a.burnedAddresses.length;
        const netMintedB = b.mintedAddresses.length - b.burnedAddresses.length;
        if (netMintedB !== netMintedA) {
          return netMintedB - netMintedA;
        } else {
          const orderA = originalLoadOrderRef.current[a.sbtAddress.toLowerCase()] || 0;
          const orderB = originalLoadOrderRef.current[b.sbtAddress.toLowerCase()] || 0;
          return orderA - orderB;
        }
      });

      const uniqueSbtDetails = [];
      const seenAddresses = new Set();
      for (const sbt of sbtDetails) {
        if (!sbt || !sbt.sbtAddress) continue;
        const address = sbt.sbtAddress.toLowerCase();
        if (!seenAddresses.has(address)) {
          seenAddresses.add(address);
          uniqueSbtDetails.push(sbt);
        }
      }

      if (isMounted.current) {
        setSbtList(uniqueSbtDetails);
        if (showLoading) {
          setLoading(false);
        }
      }
    } catch (error) {
      console.error('Error fetching SBTs:', error);
      if (isMounted.current) {
        if (showLoading) {
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    isMounted.current = true;

    const setupAutoRefresh = async () => {
      await fetchSBTs(false, true);

      refreshIntervalRef.current = setInterval(async () => {
        if (isMounted.current) {
          const currentBlock = await contractScripts.getLatestBlockNumber(provider);
          if (currentBlock > lastFetchBlock) {
            console.log("New blocks detected, updating...");
            await fetchSBTs(false, false);
          }
        }
      }, 30000);
    };

    setupAutoRefresh();
    initializeEventListener();

    return () => {
      // Cleanup to prevent memory leaks
      isMounted.current = false;
      if (refreshIntervalRef.current) {
        clearInterval(refreshIntervalRef.current);
      }
      contractScripts.removeSBTEventListener(provider);
    };
  }, [provider, network]); // eslint-disable-line react-hooks/exhaustive-deps

  const initializeEventListener = () => {
    contractScripts.listenForSBTEvents(provider, handleNewEvent);
  };

  const handleNewEvent = async (event) => {
    console.log('New SBT event detected:', event);
    if (event.eventSignature === "SBTCreated(address)") {
      await updateCacheFromEvent(event);
    } else if (event.eventSignature === "Issued(address,uint256)") {
      await fetchSBTs(false, false); 
    } else if (event.eventSignature === "Transfer(address,address,uint256)") {
      await fetchSBTs(false, false); 
    }
  };

  const updateCacheFromEvent = async (event) => {
    const networkID = network?.id;
    if (!networkID) return;
    try {
      const sbtAddress = event.args && event.args.sbtAddress ? event.args.sbtAddress : event.sbtAddress;
      const sbtAddressLower = sbtAddress.toLowerCase();
      const sbtInfo = await contractScripts.getSbtMetadata(provider, sbtAddress);
      let mintedAddresses = await contractScripts.getAddressesWhoMintedSBT(provider, sbtAddress, 0, 'latest');
      let burnedAddresses = await contractScripts.getAddressesWhoBurnedSBT(provider, sbtAddress, 0, 'latest');

      mintedAddresses = mintedAddresses.map((addr) => addr.toLowerCase());
      burnedAddresses = burnedAddresses.map((addr) => addr.toLowerCase());

      const latestBlock = await contractScripts.getLatestBlockNumber(provider);

      // Assign a stable local load order if not present
      if (typeof originalLoadOrderRef.current[sbtAddressLower] !== 'number') {
        originalLoadCounterRef.current += 1;
        originalLoadOrderRef.current[sbtAddressLower] = originalLoadCounterRef.current;
      }

      const newCache = {
        ...cache,
        [networkID]: {
          ...cache[networkID],
          sbtList: {
            ...cache[networkID]?.sbtList,
            [sbtAddressLower]: {
              sbtAddress,
              sbtInfo,
              mintedAddresses,
              burnedAddresses,
              blockNumber: latestBlock
              // No originalLoadOrder in cache
            },
          },
          lastBlock: latestBlock
        },
      };

      localStorage.setItem('sbtCache', JSON.stringify(newCache));
      setCache(newCache);

      if (isMounted.current) {
        setSbtList((prevList) => {
          const newList = [...prevList];
          const index = newList.findIndex((sbt) => sbt && sbt.sbtAddress && sbt.sbtAddress.toLowerCase() === sbtAddressLower);
          if (index === -1) {
            newList.push({ sbtAddress, sbtInfo, mintedAddresses, burnedAddresses, blockNumber: latestBlock });
          } else {
            newList[index] = { sbtAddress, sbtInfo, mintedAddresses, burnedAddresses, blockNumber: latestBlock };
          }
          return newList;
        });
      }
    } catch (error) {
      console.error('Error handling new SBT event:', error);
    }
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    await fetchSBTs(true);
    setRefreshing(false);
  };

  const handleClearCache = async () => {
    localStorage.removeItem('sbtCache');
    setCache({});
    await fetchSBTs(true);
  };

  const isMintingLive = (sbt) => {
    const now = Math.floor(Date.now() / 1000);
    if (sbt && sbt.sbtInfo && typeof sbt.sbtInfo.mintingEndTime !== 'undefined') {
      return (sbt.sbtInfo.mintingEndTime === 0 || sbt.sbtInfo.mintingEndTime > now);
    } else {
      return false;
    }
  };

  // Filter out unlisted SBTs and apply excludePasswordLocked filter
  const filteredSbtList = sbtList.filter(sbt => sbt && sbt.sbtInfo && !sbt.sbtInfo.unlisted && (!excludePasswordLocked || !sbt.sbtInfo.hasPasswordMint));
  const mintingLiveSBTs = filteredSbtList.filter(isMintingLive);
  const expiredSBTs = filteredSbtList.filter(sbt => !isMintingLive(sbt));

  let filteredMintingLiveSBTs = mintingLiveSBTs;
  if (hideFeaturedFromMintingLive) {
    const featuredSet = new Set(featured_SBTs_LIST.map(addr => addr.toLowerCase()));
    filteredMintingLiveSBTs = mintingLiveSBTs.filter(sbt => sbt && sbt.sbtAddress && !featuredSet.has(sbt.sbtAddress.toLowerCase()));
  }

  const renderSBT = (sbt) => {
    if (!sbt || !sbt.sbtAddress || !sbt.sbtInfo || sbt.sbtInfo.unlisted) {
      return null;
    }
    const { sbtAddress, sbtInfo, mintedAddresses, burnedAddresses } = sbt;
    const { name, description, image } = sbtInfo;
    const netMinted = mintedAddresses.length - burnedAddresses.length;

    return (
      <button
        key={sbtAddress}
        className={styles.sbtItem}
        onClick={() => window.location.href = `/sbt/${sbtAddress}`}
      >
        <div className={styles.sbtImage}>
          <img src={image} alt="SBT Thumbnail" width="150" height="150" />
        </div>
        <div className={styles.sbtInfo}>
          <p className={styles.sbtName}>
            {name} {sbt.sbtInfo.hasPasswordMint && <FontAwesomeIcon icon={faLock} className={styles.lockIcon} />}
          </p>
          <p className={styles.sbtDescription}>{description}</p>
          <p className={styles.sbtNetMinted}>Net Minted: {netMinted}</p>
        </div>
      </button>
    );
  };

  if (miniaturized) {
    const miniFeatured = featured_SBTs_LIST.map((addr) => addr.toLowerCase());
    const miniFeaturedSBTs = sbtList.filter(sbt => sbt && sbt.sbtAddress && sbt.sbtInfo && !sbt.sbtInfo.unlisted && miniFeatured.includes(sbt.sbtAddress.toLowerCase()));
    const miniMintingLiveSBTs = filteredMintingLiveSBTs.filter(sbt => sbt && sbt.sbtAddress && sbt.sbtInfo && !sbt.sbtInfo.unlisted);
    const miniExpiredSBTs = expiredSBTs.filter(sbt => sbt && sbt.sbtAddress && sbt.sbtInfo && !sbt.sbtInfo.unlisted);

    return (
      <div className={styles.miniaturizedContainer} id={styles.sbtListContainer}>
        <h2 className={styles.sectionTitle}>Featured Groups</h2>
        {loading ? (
          <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
        ) : (
          <div className={styles.sbtGrid}>
            {miniFeaturedSBTs.map((sbt) => {
              if (!sbt || !sbt.sbtAddress) {
                return null;
              }
              const sbtName = sbt.sbtInfo.name;
              const sbtAddress = sbt.sbtAddress;
              return (
                <SBTPage
                  key={sbt.sbtAddress}
                  SBTAddress={sbt.sbtAddress}
                  account={account}
                  provider={provider}
                  network={network}
                  miniaturized={true}
                  loginComplete={loginComplete}
                  isMintingLive={isMintingLive(sbt)}
                  hasPasswordMint={sbt.sbtInfo.hasPasswordMint}
                />
              );
            })}
          </div>
        )}
        <h2 className={styles.sectionTitle}>Minting Live</h2>
        {loading ? (
          <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
        ) : (
          <div className={styles.sbtGrid}>
            {miniMintingLiveSBTs.map((sbt) => {
              if (!sbt || !sbt.sbtAddress) {
                return null;
              }
              return (
                <SBTPage
                  key={sbt.sbtAddress}
                  SBTAddress={sbt.sbtAddress}
                  account={account}
                  provider={provider}
                  network={network}
                  miniaturized={true}
                  loginComplete={loginComplete}
                  isMintingLive={isMintingLive(sbt)}
                  hasPasswordMint={sbt.sbtInfo.hasPasswordMint}
                />
              );
            })}
          </div>
        )}
        <h2 className={styles.sectionTitle}>Minting Expired</h2>
        {loading ? (
          <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
        ) : (
          <div className={styles.sbtGrid}>
            {miniExpiredSBTs.map((sbt) => {
              if (!sbt || !sbt.sbtAddress) {
                return null;
              }
              return (
                <SBTPage
                  key={sbt.sbtAddress}
                  SBTAddress={sbt.sbtAddress}
                  account={account}
                  provider={provider}
                  network={network}
                  miniaturized={true}
                  loginComplete={loginComplete}
                  isMintingLive={isMintingLive(sbt)}
                  hasPasswordMint={sbt.sbtInfo.hasPasswordMint}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.sbtListContainer}>
      <div className={styles.header}>
        <Button
          className={styles.refreshButton}
          onClick={handleRefresh}
          disabled={refreshing}
        >
          <FontAwesomeIcon icon={faSync} spin={refreshing} /> Refresh
        </Button>
        <Button
          className={styles.clearCacheButton}
          onClick={handleClearCache}
          disabled={refreshing}
        >
          <FontAwesomeIcon icon={faTrash} /> Clear Cache
        </Button>
        <Button
          className={styles.expandButton}
          onClick={() => setShowCreateGroup(!showCreateGroup)}
        >
          <FontAwesomeIcon icon={faPlus} /> {showCreateGroup ? "Exit Group Creation" : "Create Group"}
        </Button>
      </div>

      {/* New filter area for excluding password-locked SBTs */}
      <div className={styles.filterContainer}>
        <label className={styles.filterLabel}>
          <input
            type="checkbox"
            checked={excludePasswordLocked}
            onChange={() => setExcludePasswordLocked(!excludePasswordLocked)}
          />
          Exclude Password-Locked SBTs
        </label>
      </div>

      {showCreateGroup && (
        <CreateGroup
          account={account}
          loginComplete={loginComplete}
          provider={provider}
          toggleLoginModal={toggleLoginModal}
          expanded={showCreateGroup}
          network={network}
        />
      )}

      <h2 className={styles.sectionTitle}>Featured SBTs</h2>
      {loading ? (
        <div className={styles.loadingSection}><FontAwesomeIcon icon={faSpinner} spin size="2x" /></div>
      ) : (
        <div id={styles.featuredSBTsContainer}>
          {featured_SBTs_LIST.map((sbtAddress, index) => {
            const sbt = sbtList.find(s => s && s.sbtAddress && s.sbtAddress.toLowerCase() === sbtAddress.toLowerCase());
            if (!sbt || !sbt.sbtAddress || !sbt.sbtInfo || sbt.sbtInfo.unlisted) return null;
            return (
              <SBTPage
                key={index}
                SBTAddress={sbtAddress}
                account={account}
                provider={provider}
                network={network}
                miniaturized={true}
                loginComplete={loginComplete}
                isMintingLive={isMintingLive(sbt)}
                hasPasswordMint={sbt.sbtInfo.hasPasswordMint}
              />
            );
          })}
        </div>
      )}

      <h2 className={styles.sectionTitle}>Minting Live</h2>
      {loading ? (
        <div className={styles.loadingSection}><FontAwesomeIcon icon={faSpinner} spin size="2x" /></div>
      ) : (
        filteredMintingLiveSBTs.map((sbt) => {
          if (!sbt || !sbt.sbtAddress || sbt.sbtInfo.unlisted) return null;
          return renderSBT(sbt);
        })
      )}

      <h2 className={styles.sectionTitle}>Minting Expired</h2>
      {loading ? (
        <div className={styles.loadingSection}><FontAwesomeIcon icon={faSpinner} spin size="2x" /></div>
      ) : (
        expiredSBTs.map((sbt) => {
          if (!sbt || !sbt.sbtAddress || sbt.sbtInfo.unlisted) return null;
          return renderSBT(sbt);
        })
      )}
    </div>
  );
};

export default SBTsList;

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faCheck, faChevronDown, faChevronUp, faSpinner, faExclamationCircle, faExternalLinkAlt, faCopy } from '@fortawesome/free-solid-svg-icons';
import { ethers } from 'ethers';
import contractScripts from '../Buttons/contractScripts.js';
import { fetchImageFromURL } from '../../utilities/imageScripts.js'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { UncontrolledTooltip } from 'reactstrap';
import styles from './CreateSBTGroup.module.scss';

class CreateSBTGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sbtName: '',
      sbtDescription: '',
      sbtImageFile: null,
      sbtImageUrl: '',
      useImageUrl: false,
      sbtCodes: [],
      groupSubmitted: false,
      groupHash: '',
      sbtDistribution: {
        isLimited: false,
        limitedNumber: 0,
        hasAdmin: false,
        adminAddress: props.account || '',
        isRevocable: false,
        isTimeLimited: false,
        mintingEndTime: null,
        distributionOption: 'anyoneCanMint',
        burnAuth: 'AdminOnly',
        burnAdmin: props.account || '',
        network: props.network ? props.network : 'not connected',
        unlisted: false,
      },
      imageUploaded: false,
      tokenURI: '',
      tokenUriUploaded: false,
      sbtMinted: false,
      sbtAddress: '',
      passwordList: [],
      sbtInviteLinks: [],
      sbtInviteBackupDate: '',
      textToUpload: '',
      csvAddresses: '',
      estimatedMintCost: '0',
      tokenInfoCollapsed: true,
      mintOptionsCollapsed: true,
      distributionOptionsCollapsed: true,
      currentStep: 0,
      mintingFailed: false,
      numInviteLinks: 10,
      network: props.network ? props.network : 'not connected',
      copiedLinkIndex: null,
      exportFormat: 'json',
      countdown: 12,
      countdownActive: false,
      sbtSymbol: '',
      tags: '',
      documentIDHashes: '',
      documentUrl: '',
      showTagsInput: false,
      imageLoadError: false,
      documentURLs: [] // New array for multiple document URLs
    };
  }

  componentDidMount() {
    console.log("CreateSBTGroup – Initial network:", this.props.network);
    console.log("CreateSBTGroup – Initial network ID:", this.props.network ? this.props.network.id : 'not connected');
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.network !== prevProps.network) {
      this.setState({
        network: this.props.network ? this.props.network.id : 'not connected',
        sbtDistribution: {
          ...this.state.sbtDistribution,
          network: this.props.network ? this.props.network : 'not connected',
        },
      });
    }

    if (this.state.sbtDistribution.isLimited !== prevState.sbtDistribution.isLimited ||
        this.state.sbtDistribution.limitedNumber !== prevState.sbtDistribution.limitedNumber) {
      this.updateNumInviteLinks();
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
  
    if (name.startsWith('sbtDistribution.')) {
      this.setState(
        (prevState) => ({
          sbtDistribution: {
            ...prevState.sbtDistribution,
            [name.split('.')[1]]: value,
          },
        }),
        this.updateGroupHash
      );
    } else {
      this.setState({ [name]: value }, this.updateGroupHash);
  
      // If user changed sbtImageUrl, try to load the image automatically if it's a well-formed URL
      if (name === 'sbtImageUrl') {
        this.setState({ imageLoadError: false }, async () => {
          const trimmedUrl = this.state.sbtImageUrl.trim();
          let isValidUrl = false;
          try {
            const urlObj = new URL(trimmedUrl);
            if (urlObj.protocol === 'http:' || urlObj.protocol === 'https:') {
              isValidUrl = true;
            }
          } catch (e) {
            // invalid URL, do nothing
          }
  
          if (trimmedUrl !== '' && isValidUrl) {
            try {
              const file = await fetchImageFromURL(this.state.sbtImageUrl);
              this.setState({ sbtImageFile: file, imageLoadError: false }, this.updateGroupHash);
            } catch (error) {
              console.error("Failed to fetch image via worker:", error);
              this.setState({ imageLoadError: true, sbtImageFile: null });
            }
          } else {
            // If URL is empty or invalid, clear sbtImageFile
            this.setState({ sbtImageFile: null });
          }
        });
      }
    }
  };
  

  handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.size > 10 * 1024 * 1024) {
      console.error("Image too large (>10MB)");
      return;
    }
    this.setState({ sbtImageFile: file, sbtImageUrl: '', imageLoadError: false }, this.updateGroupHash);
  };

  toggleImageUploadMethod = () => {
    this.setState((prevState) => ({
      useImageUrl: !prevState.useImageUrl,
      sbtImageFile: null,
      sbtImageUrl: '',
      imageLoadError: false
    }), this.updateGroupHash);
  };

  resetImage = () => {
    this.setState({
      sbtImageFile: null,
      sbtImageUrl: '',
      imageLoadError: false,
      useImageUrl: false
    }, this.updateGroupHash);
  };

  toggleCollapse = (section) => {
    this.setState((prevState) => ({
      [section]: !prevState[section],
    }));
  };

  updateGroupHash = () => {
    const { sbtName, sbtDescription, sbtImageFile, sbtImageUrl, sbtDistribution } = this.state;
    const groupData = {
      sbtName,
      sbtDescription,
      sbtImageFile,
      sbtImageUrl,
      sbtDistribution,
    };

    const newGroupHash = ethers.utils.id(JSON.stringify(groupData));
    this.setState({ groupHash: newGroupHash });
  };

  handleMintingEndTimeChange = (date) => {
    this.setState(
      (prevState) => ({
        sbtDistribution: {
          ...prevState.sbtDistribution,
          mintingEndTime: date,
        },
      }),
      this.updateGroupHash
    );
  };

  handleBurnAuthChange = (event) => {
    const value = event.target.value;
    this.setState((prevState) => ({
      sbtDistribution: {
        ...prevState.sbtDistribution,
        burnAuth: value,
      },
    }));
  };

  startClaim = async () => {
    if (!this.state.countdownActive) {
      this.setState({ countdownActive: true, countdown: 12 });
      this.countdownTimer = setInterval(() => {
        this.setState(prevState => ({
          countdown: prevState.countdown - 1
        }), () => {
          if (this.state.countdown === 0) {
            clearInterval(this.countdownTimer);
            this.setState({ countdownActive: false });
          }
        });
      }, 1000);
    }
  };

  generatePasswords = () => {
    console.log("generatePasswords() invoked");
    const { numInviteLinks, sbtDistribution } = this.state;
    const count = sbtDistribution.isLimited && sbtDistribution.limitedNumber > 0 ? sbtDistribution.limitedNumber : numInviteLinks;
    console.log("numInviteLinks:", count);
    const newPasswordList = Array.from({ length: count }, () => this.generateRandomString(8));
    console.log("Generated passwords (unhashed):", newPasswordList);
    
    // Note: We do NOT store these passwords in localStorage here.
    // They will be associated and stored after the SBT is successfully minted.
    
    this.setState({ passwordList: newPasswordList });
    return newPasswordList;
  };  

  generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  async uploadImageToArweave() {
    if (!this.props.loginComplete) {
      console.log("User not logged in, can't upload image");
      return;
    }

    this.setState({ currentStep: 1, mintingFailed: false });

    const { sbtImageFile } = this.state;

    if (!sbtImageFile) {
      console.error("No image file available. Please upload or load from URL first.");
      this.setState({ mintingFailed: true });
      return;
    }

    try {
      let imageFormat = 'png';
      if (sbtImageFile.type === 'image/jpeg' || sbtImageFile.type === 'image/jpg') {
        imageFormat = 'jpg';
      }

      const imageTxId = await contractScripts.uploadDataToArweave(sbtImageFile, imageFormat);
      console.log("Image uploaded to Arweave with transaction ID:", imageTxId);
      this.setState({ imageUploaded: true, sbtImageUrl: `https://arweave.net/${imageTxId}`, currentStep: 2 }, this.uploadTokenUriToArweave);
    } catch (error) {
      console.error("Failed to upload image to Arweave:", error);
      this.setState({ mintingFailed: true });
    }
  }

  async uploadTokenUriToArweave() {
    this.setState({ currentStep: 2, mintingFailed: false });
    try {
      const { sbtName, sbtDescription, sbtImageUrl, sbtDistribution, tags, documentIDHashes, documentUrls, documentURLs } = this.state;
      const { burnAuth, network, unlisted } = sbtDistribution;
      const tokenTags = tags.trim().length > 0 ? tags.split(',').map(t => t.trim()) : [];
      const docIDHashesArray = documentIDHashes.trim().length > 0 ? documentIDHashes.split(',').map(d => d.trim()) : [];
      const chainID = this.props.network?.id;
      const creator = this.props.account;

      // Incorporate documentURLs array
      const tokenUriData = JSON.stringify({
        name: sbtName || "My Test NFT",
        description: sbtDescription || "A test NFT",
        image: sbtImageUrl || "https://arweave.net/WOXBKQEkUPHrOeOhBBckB7OWzrqidnvUnJyW_KUlIOY",
        burnAuth,
        network: network?.name,
        unlisted,
        tags: tokenTags,
        chainID: chainID,
        creator: creator,
        documentIDHashes: docIDHashesArray,
        documentURLs: documentURLs // new field
      });
      const tokenUriTxId = await contractScripts.uploadDataToArweave(tokenUriData, 'json');
      console.log("Token URI uploaded to Arweave with transaction ID:", tokenUriTxId);
      this.setState({ tokenUriUploaded: true, tokenURI: `${tokenUriTxId}`, currentStep: 3 }, this.mintSBT);
    } catch (error) {
      console.error("Failed to upload token URI to Arweave:", error);
      this.setState({ mintingFailed: true });
    }
  }

  getBurnAuthEnum = (burnAuth) => {
    switch (burnAuth) {
      case 'AdminOnly':
        return 0;
      case 'OwnerOnly':
        return 1;
      case 'Both':
        return 2;
      case 'Neither':
        return 3;
      default:
        throw new Error(`Unsupported burnAuth value: ${burnAuth}`);
    }
  };

  async mintSBT() {
    console.log("mintSBT() invoked");
    console.log("Current state at mintSBT:", this.state);
  
    if (!this.props.loginComplete) {
      this.props.toggleLoginModal(true);
      return;
    }
  
    this.setState({ currentStep: 3, mintingFailed: false });
  
    const { sbtName, sbtDistribution, passwordList, tokenURI } = this.state;
    const { isLimited, limitedNumber, burnAdmin, isTimeLimited, mintingEndTime, distributionOption, burnAuth } = sbtDistribution;
    const hasPersonalizedUrl = (distributionOption === 'hasPasswords');
  
    const burnAuthEnum = this.getBurnAuthEnum(burnAuth);
  
    try {
      const sbtCount = await contractScripts.countSBTCreated(this.props.provider);
      const sbtSymbol = `CE-SBT-${sbtCount + 1}`;
      this.setState({ sbtSymbol });
      console.log('sbtSymbol:', sbtSymbol);
  
      let finalPasswordList = passwordList;
      if (hasPersonalizedUrl && passwordList.length === 0) {
        console.log("No passwords detected, generating now...");
        finalPasswordList = this.generatePasswords();
      }
  
      console.log("passwordList before hashing:", finalPasswordList);
  
      const hashedPasswords = finalPasswordList.map(password => {
        const hashed = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(password));
        console.log(`Password: ${password} | Hashed: ${hashed}`);
        return hashed;
      });
  
      console.log('Hashed passwords array:', hashedPasswords);
  
      const mintingEndTimeUnix = isTimeLimited && sbtDistribution.mintingEndTime ? Math.floor(sbtDistribution.mintingEndTime.getTime() / 1000) : 0;
  
      console.log("Calling createSBT with parameters:", {
        name: sbtName,
        symbol: sbtSymbol,
        limitedNumber: isLimited ? limitedNumber : 0,
        adminAddress: burnAdmin || this.props.account,
        mintingEndTime: mintingEndTimeUnix,
        hasPersonalizedUrl,
        burnAuthEnum,
        hashedPasswords,
        tokenURI: 'https://arweave.net/' + `${tokenURI}`
      });
  
      const receipt = await contractScripts.createSBT(
        this.props.provider,
        sbtName,
        sbtSymbol,
        isLimited ? limitedNumber : 0,
        burnAdmin || this.props.account,
        mintingEndTimeUnix,
        hasPersonalizedUrl,
        burnAuthEnum,
        hashedPasswords,
        'https://arweave.net/' + `${tokenURI}`
      );
  
      console.log('Transaction receipt for createSBT:', receipt);
  
      const sbtAddress = receipt.events[0].address;
      console.log('sbtAddress:', sbtAddress);
  
      // Now that we have a successful transaction and the SBT address, 
      // we store the generated passwords in localStorage associated with the sbtAddress.
      if (hasPersonalizedUrl && finalPasswordList.length > 0 && sbtAddress) {
        let createdSBTs = JSON.parse(localStorage.getItem('createdSBTs') || '{}');
        if (!createdSBTs[sbtAddress]) {
          createdSBTs[sbtAddress] = {};
        }
        createdSBTs[sbtAddress].passwords = finalPasswordList;
        localStorage.setItem('createdSBTs', JSON.stringify(createdSBTs));
        console.log("Associated passwords with SBT address in localStorage:", sbtAddress);
      }
  
      this.setState({ sbtMinted: true, sbtAddress, currentStep: 4, passwordList: finalPasswordList });
  
      if (hasPersonalizedUrl) {
        console.log("Generating SBT invite links for personalized URLs...");
        await this.generateSBTInviteLinks(sbtAddress);
        console.log("SBT invite links generated:", this.state.sbtInviteLinks);
      }
  
      console.log('SBT minted with address:', sbtAddress);
    } catch (error) {
      console.error("Failed to mint SBT in mintSBT:", error);
      this.setState({ mintingFailed: true });
    }
  }  

  async generateSBTInviteLinks(sbtAddress) {
    const { passwordList } = this.state;
    const sbtInviteLinks = passwordList.map(password => `${window.location.origin}/sbt/${sbtAddress}/${password}`);
    const sbtInviteBackupDate = new Date().toISOString().slice(0, 10);

    this.setState({
      sbtInviteLinks,
      sbtInviteBackupDate
    });
  }

  massSendSBTs = async () => {
    const { csvAddresses } = this.state;
    const addresses = csvAddresses.split(',').map((address) => address.trim());

    try {
      console.log("Stub function for mass sending SBTs to addresses:", addresses);
    } catch (error) {
      console.error("Failed to mass send SBTs:", error);
    }
  };

  updateNumInviteLinks = () => {
    if (this.state.sbtDistribution.isLimited && this.state.sbtDistribution.distributionOption === 'hasPasswords') {
      this.setState({ numInviteLinks: this.state.sbtDistribution.limitedNumber });
    }
  };

  handleNumInviteLinksChange = (event) => {
    const numInviteLinks = parseInt(event.target.value);
    this.setState({ numInviteLinks });
  };

  copyToClipboard = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      this.setState({ copiedLinkIndex: index });
      setTimeout(() => this.setState({ copiedLinkIndex: null }), 2000);
    });
  };

  handleExportFormatChange = (event) => {
    this.setState({ exportFormat: event.target.value });
  };

  exportPasswords = () => {
    const { passwordList, sbtInviteLinks, exportFormat, sbtSymbol, sbtName } = this.state;
    const date = new Date().toISOString().slice(0, 10);
    let content;
    let fileName;

    if (exportFormat === 'json') {
      content = JSON.stringify(passwordList.map((password, index) => ({
        index,
        password,
        inviteLink: sbtInviteLinks[index]
      })), null, 2);
      fileName = `${sbtSymbol}_${sbtName}_passwords_${date}.json`;
    } else if (exportFormat === 'csv') {
      content = 'index,password,inviteLink\n' +
        passwordList.map((password, index) =>
          `${index},${password},${sbtInviteLinks[index]}`
        ).join('\n');
      fileName = `${sbtSymbol}_${sbtName}_passwords_${date}.csv`;
    }

    const blob = new Blob([content], { type: exportFormat === 'json' ? 'application/json' : 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  handleMintClick = async () => {
    if (!this.props.account) {
      this.props.toggleLoginModal(true);
      return;
    }
    if (this.state.currentStep > 0) return;

    // If using an image URL, ensure we have fetched and converted it to a file first
    if (this.state.useImageUrl && this.state.sbtImageUrl && !this.state.sbtImageFile) {
      try {
        const file = await fetchImageFromURL(this.state.sbtImageUrl);
        this.setState({ sbtImageFile: file, imageLoadError: false }, this.updateGroupHash);
      } catch (error) {
        console.error("No image file available after attempting to fetch from URL.");
        this.setState({ imageLoadError: true });
        return;
      }
    }

    this.uploadImageToArweave();
  }

  async handleImageLoaded(imgRef) {
    try {
      imgRef.crossOrigin = "anonymous";
      const imageElement = imgRef;
      if (!imageElement.complete || imageElement.naturalWidth === 0) {
        console.error("Image not fully loaded or invalid.");
        this.setState({ sbtImageFile: null, imageLoadError: true });
        return;
      }

      const canvas = document.createElement('canvas');
      canvas.width = imageElement.naturalWidth;
      canvas.height = imageElement.naturalHeight;
      const ctx = canvas.getContext('2d');

      imageElement.setAttribute('crossOrigin', 'anonymous');

      ctx.drawImage(imageElement, 0, 0);
      const blob = await new Promise((resolve, reject) => {
        canvas.toBlob((b) => {
          if (!b) reject(new Error("Failed to create blob from canvas"));
          resolve(b);
        }, 'image/png');
      });
      if (!blob) {
        console.error("Failed to convert image to blob");
        this.setState({ sbtImageFile: null, imageLoadError: true });
        return;
      }
      const file = new File([blob], "url_image.png", { type: "image/png" });
      this.setState({ sbtImageFile: file, imageLoadError: false }, this.updateGroupHash);
    } catch (error) {
      console.error("Error converting image:", error);
      this.setState({ sbtImageFile: null, imageLoadError: true });
    }
  }

  // Function to add a document URL to the array
  addDocumentURL = () => {
    const { documentUrl, documentURLs } = this.state;
    if (documentUrl.trim() !== '' && documentURLs.length < 10) {
      this.setState((prevState) => ({
        documentURLs: [...prevState.documentURLs, prevState.documentUrl.trim()],
        documentUrl: ''
      }), this.updateGroupHash);
    }
  };

  // Function to remove a document URL from the array
  removeDocumentURL = (index) => {
    this.setState((prevState) => {
      const newURLs = [...prevState.documentURLs];
      newURLs.splice(index, 1);
      return { documentURLs: newURLs };
    }, this.updateGroupHash);
  };

  render() {
    const {
      sbtName,
      sbtDescription,
      sbtImageFile,
      sbtImageUrl,
      useImageUrl,
      sbtDistribution,
      imageUploaded,
      tokenURI,
      tokenUriUploaded,
      sbtMinted,
      sbtAddress,
      csvAddresses,
      tokenInfoCollapsed,
      mintOptionsCollapsed,
      distributionOptionsCollapsed,
      currentStep,
      mintingFailed,
      sbtInviteLinks,
      numInviteLinks,
      network,
      copiedLinkIndex,
      exportFormat,
      sbtSymbol,
      tags,
      documentIDHashes,
      documentUrl,
      showTagsInput,
      imageLoadError,
      documentURLs
    } = this.state;

    const calendarStyles = {
      color: 'black',
    };

    const isHasPasswords = sbtDistribution.distributionOption === 'hasPasswords';
    const isLimitedWithPasswords = sbtDistribution.isLimited && isHasPasswords;

    return (
      <div id={styles.createGroupExpanded}>
        <div className={styles.headerContainer}>
          <h1 className={styles.createGroupTitle}>Create SBT / Group</h1>
          <FontAwesomeIcon icon={faQuestionCircle} className={styles.tooltip} id="learnMoreTooltip" style={{opacity:0.5}} />
          <UncontrolledTooltip placement="right" target="learnMoreTooltip" delay={{ show: 0, hide: 5000 }} className={styles.tooltip}>
            Learn more about SBTs (Soulbound Tokens = Non-transferrable NFTs). <br />
            <a href="https://github.com/Ensoul-Labs/awesome-soulbound-tokens" target="_blank" rel="noopener noreferrer">
              More Info
            </a>
          </UncontrolledTooltip>
        </div>
        <div className={styles.collapsibleSection}>
          <h3 onClick={() => this.toggleCollapse('tokenInfoCollapsed')}>
            SBT Token Info {tokenInfoCollapsed ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}
          </h3>
          {!tokenInfoCollapsed && (
            <div className={styles.inputColumn}>
              <input
                type="text"
                name="sbtName"
                value={sbtName}
                onChange={this.handleInputChange}
                placeholder="SBT Name"
                id={styles.sbtName}
              />
              <input
                type="text"
                name="sbtDescription"
                value={sbtDescription}
                onChange={this.handleInputChange}
                placeholder="Event / Group Description"
                id={styles.sbtDescription}
              />

              <div className={styles.imageUploadContainer}>
                <label className={styles.imageUploadLabel}>SBT Image</label>
                <div className={styles.imageUploadOptions}>
                  <label className={styles.imageUploadOption}>
                    <input
                      type="radio"
                      checked={!useImageUrl}
                      onChange={this.toggleImageUploadMethod}
                      className={styles.imageUploadOptionLabel}
                    />
                    Upload Image
                  </label>
                  <label className={styles.imageUploadOption}>
                    <input
                      type="radio"
                      checked={useImageUrl}
                      onChange={this.toggleImageUploadMethod}
                      className={styles.imageUploadOptionLabel}
                    />
                    Use Image URL
                  </label>
                </div>
                {!useImageUrl ? (
                  <div className={styles.fileUploadContainer}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={this.handleImageUpload}
                      style={{ display: 'none' }}
                      ref={(fileInput) => (this.fileInput = fileInput)}
                    />
                    <button
                      type="button"
                      onClick={() => this.fileInput.click()}
                      className={styles.fileUploadButton}
                    >
                      Choose Image
                    </button>
                    {sbtImageFile && (
                      <span className={styles.selectedFile}>
                        {sbtImageFile.name}
                      </span>
                    )}
                  </div>
                ) : (
                  <div className={styles.fileUploadContainer}>
                    <input
                      type="text"
                      name="sbtImageUrl"
                      value={sbtImageUrl}
                      onChange={this.handleInputChange}
                      placeholder="Enter image URL"
                    />
                  </div>
                )}
              </div>

              {/* Image Preview or Error */}
              {useImageUrl && sbtImageUrl && !imageLoadError && sbtImageFile && (
                <div className={styles.imagePreview}>
                  <img
                    src={URL.createObjectURL(sbtImageFile)}
                    alt="SBT"
                  />
                  <button type="button" onClick={this.resetImage} className={styles.removeImageButton}>X</button>
                </div>
              )}

              {useImageUrl && sbtImageUrl && imageLoadError && (
                <div className={styles.imagePreview}>
                  <span style={{color: 'red', fontSize: '2em'}}>Image Load Error &#10006;</span>
                  <button type="button" onClick={this.resetImage} className={styles.removeImageButton}>X</button>
                </div>
              )}

              {!useImageUrl && sbtImageFile && (
                <div className={styles.imagePreview}>
                  <img
                    src={URL.createObjectURL(sbtImageFile)}
                    alt="SBT"
                  />
                  <button type="button" onClick={this.resetImage} className={styles.removeImageButton}>X</button>
                </div>
              )}

              {!useImageUrl && !sbtImageFile && !imageLoadError && (
                <div></div>
              )}

              {useImageUrl && !sbtImageUrl && !imageLoadError && (
                <div></div>
              )}

              <div id={styles.addDocUrlSection}>

                <input
                  type="text"
                  name="documentUrl"
                  value={documentUrl}
                  onChange={this.handleInputChange}
                  placeholder="Document URL"
                />
                <button
                  type="button"
                  onClick={this.addDocumentURL}
                  disabled={documentUrl.trim() === '' || documentURLs.length >= 10}
                >
                  Add Document URL
                </button>

              </div>

              {documentURLs.length > 0 && (
                <ul style={{marginTop:'10px'}}>
                  {documentURLs.map((url, index) => (
                    <li key={index}>
                      {url} <button type="button" onClick={() => this.removeDocumentURL(index)}>Remove</button>
                    </li>
                  ))}
                </ul>
              )}

              {!showTagsInput && (
                <button type="button" onClick={() => this.setState({ showTagsInput: true })}>
                  Add tags
                </button>
              )}
              {showTagsInput && (
                <input
                  type="text"
                  name="tags"
                  value={tags}
                  onChange={this.handleInputChange}
                  placeholder="Tags (comma separated)"
                  id={styles.sbtTags}
                />
              )}
            </div>
          )}
        </div>
        <div className={styles.collapsibleSection}>
          <h3 onClick={() => this.toggleCollapse('mintOptionsCollapsed')}>
            SBT Mint Options {mintOptionsCollapsed ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}
          </h3>
          {!mintOptionsCollapsed && (
            <div className={styles.sbtTokenOptions}>
              <label>
                <input
                  type="checkbox"
                  name="sbtDistribution.isLimited"
                  checked={sbtDistribution.isLimited}
                  onChange={this.handleInputChange}
                />
                <span>Limited Number of Tokens</span>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className={styles.tooltip}
                  id="limitedNumberTooltip"
                  style={{opacity:0.5}}
                />
                <UncontrolledTooltip placement="right" target="limitedNumberTooltip">
                  Specify the maximum number of SBTs that can be minted.
                </UncontrolledTooltip>
              </label>
              {sbtDistribution.isLimited && (
                <input
                  type="number"
                  name="sbtDistribution.limitedNumber"
                  value={sbtDistribution.limitedNumber}
                  onChange={this.handleInputChange}
                  placeholder="Enter limited number"
                />
              )}
              <label>
                <input
                  type="checkbox"
                  name="sbtDistribution.isTimeLimited"
                  checked={sbtDistribution.isTimeLimited}
                  onChange={this.handleInputChange}
                />
                <span id={styles.timeLimitedMintingText}>Time-Limited Minting</span>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className={styles.tooltip}
                  id="timeLimitedTooltip"
                  style={{opacity:0.5}}
                />
                <UncontrolledTooltip placement="right" target="timeLimitedTooltip">
                  Set an end time for the minting period. After this time, no more SBTs can be minted.
                </UncontrolledTooltip>
              </label>
              {sbtDistribution.isTimeLimited && (
                <div className={styles.timeLimitedOptions}>
                  <DatePicker
                    selected={sbtDistribution.mintingEndTime}
                    onChange={this.handleMintingEndTimeChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    calendarClassName={styles.blackText}
                    style={calendarStyles}
                  />
                  <span className={styles.unixTimestamp}>
                    Unix Timestamp: {sbtDistribution.mintingEndTime ? Math.floor(sbtDistribution.mintingEndTime.getTime() / 1000) : '-'}
                  </span>
                </div>
              )}
              <label>
                <span>Burn Authorization</span>
                <select name="sbtDistribution.burnAuth" value={sbtDistribution.burnAuth} onChange={this.handleBurnAuthChange}>
                  <option value="AdminOnly">Admin Only</option>
                  <option value="OwnerOnly">Owner Only</option>
                  <option value="Both">Both</option>
                  <option value="Neither">Neither</option>
                </select>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className={styles.tooltip}
                  id="burnAuthTooltip"
                  style={{opacity:0.5}}
                />
                <UncontrolledTooltip placement="right" target="burnAuthTooltip">
                  Specify who can burn the token: Admin Only, Owner Only, Both, or Neither.
                </UncontrolledTooltip>
              </label>
              <label>
                <span>Burn Admin Address </span>
                <input
                  type="text"
                  name="sbtDistribution.burnAdmin"
                  value={sbtDistribution.burnAdmin}
                  onChange={this.handleInputChange}
                  placeholder="default = deployer address"
                  id={styles.burnAdminAddressInput}
                />
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className={styles.tooltip}
                  id="burnAdminTooltip"
                  style={{opacity:0.5}}
                />
                <UncontrolledTooltip placement="right" target="burnAdminTooltip">
                  Enter the address that can burn the token. Defaults to the connected address.
                </UncontrolledTooltip>
              </label>
              <label>
                <span>Network: </span>
                <button className={styles.networkButton} onClick={() => this.props.toggleLoginModal(true)}>
                  {network?.name}
                </button>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className={styles.tooltip}
                  id="networkTooltip"
                  style={{opacity:0.5}}
                />
                <UncontrolledTooltip placement="right" target="networkTooltip">
                  Select the network for minting.
                </UncontrolledTooltip>
              </label>
            </div>
          )}
        </div>
        <div className={styles.collapsibleSection}>
          <h3 onClick={() => this.toggleCollapse('distributionOptionsCollapsed')}>
            SBT Distribution Options {distributionOptionsCollapsed ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}
          </h3>
          {!distributionOptionsCollapsed && (
            <div className={styles.distributionOptions}>
              <label>
                <input
                  type="radio"
                  name="sbtDistribution.distributionOption"
                  value="hasPasswords"
                  checked={sbtDistribution.distributionOption === 'hasPasswords'}
                  onChange={this.handleInputChange}
                />
                One-use URLs
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className={styles.tooltip}
                  id="oneUseTooltip"
                  style={{opacity:0.5}}
                />
                <UncontrolledTooltip placement="right" target="oneUseTooltip">
                  Generate one-use URLs / passwords for each SBT.
                </UncontrolledTooltip>
              </label>
              {isHasPasswords && (
                <div className={styles.inviteLinksOptions}>
                  <label>
                    Number of Invite Links:
                    <input
                      type="number"
                      value={isLimitedWithPasswords ? sbtDistribution.limitedNumber : numInviteLinks}
                      onChange={isLimitedWithPasswords ? () => {} : this.handleNumInviteLinksChange}
                      min="1"
                      disabled={isLimitedWithPasswords}
                    />
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      className={styles.tooltip}
                      id="numInviteLinksTooltip"
                      style={{opacity:0.5}}
                    />
                    <UncontrolledTooltip placement="right" target="numInviteLinksTooltip">
                      {sbtDistribution.isLimited
                        ? `Exactly ${sbtDistribution.limitedNumber} invite links will be generated.`
                        : "An admin can create more invite links later if needed."}
                    </UncontrolledTooltip>
                  </label>
                </div>
              )}
              <label>
                <input
                  type="radio"
                  name="sbtDistribution.distributionOption"
                  value="anyoneCanMint"
                  checked={sbtDistribution.distributionOption === 'anyoneCanMint'}
                  onChange={this.handleInputChange}
                />
                URL where anyone can mint
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className={styles.tooltip}
                  id="anyoneCanMintTooltip"
                  style={{opacity:0.5}}
                />
                <UncontrolledTooltip placement="right" target="anyoneCanMintTooltip">
                  Generate a URL where anyone can mint the SBT.
                </UncontrolledTooltip>
              </label>

              <label>
                <input
                  type="checkbox"
                  name="sbtDistribution.unlisted"
                  checked={sbtDistribution.unlisted}
                  onChange={this.handleInputChange}
                />
                <span>Unlisted SBT</span>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className={styles.tooltip}
                  id="unlistedTooltip"
                  style={{opacity:0.5}}
                />
                <UncontrolledTooltip placement="right" target="unlistedTooltip">
                  If checked, the SBT will not appear in the public list but will still be discoverable via the Arweave transaction if not encrypted.
                </UncontrolledTooltip>
              </label>
            </div>
          )}
        </div>
        <div className={styles.mintingSteps}>
          <button onClick={this.handleMintClick}>
            {currentStep === 0 && 'Mint SBT'}
            {currentStep === 1 && 'Uploading Image...'}
            {currentStep === 2 && 'Uploading URI...'}
            {currentStep === 3 && 'Minting SBT...'}
            {mintingFailed && currentStep > 0 && <FontAwesomeIcon icon={faExclamationCircle} style={{ marginLeft: '10px', color: 'red' }} />}
          </button>
        </div>
        <div className={styles.progressIndicator}>
          <div className={currentStep >= 1 ? styles.stepCompleted : styles.step}>
            <FontAwesomeIcon icon={currentStep === 1 ? faSpinner : currentStep > 1 ? faCheck : faExclamationCircle} spin={currentStep === 1} />
            <span>Upload Image</span>
          </div>
          <div className={currentStep >= 2 ? styles.stepCompleted : styles.step}>
            <FontAwesomeIcon icon={currentStep === 2 ? faSpinner : currentStep > 2 ? faCheck : faExclamationCircle} spin={currentStep === 2} />
            <span>Upload URI</span>
          </div>
          <div className={currentStep >= 3 ? styles.stepCompleted : styles.step}>
            <FontAwesomeIcon icon={currentStep === 3 ? faSpinner : currentStep > 3 ? faCheck : faExclamationCircle} spin={currentStep === 3} />
            <span>Mint SBT</span>
          </div>
        </div>
        <div className={styles.sbtContractAddress}>
          <span>SBT Contract Address: </span>
          {sbtAddress ? (
            <a
              href={`/sbt/${sbtAddress}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} /> SBT Page ({sbtAddress})
            </a>
          ) : (
            <span>-</span>
          )}
        </div>
        {sbtInviteLinks.length > 0 && sbtMinted && (
          <div className={styles.sbtInviteLinks}>
            <h3>SBT Invite Links:</h3>
            <ul>
              {sbtInviteLinks.map((link, index) => (
                <li key={index} className={styles.inviteLinkItem}>
                  <span className={styles.inviteLink}>{link}</span>
                  <button onClick={() => this.copyToClipboard(link, index)} className={styles.copyButton}>
                    <FontAwesomeIcon icon={copiedLinkIndex === index ? faCheck : faCopy} />
                  </button>
                </li>
              ))}
            </ul>
            <div className={styles.exportOptions}>
              <select value={exportFormat} onChange={this.handleExportFormatChange} className={styles.exportFormatSelect}>
                <option value="json">JSON</option>
                <option value="csv">CSV</option>
              </select>
              <button onClick={this.exportPasswords} className={styles.exportButton}>Export Passwords</button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CreateSBTGroup;

import { CLOUDFLARE_CORS_WORKER_URL } from '../variables/CONTRACT_ADDRESSES.js';

export const fetchImageFromURL = async (imageUrl) => {
  if (!imageUrl || imageUrl.trim() === '') {
    console.error("No URL provided for image.");
    throw new Error("No URL provided");
  }

  try {
    const response = await fetch(CLOUDFLARE_CORS_WORKER_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        url: imageUrl,
        action: 'fetch_image'
      })
    });

    if (!response.ok) {
      let errorMessage = 'Failed to fetch image';
      try {
        const errorData = await response.json();
        if (errorData.error) {
          errorMessage = errorData.error;
        }
      } catch (e) {
        // ignore JSON parse error
      }

      if (response.status === 403) {
        errorMessage += ' (Permission Denied (403))';
      }

      console.error("Failed to fetch image via worker:", errorMessage);
      throw new Error(errorMessage);
    }

    const blob = await response.blob();
    if (blob.size > 10 * 1024 * 1024) {
      console.error("Image too large (>10MB)");
      throw new Error("Image too large");
    }

    const contentType = blob.type;
    if (!contentType || !contentType.startsWith('image/')) {
      console.error("URL does not point to a valid image");
      throw new Error("Invalid image type");
    }

    const fileExtension = contentType.includes('jpeg') ? 'jpg' : contentType.split('/')[1] || 'png';
    const file = new File([blob], `remote_image.${fileExtension}`, { type: contentType });
    return file;
  } catch (error) {
    console.error("Failed to fetch image via worker:", error);
    throw error;
  }
};

// PolisReport.jsx:

import React, { useEffect, useState, useRef } from 'react';
import * as d3 from 'd3';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQuestionCircle,
  faSpinner,
  faCog,
  faInfoCircle,
  faMinusSquare,
  faPlusSquare,
  faCaretDown,
  faCaretUp
} from '@fortawesome/free-solid-svg-icons';

import {
  computePolisStats,
  beeswarmByExtremity,
  getCommentBarData,
  clusterUMAPPointsKmeans,
  findRepresentativeQuestions,
  silhouetteScore,
  computeJointSVD,
  doUMAP,
  computeQuestionDivisiveness
} from '../../utilities/polisMath';

import demoData from '../../variables/demoPolisData.json';
import styles from './PolisReport.module.scss';

/**************************************************************
 * Helper: parse JSON safely
 **************************************************************/
function safeJsonParse(str) {
  if (!str) return null;
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
}

/***************************************************************
 * PolisBoxPlot
 * Distinguishes:
 *   - green (Agree => 1)
 *   - yellow (Unsure => 0)
 *   - white (No Response => null/undefined)
 *   - red (Disagree => -1)
 ***************************************************************/
function PolisBoxPlot({ votes }) {
  // total # participants
  const total = votes.length || 1;

  // Count categories
  const agrees = votes.filter(v => v === 1).length;
  const disagrees = votes.filter(v => v === -1).length;
  const unsures = votes.filter(v => v === 0).length;
  const noresp = votes.filter(v => v === null || v === undefined).length;

  // Dimensions for the mini bar
  const svgWidth = 200;
  const svgHeight = 30;

  // Convert counts to pixel widths
  const fractionAgree = (agrees / total) * svgWidth;
  const fractionUnsure = (unsures / total) * svgWidth;
  const fractionNoResp = (noresp / total) * svgWidth;
  const fractionDisagree = (disagrees / total) * svgWidth;

  let currentX = 0;

  return (
    <div className={styles.polisBoxPlotContainer}>
      <svg width={svgWidth} height={svgHeight} className={styles.polisBoxPlotSvg}>
        <rect
          x={0}
          y={0}
          width={svgWidth}
          height={svgHeight}
          fill="none"
          stroke="#000"
          strokeWidth={1}
        />
        <rect
          x={currentX}
          y={0}
          width={fractionAgree}
          height={svgHeight}
          fill="green"
        />
        {fractionAgree > 0 && (currentX += fractionAgree)}

        <rect
          x={currentX}
          y={0}
          width={fractionUnsure}
          height={svgHeight}
          fill="yellow"
        />
        {fractionUnsure > 0 && (currentX += fractionUnsure)}

        <rect
          x={currentX}
          y={0}
          width={fractionNoResp}
          height={svgHeight}
          fill="white"
        />
        {fractionNoResp > 0 && (currentX += fractionNoResp)}

        <rect
          x={currentX}
          y={0}
          width={fractionDisagree}
          height={svgHeight}
          fill="red"
        />
      </svg>
    </div>
  );
}

/***************************************************************
 * Build rating matrix from real or from the included demo
 *
 * We only consider question responses of type 'binary'.
 *   That means we only parse if r.response has { type: 'binary' } and answer { value: 'Agree' | 'Disagree' | 'Unsure' }
 ***************************************************************/
function buildRatingMatrixFromRealData(realQR) {
  if (!realQR) {
    return { matrix: null, responders: [], questions: [], promptsMap: {} };
  }

  const participantsSet = new Set();
  const questionList = [];
  const questionIndexMap = {};
  const promptsMap = {};

  // Only gather binary questions
  Object.entries(realQR).forEach(([qId, arr]) => {
    if (!arr || !arr.length) return;
    const firstParsed = safeJsonParse(arr[0].response);
    if (!firstParsed || firstParsed.type !== 'binary') return;

    // We have a binary question => consider it
    questionList.push(qId);
    questionIndexMap[qId] = questionList.length - 1;
    promptsMap[qId] = firstParsed.prompt || '(No prompt)';

    // gather participants
    for (let r of arr) {
      participantsSet.add(r.responder.toLowerCase());
    }
  });

  if (!questionList.length || !participantsSet.size) {
    return { matrix: null, responders: [], questions: [], promptsMap: {} };
  }

  const participantArray = Array.from(participantsSet);
  const participantIndexMap = {};
  participantArray.forEach((pAddr, idx) => {
    participantIndexMap[pAddr] = idx;
  });

  const numQ = questionList.length;
  const numP = participantArray.length;
  const mat = [];
  for (let i = 0; i < numQ; i++) {
    const row = [];
    for (let j = 0; j < numP; j++) {
      row.push(null);
    }
    mat.push(row);
  }

  Object.entries(realQR).forEach(([qId, arr]) => {
    const rowIndex = questionIndexMap[qId];
    if (rowIndex === undefined) return; // not a binary question
    for (let r of arr) {
      const parsed = safeJsonParse(r.response);
      if (!parsed) continue;
      if (parsed.type !== 'binary') continue;
      if (parsed?.answer?.encrypted) continue; // skip encrypted
      const ans = parsed?.answer?.value;
      let val = null;
      if (ans === 'Agree') val = 1;
      else if (ans === 'Disagree') val = -1;
      else if (ans === 'Unsure') val = 0;
      const pIdx = participantIndexMap[r.responder.toLowerCase()];
      if (pIdx !== undefined) {
        mat[rowIndex][pIdx] = val;
      }
    }
  });

  return {
    matrix: mat,
    responders: participantArray,
    questions: questionList,
    promptsMap
  };
}

function buildRatingMatrixFromDemo() {
  const commentsArr = demoData.comments || [];
  const participantsArr = demoData.participantsVotes || [];

  if (!commentsArr.length || !participantsArr.length) {
    return { matrix: null, responders: [], questions: [], promptsMap: {} };
  }

  const promptsMap = {};
  const questionList = [];
  commentsArr.forEach((comment) => {
    questionList.push(comment.commentId);
    promptsMap[comment.commentId] = comment.commentBody || '(No prompt)';
  });

  const participantsSet = new Set();
  participantsArr.forEach((p) => {
    participantsSet.add(p.participant);
  });

  const participantArray = Array.from(participantsSet);
  const participantIndexMap = {};
  participantArray.forEach((addr, i) => {
    participantIndexMap[addr] = i;
  });

  const numC = commentsArr.length;
  const numP = participantArray.length;
  const matrix = [];
  for (let i = 0; i < numC; i++) {
    matrix.push(new Array(numP).fill(null));
  }

  participantsArr.forEach((p) => {
    const pIdx = participantIndexMap[p.participant];
    if (pIdx === undefined) return;
    const vs = p.votes || {};
    Object.entries(vs).forEach(([k, val]) => {
      const cIndex = parseInt(k, 10);
      if (cIndex >= 0 && cIndex < numC) {
        matrix[cIndex][pIdx] = val;
      }
    });
  });

  return {
    matrix,
    responders: participantArray,
    questions: questionList,
    promptsMap
  };
}

/***************************************************************
 * Stub placeholders for blockchain network info
 * (Will be replaced with real data from SurveyResults.jsx)
 ***************************************************************/
function getBlockchainNetwork() {
  // TODO: replace with real data from SurveyResults.jsx
  return "Base Sepolia";
}

function getLastBlock() {
  // TODO: replace with real data from SurveyResults.jsx
  return "20608649";
}

function getUTCDataTimestamp() {
  // TODO: replace with real data from SurveyResults.jsx
  const now = new Date();
  return now.toISOString().replace('T', ' ').split('.')[0] + ' UTC';
}

/***************************************************************
 * The main PolisReport component
 ***************************************************************/
export default function PolisReport({
  questionResponses,
  network,
  disclaimersActive,
  sbtFilterString,
  // Pass the entire filterState for SBT listing, if available
  filterState
}) {
  const [ratingMatrix, setRatingMatrix] = useState(null);
  const [allResponders, setAllResponders] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [stats, setStats] = useState(null);

  // Single SVD-based approach (statements + participants):
  const [participantCoords, setParticipantCoords] = useState([]);
  const [statementCoords, setStatementCoords] = useState([]);

  // Additional: UMAP-based approach (participants only):
  const [umapParticipantCoords, setUmapParticipantCoords] = useState([]);

  const [questionPrompts, setQuestionPrompts] = useState({});
  const [questionLabels, setQuestionLabels] = useState([]);

  const containerRef = useRef(null);

  // Toggling between demo data or real data
  const [useDemoData, setUseDemoData] = useState(false);

  // For cluster assignments
  const [clusterCount, setClusterCount] = useState(3);
  const [clusterAssignments, setClusterAssignments] = useState([]);

  // Representative questions
  const [repQuestions, setRepQuestions] = useState({});

  // Collapsible states
  const [beeswarmOpen, setBeeswarmOpen] = useState(true);
  const [participantsGraphOpen, setParticipantsGraphOpen] = useState(true);
  const [allQuestionsOpen, setAllQuestionsOpen] = useState(true);
  const [statsOpen, setStatsOpen] = useState(true);

  // Collapsible clusters
  const [clusterCollapseState, setClusterCollapseState] = useState({});

  // Show/hide tooltips
  const [enableTooltips, setEnableTooltips] = useState(true);
  const [hoveredContent, setHoveredContent] = useState(null);
  const [tooltipPos, setTooltipPos] = useState({ x: 0, y: 0 });

  // Show/hide the top settings row
  const [showSettingsRow, setShowSettingsRow] = useState(true);

  // Toggles for participant graph
  const [showAxes, setShowAxes] = useState(true);
  const [showRadialAxes, setShowRadialAxes] = useState(true);
  const [showComments, setShowComments] = useState(false);
  const [showParticipants, setShowParticipants] = useState(true);
  const [showGroupOutline, setShowGroupOutline] = useState(true);

  // PDF capture ref
  const reportRef = useRef(null);

  // Circle hovered
  const [hoveredCircleIndex, setHoveredCircleIndex] = useState(null);

  // NEW: Embedding choice. Defaults to "UMAP"
  const [embeddingChoice, setEmbeddingChoice] = useState("UMAP");

  // Error handling
  const [errorMessage, setErrorMessage] = useState(null);

  // Local state for toggling PDF link in the heading
  const [isPdfModeActive, setIsPdfModeActive] = useState(false);

  /***************************************************************
   * handleCollapseAll / handleExpandAll
   ***************************************************************/
  function handleCollapseAll() {
    setBeeswarmOpen(false);
    setParticipantsGraphOpen(false);
    setAllQuestionsOpen(false);
    setStatsOpen(false);

    const newObj = {};
    Object.keys(repQuestions).forEach((c) => { newObj[c] = false; });
    setClusterCollapseState(newObj);
  }

  function handleExpandAll() {
    setBeeswarmOpen(true);
    setParticipantsGraphOpen(true);
    setAllQuestionsOpen(true);
    setStatsOpen(true);

    const newObj = {};
    Object.keys(repQuestions).forEach((c) => { newObj[c] = true; });
    setClusterCollapseState(newObj);
  }

  function handleCollapseAllClusters() {
    const newObj = {};
    Object.keys(repQuestions).forEach((c) => { newObj[c] = false; });
    setClusterCollapseState(newObj);
  }

  function handleExpandAllClusters() {
    const newObj = {};
    Object.keys(repQuestions).forEach((c) => { newObj[c] = true; });
    setClusterCollapseState(newObj);
  }

  /***************************************************************
   * Build rating matrix from real or demo
   ***************************************************************/
  useEffect(() => {
    setErrorMessage(null);
    let buildResult;
    try {
      if (!useDemoData) {
        buildResult = buildRatingMatrixFromRealData(questionResponses);
      } else {
        buildResult = buildRatingMatrixFromDemo();
      }
      if (!buildResult.matrix || !buildResult.matrix.length) {
        setRatingMatrix(null);
        setAllResponders([]);
        setAllQuestions([]);
        setStats(null);
        setQuestionPrompts({});
        setQuestionLabels([]);
        setParticipantCoords([]);
        setStatementCoords([]);
        setClusterAssignments([]);
        setRepQuestions({});
        setUmapParticipantCoords([]);
        return;
      }
      setRatingMatrix(buildResult.matrix);
      setAllResponders(buildResult.responders);
      setAllQuestions(buildResult.questions);
      setQuestionPrompts(buildResult.promptsMap);

      const labels = buildResult.questions.map((q, idx) => `#${idx + 1}`);
      setQuestionLabels(labels);
    } catch (e) {
      setErrorMessage(`Error building rating matrix: ${e.message}`);
    }
  }, [questionResponses, useDemoData]);

  /***************************************************************
   * Single SVD approach for participants & statements
   ***************************************************************/
  useEffect(() => {
    setErrorMessage(null);
    if (!ratingMatrix || !ratingMatrix.length) return;

    try {
      const st = computePolisStats(ratingMatrix);
      setStats(st);

      // We do a single SVD so participants and statements share the same coordinate space:
      const { part2D, stmt2D } = computeJointSVD(ratingMatrix);
      setParticipantCoords(part2D);
      setStatementCoords(stmt2D);
    } catch (e) {
      setErrorMessage(`Stats / SVD error: ${e.message}`);
    }
  }, [ratingMatrix]);

  /***************************************************************
   * Additional: UMAP approach for participants only
   ***************************************************************/
  useEffect(() => {
    setErrorMessage(null);
    if (!ratingMatrix || !ratingMatrix.length) {
      setUmapParticipantCoords([]);
      return;
    }
    try {
      const nComments = ratingMatrix.length;
      const nParticipants = ratingMatrix[0].length;
      if (nParticipants < 2) {
        // Not enough participants to do UMAP
        throw new Error("UMAP requires at least 2 participants to attempt dimension reduction.");
      }

      // We want shape [nParticipants x nComments], so let's transpose ratingMatrix
      const participantData = [];
      for (let p = 0; p < nParticipants; p++) {
        const row = [];
        for (let c = 0; c < nComments; c++) {
          row.push(ratingMatrix[c][p] ?? 0);
        }
        participantData.push(row);
      }

      // Adjust nNeighbors to remain within [2..15], but not exceed participants - 1
      const nNeighbors = Math.max(2, Math.min(15, nParticipants - 1));

      (async () => {
        const embedding = doUMAP(participantData, nNeighbors);
        const coords = embedding.map((pt, i) => ({
          x: pt[0],
          y: pt[1],
          index: i
        }));
        setUmapParticipantCoords(coords);
      })();
    } catch (e) {
      setErrorMessage(`UMAP error: ${e.message}`);
    }
  }, [ratingMatrix]);

  /***************************************************************
   * K-Means clustering on participants
   ***************************************************************/
  useEffect(() => {
    setErrorMessage(null);
    try {
      let pointsToUse = [];
      if (embeddingChoice === "UMAP") {
        pointsToUse = umapParticipantCoords;
      } else {
        pointsToUse = participantCoords;
      }

      if (!pointsToUse || !pointsToUse.length) {
        setClusterAssignments([]);
        setRepQuestions({});
        return;
      }
      const k = parseInt(clusterCount, 10) || 2;
      if (pointsToUse.length < k) {
        // if not enough points to cluster
        setClusterAssignments(new Array(pointsToUse.length).fill(0));
        setRepQuestions({});
        return;
      }
      const assigned = clusterUMAPPointsKmeans(pointsToUse, k);
      setClusterAssignments(assigned);

      if (ratingMatrix && ratingMatrix.length && assigned.length === ratingMatrix[0].length) {
        const repQ = findRepresentativeQuestions(
          ratingMatrix,
          assigned,
          questionPrompts,
          allQuestions
        );
        setRepQuestions(repQ);

        const newObj = {};
        Object.keys(repQ).forEach((c) => { newObj[c] = true; });
        setClusterCollapseState(newObj);
      } else {
        setRepQuestions({});
        setClusterCollapseState({});
      }
    } catch (e) {
      setErrorMessage(`Clustering error: ${e.message}`);
    }
  }, [
    participantCoords,
    umapParticipantCoords,
    clusterCount,
    ratingMatrix,
    questionPrompts,
    allQuestions,
    embeddingChoice
  ]);

  /***************************************************************
   * PDF Download (UPDATED to reduce whitespace, hide tooltips, and single tall page)
   ***************************************************************/
  const handleDownloadPDF = async () => {
    if (!reportRef.current) return;
    const input = reportRef.current;

    // Mark PDF mode so that any conditional rendering can switch (like disclaimers or link)
    setIsPdfModeActive(true);
    input.classList.add(styles.pdfMode);

    try {
      // Force-hide tooltips before capturing
      const tooltipElements = input.querySelectorAll(`.${styles.beeTooltip}`);
      tooltipElements.forEach(el => {
        el.style.display = 'none';
        el.style.visibility = 'hidden';
      });

      // We'll capture the container at its natural width & height
      const canvas = await html2canvas(input, {
        scale: 2,
        ignoreElements: (element) => {
          // Also ignore pdfIgnore elements
          return (
            element.classList &&
            element.classList.contains(styles.pdfIgnore)
          );
        }
      });

      const imgData = canvas.toDataURL('image/png');
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // We'll create a jsPDF doc sized exactly to the component's dimensions
      // so that it becomes a single tall page with no added margins left & right.
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'px',
        format: [canvasWidth, canvasHeight]
      });

      pdf.addImage(imgData, 'PNG', 0, 0, canvasWidth, canvasHeight);

      const timestamp = new Date().toISOString().replace(/[:.\-]/g, '_');
      const filename = `contextEngine_polis-style-report_${timestamp}.pdf`;
      pdf.save(filename);

      // Restore tooltips after done
      tooltipElements.forEach(el => {
        el.style.display = '';
        el.style.visibility = '';
      });
    } catch (e) {
      setErrorMessage(`PDF generation error: ${e.message}`);
    }

    // Remove PDF mode & revert
    input.classList.remove(styles.pdfMode);
    setIsPdfModeActive(false);
  };

  /***************************************************************
   * Mouse move for tooltips
   ***************************************************************/
  function handleContainerMouseMove(e) {
    if (containerRef.current && enableTooltips) {
      const rect = containerRef.current.getBoundingClientRect();
      setTooltipPos({
        x: e.clientX - rect.left + 10,
        y: e.clientY - rect.top + 10
      });
    }
  }

  /***************************************************************
   * Build question list with box plots
   ***************************************************************/
  function buildQuestionList() {
    if (!ratingMatrix || !ratingMatrix.length) {
      return (
        <p
          className={styles.hiddenInPdf}
          style={{ fontStyle: 'italic', marginLeft: '10px' }}
        >
          (No questions or rating matrix)
        </p>
      );
    }
    const barData = getCommentBarData(ratingMatrix);
    const lines = barData.map((bar, i) => {
      const label = questionLabels[i] || `#${i + 1}`;
      const originalId = allQuestions[i];
      const prompt = questionPrompts[originalId] || '(No prompt)';
      const votes = ratingMatrix[i];

      const agrees = votes.filter(v => v === 1).length;
      const disagrees = votes.filter(v => v === -1).length;
      const unsures = votes.filter(v => v === 0).length;
      const noresp = votes.filter(v => v === null || v === undefined).length;
      const total = votes.length;

      return (
        <div
          key={i}
          style={{
            marginBottom: '6px',
            borderBottom: '1px solid #ddd',
            paddingBottom: '6px'
          }}
        >
          <div style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
            {label}: {prompt}
          </div>
          <div
            style={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}
          >
            <span style={{ fontSize: '0.8rem', marginRight: '8px' }}>
              <strong>Agree:</strong> {agrees} /{' '}
              <strong>Disagree:</strong> {disagrees} /{' '}
              <strong>Unsure:</strong> {unsures} /{' '}
              (Total: {total})
            </span>
            <PolisBoxPlot votes={votes} />
          </div>
        </div>
      );
    });

    return (
      <div style={{ marginTop: 10 }}>
        {lines}
      </div>
    );
  }

  /***************************************************************
   * getVotesForQuestionInCluster
   ***************************************************************/
  function getVotesForQuestionInCluster(questionIndex, clusterIndex, rMatrix, assignments) {
    // Safely handle edge cases
    if (!rMatrix || !rMatrix.length) return [];
    if (questionIndex < 0 || questionIndex >= rMatrix.length) return [];
    if (!rMatrix[questionIndex]) return [];
    if (!assignments) return [];

    const nParticipants = rMatrix[questionIndex].length;
    const votes = [];
    for (let p = 0; p < nParticipants; p++) {
      if (assignments[p] === clusterIndex) {
        const val = (rMatrix[questionIndex][p] !== undefined && rMatrix[questionIndex][p] !== null)
          ? rMatrix[questionIndex][p]
          : null;
        votes.push(val);
      }
    }
    return votes;
  }

  /***************************************************************
   * renderClusterRepresentativesFor
   ***************************************************************/
  function renderClusterRepresentativesFor(clusterIndex) {
    if (!repQuestions[clusterIndex] || !repQuestions[clusterIndex].length) {
      return (
        <p style={{ marginLeft: '20px', marginTop: '6px' }}>
          No representative questions found for cluster {clusterIndex}
        </p>
      );
    }
    const arr = repQuestions[clusterIndex].slice(0, 3);
    const uniqueClusters = Array.from(new Set(clusterAssignments)).sort((a,b)=>a-b);
    const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

    return (
      <div style={{ marginTop: '6px' }}>
        {arr.map((rq, idx) => {
          const qIndex = rq.questionIndex;
          const questionPrompt = rq.prompt;
          const clusterVotes = getVotesForQuestionInCluster(
            qIndex,
            clusterIndex,
            ratingMatrix,
            clusterAssignments
          );

          return (
            <div
              key={idx}
              style={{
                marginLeft: '20px',
                marginBottom: '8px',
                borderLeft: '2px solid #ccc',
                paddingLeft: '8px'
              }}
            >
              <strong>{rq.label}</strong>: {questionPrompt} <br />
              <small style={{ color: '#666' }}>
                (cluster’s average agreement is {rq.difference.toFixed(2)} away from global)
              </small>

              {/* This cluster's response */}
              <div style={{ marginTop: '4px' }}>
                <p style={{ marginBottom: '2px', fontSize: '0.85rem' }}>
                  This cluster's response:
                </p>
                <PolisBoxPlot votes={clusterVotes} />
              </div>

              {/* Compare all other clusters */}
              <div style={{ marginTop: '6px', marginBottom: '6px' }}>
                <p style={{ fontSize: '0.85rem', marginBottom: '2px' }}>
                  Compare all other clusters on this question:
                </p>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    gap: '8px'
                  }}
                >
                  {uniqueClusters.map((cl) => {
                    if (cl === clusterIndex) return null;
                    const otherClusterVotes = getVotesForQuestionInCluster(
                      qIndex,
                      cl,
                      ratingMatrix,
                      clusterAssignments
                    );
                    const clusterColor = colorScale(cl);
                    return (
                      <div
                        key={cl}
                        style={{
                          border: `1px solid ${clusterColor}`,
                          padding: '4px'
                        }}
                      >
                        <div
                          style={{
                            color: clusterColor,
                            fontWeight: 'bold',
                            marginBottom: '4px'
                          }}
                        >
                          Cluster {cl}
                        </div>
                        <PolisBoxPlot votes={otherClusterVotes} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  /***************************************************************
   * buildClusterHulls
   ***************************************************************/
  function buildClusterHulls(points, assignments) {
    const grouped = {};
    points.forEach((pt) => {
      const cl = assignments[pt.index];
      if (!grouped[cl]) grouped[cl] = [];
      grouped[cl].push([pt.x, pt.y]);
    });

    const hulls = [];
    Object.keys(grouped).forEach((clstr) => {
      const arr = grouped[clstr];
      if (arr.length < 3) {
        hulls.push({ cluster: +clstr, path: null });
        return;
      }
      const hull = d3.polygonHull(arr);
      hulls.push({ cluster: +clstr, path: hull });
    });
    return hulls;
  }

  /***************************************************************
   * renderClusterLegend
   ***************************************************************/
  function renderClusterLegend() {
    if (!clusterAssignments || !clusterAssignments.length) return null;
    const uniqueClusters = Array.from(new Set(clusterAssignments)).sort((a,b)=>a-b);
    const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

    return (
      <div style={{ marginTop: '12px', marginBottom: '20px' }}>
        <strong style={{ marginRight: '6px' }}>
          Opinion Groups{' '}
          {enableTooltips && (
            <FontAwesomeIcon
              icon={faQuestionCircle}
              title="Groups are made of participants who voted similarly on statements."
              style={{ cursor: 'help', marginLeft: '4px' }}
              onMouseEnter={() => setHoveredContent('Groups are made of participants who voted similarly on statements.')}
              onMouseLeave={() => setHoveredContent(null)}
            />
          )}
          :
        </strong>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '6px'
          }}
        >
          {uniqueClusters.map((c) => {
            const cColor = colorScale(c);
            const clusterIsOpen = clusterCollapseState[c];
            const handleToggleCluster = () => {
              setClusterCollapseState(prev => ({
                ...prev,
                [c]: !prev[c]
              }));
            };
            return (
              <div
                key={c}
                style={{
                  marginBottom: '8px',
                  border: '1px dashed #ccc',
                  padding: '6px'
                }}
              >
                <div
                  onClick={handleToggleCluster}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <svg width={16} height={16} className={styles.clusterSwatchSvg}>
                      <circle cx={8} cy={8} r={6} fill={cColor} />
                    </svg>
                    <span style={{ marginLeft: '4px', fontWeight: 'bold' }}>
                      Cluster {c}
                    </span>
                  </div>
                  <FontAwesomeIcon
                    icon={clusterIsOpen ? faMinusSquare : faPlusSquare}
                    style={{ marginRight: '8px' }}
                  />
                </div>
                {clusterIsOpen ? renderClusterRepresentativesFor(c) : (
                  <div style={{ marginLeft: '20px', marginTop: '6px' }}>
                    <em className={styles.showWhenPdf}>
                      Omitted
                    </em>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  /***************************************************************
   * renderParticipantGraph
   ***************************************************************/
  function renderParticipantGraph() {
    try {
      let pointsToUse = [];
      if (embeddingChoice === "UMAP") {
        pointsToUse = umapParticipantCoords;
      } else {
        pointsToUse = participantCoords;
      }

      if (!pointsToUse || !pointsToUse.length) {
        return (
          <p
            className={styles.hiddenInPdf}
            style={{ fontStyle: 'italic', marginLeft: '10px', color: 'red' }}
          >
            (No participant data or not enough participants to plot.)
          </p>
        );
      }

      const stCoords = (embeddingChoice === "SVD") ? statementCoords : [];
      const allXs = (embeddingChoice === "SVD")
        ? pointsToUse.map(d => d.x).concat(stCoords.map(d => d.x))
        : pointsToUse.map(d => d.x);
      const allYs = (embeddingChoice === "SVD")
        ? pointsToUse.map(d => d.y).concat(stCoords.map(d => d.y))
        : pointsToUse.map(d => d.y);

      const minx = d3.min(allXs) ?? 0;
      const maxx = d3.max(allXs) ?? 1;
      const miny = d3.min(allYs) ?? 0;
      const maxy = d3.max(allYs) ?? 1;

      const w = 500;
      const h = 400;
      const pad = 40;
      const xScale = d3.scaleLinear().domain([minx, maxx]).range([-(w / 2 - pad), (w / 2 - pad)]);
      const yScale = d3.scaleLinear().domain([miny, maxy]).range([(h / 2 - pad), -(h / 2 - pad)]);

      const hulls = buildClusterHulls(pointsToUse, clusterAssignments);
      const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

      const centerX = w / 2;
      const centerY = h / 2;

      function buildStatementClustersTooltip(stmtIndex) {
        const uniqueClusters = Array.from(new Set(clusterAssignments)).sort((a,b)=>a-b);
        return (
          <div style={{ minWidth: '250px' }}>
            <p style={{ fontWeight: 'bold', marginBottom: '8px' }}>
              Cluster BoxPlots (SVD mode)
            </p>
            {uniqueClusters.map((cl) => {
              const clusterVotes = getVotesForQuestionInCluster(
                stmtIndex,
                cl,
                ratingMatrix,
                clusterAssignments
              );
              const cColor = colorScale(cl);
              return (
                <div
                  key={cl}
                  style={{
                    border: `1px solid ${cColor}`,
                    marginBottom: '6px',
                    padding: '4px'
                  }}
                >
                  <div
                    style={{
                      color: cColor,
                      fontWeight: 'bold',
                      marginBottom: '4px'
                    }}
                  >
                    Cluster {cl}
                  </div>
                  <PolisBoxPlot votes={clusterVotes} />
                </div>
              );
            })}
          </div>
        );
      }

      return (
        <div className={styles.graphItem}>
          <svg
            width={w}
            height={h}
            className={styles.participantSvg}
          >
            <g transform={`translate(${centerX}, ${centerY})`}>
              {showRadialAxes && (
                <g>
                  <circle
                    strokeWidth={1}
                    stroke="rgb(230,230,230)"
                    fill="rgb(248,248,248)"
                    r={Math.min(w, h) / 2.3}
                  />
                  <circle
                    strokeWidth={1}
                    stroke="rgb(230,230,230)"
                    fill="rgb(245,245,245)"
                    r={Math.min(w, h) / 4}
                  />
                  <circle
                    strokeWidth={1}
                    stroke="rgb(230,230,230)"
                    fill="rgb(248,248,248)"
                    r={Math.min(w, h) / 8}
                  />
                </g>
              )}

              {showAxes && (
                <g>
                  <line
                    x1={-(w / 2) + pad}
                    y1={0}
                    x2={(w / 2) - pad}
                    y2={0}
                    stroke="black"
                    strokeWidth={1}
                  />
                  <line
                    x1={0}
                    y1={-(h / 2) + pad}
                    x2={0}
                    y2={(h / 2) - pad}
                    stroke="black"
                    strokeWidth={1}
                  />
                </g>
              )}

              {showGroupOutline && hulls.map((hullObj, i) => {
                if (!hullObj.path) return null;
                const cColor = colorScale(hullObj.cluster);
                const lineGenerator = d3.line();
                const pathStr = lineGenerator(
                  hullObj.path.map(pt => [xScale(pt[0]), yScale(pt[1])])
                ) + "Z";
                return (
                  <path
                    key={i}
                    d={pathStr}
                    fill={cColor}
                    fillOpacity={0.1}
                    stroke={cColor}
                    strokeOpacity={0.7}
                    strokeWidth={1}
                  />
                );
              })}

              {showParticipants && pointsToUse.map((d, i) => {
                const c = clusterAssignments[d.index] ?? 0;
                const col = colorScale(c);
                const px = xScale(d.x);
                const py = yScale(d.y);
                return (
                  <circle
                    key={i}
                    cx={px}
                    cy={py}
                    r={4}
                    fill={col}
                    onMouseEnter={() => {
                      if (enableTooltips) {
                        setHoveredContent(`Participant #${d.index}, Cluster ${c}, [${d.x.toFixed(2)}, ${d.y.toFixed(2)}]`);
                      }
                    }}
                    onMouseLeave={() => {
                      if (enableTooltips) {
                        setHoveredContent(null);
                      }
                    }}
                  />
                );
              })}

              {showComments && embeddingChoice === "SVD" && stCoords.map((d, i) => {
                const label = questionLabels[d.index] || `#${d.index + 1}`;
                const promptKey = allQuestions[d.index];
                const prompt = questionPrompts[promptKey] || '(No prompt)';
                const px = xScale(d.x);
                const py = yScale(d.y);
                const votesForThis = ratingMatrix[d.index] || [];
                const agrees = votesForThis.filter(v => v === 1).length;
                const disagrees = votesForThis.filter(v => v === -1).length;
                const unsures = votesForThis.filter(v => v === 0).length;
                const noresps = votesForThis.filter(v => v === null || v === undefined).length;

                const rowVotes = ratingMatrix[d.index];
                const statementHoverContent = (
                  <div>
                    <div style={{ marginBottom: '6px' }}>
                      <strong>{label}:</strong> {prompt}
                    </div>
                    <div style={{ fontSize: '0.85rem', marginBottom: '6px' }}>
                      <strong>Agree:</strong> {agrees},{' '}
                      <strong>Disagree:</strong> {disagrees},{' '}
                      <strong>Unsure:</strong> {unsures},{' '}
                      <strong>No Resp:</strong> {noresps}
                    </div>
                    <PolisBoxPlot votes={rowVotes} />
                    <div style={{ marginTop: '8px' }}>
                      {buildStatementClustersTooltip(d.index)}
                    </div>
                  </div>
                );

                return (
                  <circle
                    key={i}
                    cx={px}
                    cy={py}
                    r={3}
                    fill="#000"
                    onMouseEnter={() => {
                      if (enableTooltips) {
                        setHoveredContent(statementHoverContent);
                      }
                    }}
                    onMouseLeave={() => {
                      if (enableTooltips) {
                        setHoveredContent(null);
                      }
                    }}
                  />
                );
              })}
            </g>
            <text
              x={10}
              y={20}
              fontSize="12"
              fontWeight="600"
            >
              Participants + {embeddingChoice === "SVD" ? 'Statements ' : ''}Graph ({embeddingChoice})
            </text>
          </svg>
        </div>
      );
    } catch (err) {
      return (
        <p style={{ color: 'red', marginLeft: '10px' }}>
          Error rendering participant graph: {err.message}
        </p>
      );
    }
  }

  /***************************************************************
   * renderCommentSwarm
   ***************************************************************/
  function renderCommentSwarm() {
    try {
      if (!ratingMatrix || !ratingMatrix.length) {
        return (
          <p
            className={styles.hiddenInPdf}
            style={{ fontStyle: 'italic', marginLeft: '10px' }}
          >
            (No question data for beeswarm)
          </p>
        );
      }

      const divResults = computeQuestionDivisiveness(ratingMatrix);
      const points = divResults.map(item => {
        return {
          index: item.commentIndex,
          extremity: item.divisiveness,
          agrees: item.agrees,
          disagrees: item.disagrees,
          total: item.total
        };
      });

      if (!points.length) {
        return (
          <p
            className={styles.hiddenInPdf}
            style={{ fontStyle: 'italic', marginLeft: '10px' }}
          >
            (No valid divisiveness data)
          </p>
        );
      }

      const w = 600;
      const h = 200;
      const swarmed = beeswarmByExtremity(points, w, h);

      return (
        <div className={styles.swarmContainer}>
          <svg
            width={w}
            height={h}
            className={styles.beeswarmSvg}
          >
            <text x={10} y={20} fontSize="12" fontWeight="600">
              Consensus vs Divisiveness (Bee Swarm)
            </text>
            <line x1={0} y1={h - 10} x2={w} y2={h - 10} stroke="black" />
            <text x={0} y={h - 15} fontSize="10" fill="black">
              More Consensus (0)
            </text>
            <text x={w - 80} y={h - 15} fontSize="10" fill="black">
              More Divisive (1)
            </text>

            {swarmed.map((d, i) => {
              const cIndex = d.index;
              const promptKey = allQuestions[cIndex];
              const label = questionLabels[cIndex] || `#${cIndex + 1}`;
              const rowVotes = ratingMatrix[cIndex] || [];
              const agrees = rowVotes.filter(v => v === 1).length;
              const disagrees = rowVotes.filter(v => v === -1).length;
              const unsures = rowVotes.filter(v => v === 0).length;
              const prompt = questionPrompts[promptKey] || '(No prompt)';

              const tooltipContent = (
                <div>
                  <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                    {label}: {prompt}
                  </div>
                  <div style={{ fontSize: '0.85rem', marginBottom: '6px' }}>
                    <strong>Agree:</strong> {agrees},{' '}
                    <strong>Disagree:</strong> {disagrees},{' '}
                    <strong>Unsure:</strong> {unsures}
                  </div>
                  <PolisBoxPlot votes={rowVotes} />
                </div>
              );

              const circleClass =
                hoveredCircleIndex === i
                  ? styles.beeswarmCircleHover
                  : styles.beeswarmCircle;

              return (
                <circle
                  key={i}
                  cx={d.x}
                  cy={d.y}
                  r={4}
                  className={circleClass}
                  onMouseEnter={() => {
                    if (enableTooltips) {
                      setHoveredContent(tooltipContent);
                      setHoveredCircleIndex(i);
                    }
                  }}
                  onMouseLeave={() => {
                    if (enableTooltips) {
                      setHoveredContent(null);
                      setHoveredCircleIndex(null);
                    }
                  }}
                />
              );
            })}
          </svg>

          <div className={styles.beeswarmDescription}>
            <p className={styles.beeswarmDescText}>
              <strong>Bee Swarm Plot:</strong> Each statement is placed on a horizontal axis
              according to how “divisive” it is. Questions that are nearly half agree and half disagree
              appear on the right side (near 1), while those with near-unanimous agreement or disagreement
              appear on the left (near 0). “No response” and “unsure” votes are excluded from the split
              calculation.
            </p>
          </div>
        </div>
      );
    } catch (err) {
      return (
        <p style={{ color: 'red', marginLeft: '10px' }}>
          Error rendering Bee Swarm: {err.message}
        </p>
      );
    }
  }

  /***************************************************************
   * Render SBT Filter Info (from filterState)
   ***************************************************************/
  function renderSBTFilterState() {
    if (!filterState) {
      // If no filterState, fallback to existing sbtFilterString logic
      return (
        <span className={styles.statValue}>
          {(!sbtFilterString || !sbtFilterString.length) ? 'None' : sbtFilterString}
        </span>
      );
    }

    // The filterState might contain: { includedSBTs: [], excludedSBTs: [], onlyVerifiedHumans: bool }
    const { includedSBTs, excludedSBTs, onlyVerifiedHumans } = filterState;

    const renderSBTList = (sbtArr, label) => {
      if (!sbtArr || !sbtArr.length) return null;
      return (
        <div style={{ marginLeft: '8px', marginBottom: '6px' }}>
          <strong>{label}:</strong>{' '}
          {sbtArr.map((sbtItem, idx) => {
            const shortAddr = sbtItem.address.slice(0, 6) + '...' + sbtItem.address.slice(-4);
            return (
              <span
                key={idx}
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  background: '#ddd',
                  padding: '3px 6px',
                  borderRadius: '4px',
                  marginRight: '6px'
                }}
              >
                {sbtItem.name || shortAddr}
                {/* External link to block explorer or your SBT page */}
                <a
                  href={`https://etherscan.io/token/${sbtItem.address}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginLeft: '5px', color: '#333' }}
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </a>
              </span>
            );
          })}
        </div>
      );
    };

    return (
      <div>
        {onlyVerifiedHumans && (
          <div style={{ marginBottom: '6px' }}>
            <strong>Only Verified Humans</strong>
          </div>
        )}
        {renderSBTList(includedSBTs, 'Included SBTs')}
        {renderSBTList(excludedSBTs, 'Excluded SBTs')}
      </div>
    );
  }

  /***************************************************************
   * Render
   ***************************************************************/
  return (
    <div
      className={styles.polisReportContainer}
      ref={containerRef}
      onMouseMove={handleContainerMouseMove}
    >
      {/* The gear icon to toggle top settings row */}
      <div style={{ textAlign: 'right' }}>
        <FontAwesomeIcon
          icon={faCog}
          style={{ fontSize: '1.3rem', cursor: 'pointer', marginRight: '10px' }}
          onClick={() => setShowSettingsRow(!showSettingsRow)}
          title="Toggle settings row"
        />
      </div>

      {showSettingsRow && (
        <div className={styles.pdfIgnore} id={styles.settingsRow}>
          <div style={{ marginRight: '12px', position: 'relative' }}>
            <button
              onClick={handleDownloadPDF}
              style={{
                padding: '6px 12px',
                cursor: 'pointer',
                marginRight: '4px'
              }}
              onMouseEnter={() => {
                if (enableTooltips) {
                  setHoveredContent('Downloads only the currently open sections of this report as a PDF');
                }
              }}
              onMouseLeave={() => {
                if (enableTooltips) {
                  setHoveredContent(null);
                }
              }}
              title="Download the currently open sections of the report"
            >
              Download as PDF {enableTooltips && <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: '4px' }} />}
            </button>
          </div>

          <div style={{ marginRight: '12px' }}>
            <label className={styles.demoToggleLabel} style={{ marginRight: '10px' }}>
              <input
                type="checkbox"
                checked={useDemoData}
                onChange={e => setUseDemoData(e.target.checked)}
                className={styles.demoToggleCheckbox}
              />
              Use Demo Data
            </label>
          </div>

          <div style={{ marginRight: '12px' }}>
            <label className={styles.demoToggleLabel} style={{ marginRight: '5px' }}>
              <input
                type="checkbox"
                checked={enableTooltips}
                onChange={e => setEnableTooltips(e.target.checked)}
                style={{ marginRight: '4px', cursor: 'pointer' }}
              />
              Enable Explainers
            </label>
          </div>

          <div style={{ marginRight: '12px' }}>
            <button onClick={handleCollapseAll} style={{ marginRight: '4px' }}>
              Collapse All
            </button>
            <button onClick={handleExpandAll}>
              Expand All
            </button>
          </div>
        </div>
      )}

      <div ref={reportRef} style={{ background: '#fff', padding: '20px' }}>
        {/* Heading with link only in PDF mode */}
        {isPdfModeActive ? (
          <h4 className={styles.heading}>
            <a href="https://contextengine.xyz" target="_blank" rel="noopener noreferrer">
              Context Engine
            </a> Report (Pol.is Style)
          </h4>
        ) : (
          <h4 className={styles.heading}>
            Context Engine Report (Pol.is Style)
          </h4>
        )}

        {disclaimersActive && (
          <div className={styles.disclaimerBox}>
            <strong>Disclaimer:</strong> Only non-encrypted, binary
            (Agree/Disagree/Unsure) responses have been considered in
            this Polis-style report.
          </div>
        )}

        {errorMessage && (
          <div style={{ color: 'red', marginBottom: '10px', fontWeight: 'bold' }}>
            Error: {errorMessage}
          </div>
        )}

        {!stats ? (
          <p className={styles.noData}>
            No non-encrypted binary responses found, or no Demo data loaded.
          </p>
        ) : (
          <>
            <div className={styles.sectionCollapse}>
              <div
                className={styles.sectionHeaderRow}
                style={{ width: '100%', cursor: 'pointer' }}
                onClick={() => setStatsOpen(!statsOpen)}
              >
                <h5 id={styles.sectionHeader} className={styles.sectionTitle}>
                  <FontAwesomeIcon icon={statsOpen ? faCaretUp : faCaretDown} style={{ marginRight: '6px' }} />
                  Summary and Statistics
                </h5>
                <div className={styles.pdfIgnore}  style={{ textAlign: 'right', flex: '1' }}>
                  {statsOpen ? 'Hide' : 'Show'}
                  {!statsOpen && (
                    <span className={styles.showWhenPdf} style={{ marginLeft: '10px', color: '#555' }}>
                      (Omitted)
                    </span>
                  )}
                </div>
              </div>
              {statsOpen ? (
                <div className={styles.statsSectionCollapsible}>
                  <div className={styles.statsSection}>
                    <div className={styles.statsRow}>
                      <div className={styles.statsItem}>
                        <span className={styles.statLabel}>
                          Participants{' '}
                          {enableTooltips && (
                            <FontAwesomeIcon
                              icon={faQuestionCircle}
                              className={styles.tooltipIcon}
                              onMouseEnter={() => setHoveredContent('Participants who voted or wrote statements in the conversation.')}
                              onMouseLeave={() => setHoveredContent(null)}
                            />
                          )}
                          :
                        </span>
                        <span className={styles.statValue}>{stats.nParticipants}</span>
                      </div>
                      <div className={styles.statsItem}>
                        <span className={styles.statLabel}>
                          Statements{' '}
                          {enableTooltips && (
                            <FontAwesomeIcon
                              icon={faQuestionCircle}
                              className={styles.tooltipIcon}
                              onMouseEnter={() => setHoveredContent('Number of statements (questions) with a binary vote option available.')}
                              onMouseLeave={() => setHoveredContent(null)}
                            />
                          )}
                          :
                        </span>
                        <span className={styles.statValue}>{stats.nComments}</span>
                      </div>
                      <div className={styles.statsItem}>
                        <span className={styles.statLabel}>
                          Votes{' '}
                          {enableTooltips && (
                            <FontAwesomeIcon
                              icon={faQuestionCircle}
                              className={styles.tooltipIcon}
                              onMouseEnter={() => setHoveredContent('Total agree or disagree clicks recorded across all statements by participants.')}
                              onMouseLeave={() => setHoveredContent(null)}
                            />
                          )}
                          :
                        </span>
                        <span className={styles.statValue}>{stats.totalVotes}</span>
                      </div>
                      <div className={styles.statsItem}>
                        <span className={styles.statLabel}>
                          Votes/Voter Avg{' '}
                          {enableTooltips && (
                            <FontAwesomeIcon
                              icon={faQuestionCircle}
                              className={styles.tooltipIcon}
                              onMouseEnter={() => setHoveredContent('The average number of vote actions each participant made.')}
                              onMouseLeave={() => setHoveredContent(null)}
                            />
                          )}
                          :
                        </span>
                        <span className={styles.statValue}>
                          {stats.votesPerVoterAvg.toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <div className={styles.statsRow}>
                      <div className={styles.statsItem}>
                        <span className={styles.statLabel}>
                          Group Filter{' '}
                          {enableTooltips && (
                            <FontAwesomeIcon
                              icon={faQuestionCircle}
                              className={styles.tooltipIcon}
                              onMouseEnter={() => setHoveredContent('SBT-based group membership. SBTs can represent event attendance or membership, as used in the context engine.')}
                              onMouseLeave={() => setHoveredContent(null)}
                            />
                          )}
                          :
                        </span>
                        {/* Render the filterState in a stylized way */}
                        {renderSBTFilterState()}
                      </div>
                    </div>

                    {/* Added row for network and block info */}
                    <div className={styles.statsRow}>
                      <div className={styles.statsItem}>
                        <span className={styles.statLabel}>Blockchain:</span>
                        <span className={styles.statValue}>{getBlockchainNetwork()}</span>
                      </div>
                      <div className={styles.statsItem}>
                        <span className={styles.statLabel}>Last Block:</span>
                        <span className={styles.statValue}>{getLastBlock()}</span>
                      </div>
                      <div className={styles.statsItem}>
                        <span className={styles.statLabel}>Timestamp:</span>
                        <span className={styles.statValue}>{getUTCDataTimestamp()}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            {/* CONSENSUS SECTION */}
            <div className={styles.sectionCollapse}>
              <div
                className={styles.sectionHeaderRow}
                style={{ width: '100%', cursor: 'pointer' }}
                onClick={() => setBeeswarmOpen(!beeswarmOpen)}
              >
                <h5 id={styles.sectionHeader} className={styles.sectionTitle}>
                  <FontAwesomeIcon icon={beeswarmOpen ? faCaretUp : faCaretDown} style={{ marginRight: '6px' }} />
                  Consensus and Debated Questions
                </h5>
                <div className={styles.pdfIgnore}  style={{ textAlign: 'right', flex: '1' }}>
                  {beeswarmOpen ? 'Hide' : 'Show'}
                  {!beeswarmOpen && (
                    <span className={styles.showWhenPdf} style={{ marginLeft: '10px', color: '#555' }}>
                      (Omitted)
                    </span>
                  )}
                </div>
              </div>
              {beeswarmOpen ? (
                <div className={styles.graphSection}>
                  {renderCommentSwarm()}
                </div>
              ) : null}
            </div>

            {/* PARTICIPANTS + STATEMENTS GRAPH */}
            <div className={styles.sectionCollapse}>
              <div
                className={styles.sectionHeaderRow}
                style={{ width: '100%', cursor: 'pointer' }}
                onClick={() => setParticipantsGraphOpen(!participantsGraphOpen)}
              >
                <h5 id={styles.sectionHeader} className={styles.sectionTitle}>
                  <FontAwesomeIcon icon={participantsGraphOpen ? faCaretUp : faCaretDown} style={{ marginRight: '6px' }} />
                  Participants Graph
                </h5>
                <div className={styles.pdfIgnore}  style={{ textAlign: 'right', flex: '1' }}>
                  {participantsGraphOpen ? 'Hide' : 'Show'}
                  {!participantsGraphOpen && (
                    <span className={styles.showWhenPdf} style={{ marginLeft: '10px', color: '#555' }}>
                      (Omitted)
                    </span>
                  )}
                </div>
              </div>
              {participantsGraphOpen ? (
                <>
                  <p style={{ fontSize: '0.9rem', marginBottom: '10px' }}>
                    This diagram uses either <strong>UMAP</strong> or <strong>SVD/PCA</strong> to arrange participants. In <strong>SVD</strong> mode, statements are also displayed in the same 2D space.
                  </p>

                  <div style={{ marginBottom: '8px' }}>
                    <label style={{ marginRight: '10px' }}>
                      # of clusters (K-Means):
                    </label>
                    <input
                      type="number"
                      value={clusterCount}
                      onChange={e => setClusterCount(e.target.value)}
                      style={{ width: '60px', marginRight: '15px' }}
                    />

                    <label style={{ marginRight: '6px' }}>
                      Embedding:
                      {enableTooltips && (
                        <FontAwesomeIcon
                          icon={faQuestionCircle}
                          style={{ marginLeft: '4px', cursor: 'help' }}
                          onMouseEnter={() => setHoveredContent('UMAP is a non-linear approach focusing on participant clustering; SVD places both participants and statements in the same plane.')}
                          onMouseLeave={() => setHoveredContent(null)}
                        />
                      )}
                    </label>
                    <select
                      value={embeddingChoice}
                      onChange={(e) => {
                        setEmbeddingChoice(e.target.value);
                        if (e.target.value === "UMAP") {
                          // Force showComments to false
                          setShowComments(false);
                        }
                      }}
                      style={{ padding: '4px', marginRight: '10px' }}
                    >
                      <option value="UMAP">UMAP</option>
                      <option value="SVD">SVD/PCA</option>
                    </select>

                    <label style={{ marginRight: '10px', cursor: 'pointer' }}>
                      <input
                        type="checkbox"
                        checked={showComments && embeddingChoice === "SVD"}
                        onChange={() => {
                          // If turning on, force SVD
                          if (!showComments) {
                            setEmbeddingChoice('SVD');
                            setShowComments(true);
                          } else {
                            // turning off
                            setShowComments(false);
                          }
                        }}
                        style={{ marginRight: '4px' }}
                      />
                      Statements
                    </label>

                    <label style={{ marginRight: '10px', cursor: 'pointer' }}>
                      <input
                        type="checkbox"
                        checked={showParticipants}
                        onChange={() => setShowParticipants(!showParticipants)}
                        style={{ marginRight: '4px' }}
                      />
                      Participants
                    </label>

                    <label style={{ marginRight: '10px', cursor: 'pointer' }}>
                      <input
                        type="checkbox"
                        checked={showGroupOutline}
                        onChange={() => setShowGroupOutline(!showGroupOutline)}
                        style={{ marginRight: '4px' }}
                      />
                      Outline
                    </label>

                    <label style={{ marginRight: '10px', cursor: 'pointer' }}>
                      <input
                        type="checkbox"
                        checked={showAxes}
                        onChange={() => setShowAxes(!showAxes)}
                        style={{ marginRight: '4px' }}
                      />
                      Axes
                    </label>

                    <label style={{ marginRight: '10px', cursor: 'pointer' }}>
                      <input
                        type="checkbox"
                        checked={showRadialAxes}
                        onChange={() => setShowRadialAxes(!showRadialAxes)}
                        style={{ marginRight: '4px' }}
                      />
                      Radial Axes
                    </label>
                  </div>

                  <div className={styles.graphSection}>
                    {renderParticipantGraph()}
                  </div>

                  <div className={styles.pdfIgnore}>
                    <button onClick={handleCollapseAllClusters} style={{ marginRight: '10px' }}>
                      Collapse Clusters
                    </button>
                    <button onClick={handleExpandAllClusters}>
                      Expand Clusters
                    </button>
                  </div>

                  {renderClusterLegend()}

                </>
              ) : (
                <div style={{ width: '900px', height: '1px', opacity: 0, pointerEvents: 'none' }} />
              )}
            </div>

            {/* ALL QUESTIONS */}
            <div className={styles.sectionCollapse}>
              <div
                className={styles.sectionHeaderRow}
                style={{ width: '100%', cursor: 'pointer' }}
                onClick={() => setAllQuestionsOpen(!allQuestionsOpen)}
              >
                <h5 id={styles.sectionHeader} className={styles.sectionTitle}>
                  <FontAwesomeIcon icon={allQuestionsOpen ? faCaretUp : faCaretDown} style={{ marginRight: '6px' }} />
                  All Questions
                </h5>
                <div className={styles.pdfIgnore}  style={{ textAlign: 'right', flex: '1' }}>
                  {allQuestionsOpen ? 'Hide' : 'Show'}
                  {!allQuestionsOpen && (
                    <span className={styles.showWhenPdf} style={{ marginLeft: '10px', color: '#555' }}>
                      (Omitted)
                    </span>
                  )}
                </div>
              </div>
              {allQuestionsOpen ? (
                <>
                  {buildQuestionList()}
                </>
              ) : null}
            </div>
          </>
        )}
      </div>

      {/* Tooltips should not appear in PDF; forcibly hidden above but also .pdfIgnore */}
      {enableTooltips && hoveredContent && (
        <div
          className={`${styles.beeTooltip} ${styles.pdfIgnore}`}
          style={{
            left: tooltipPos.x,
            top: tooltipPos.y
          }}
        >
          {hoveredContent}
        </div>
      )}
    </div>
  );
}

// UserPage.jsx

import React, { Component } from 'react';
import styles from './UserPage.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faCheck,
  faExpand,
  faSpinner,
  faExternalLinkAlt,
  faBookmark,
  faChevronDown,
  faChevronUp,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import contractScripts from 'components/Buttons/contractScripts';
import proposalScripts from 'components/UpcomingMatches/proposalScripts';
import StatsSection from './UserStats';
import CompareAddressSection from './CompareAddresses';
import SBTPage from '../SBTs/SBTPage';
import { Collapse, UncontrolledTooltip, Modal, ModalHeader, ModalBody } from 'reactstrap';

// NEW IMPORT: for mini question display
import SingleQuestionResponse from '../SurveyTool/SingleQuestionResponse.jsx';

class UserPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      viewAddress: '',
      surveyResponseInfo: [],        // Basic info about responded-to surveys
      surveyCreationInfo: [],        // Basic info about created surveys
      questionCreationInfo: [],      // Basic info about created questions
      questionResponseInfo: [],      // Basic info about responded-to questions

      // Newly added to store actual question-by-question responses (for surveys):
      detailedSurveyResponses: {},   // { [surveyId]: [ array of { questionData, responseData } ] }

      // Newly added to store actual single-question responses:
      detailedQuestionResponses: {}, // { [questionId]: responseObject }

      userStats: {
        surveysResponded: 0,
        surveysCreated: 0,
        questionsResponded: 0,
        questionsCreated: 0,
        mostUniqueIdea: ' ... ',
        badgesReceived: 0,
        worryScore: 'x%',
        enthusiasmScore: 'y%',
        topTags: ['#cybersecurity', '#ubi', '#mechinterp'],
      },
      copied: false,
      collapseOpen: false,
      username: '',
      usernameError: '',
      bookmarked: false,
      sbtList: [],
      loadingSBTs: true,
      loadingSurveys: true,
      loadingQuestions: true,
      showAnalysisModal: false,
      aiAnalysis: '',
      showFullProfileModal: false,
      isSimulated: false,
      selectedTab: 'surveys', // For the toggle between surveys and questions
      isCacheUpToDate: false, // Tracks if local cache is up-to-date with the chain

      // For expanding/collapsing each responded survey's question answers:
      expandedSurveyResponses: {},   // { [surveyId]: boolean }
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ viewAddress: this.props.viewAddress });
    this.getSurveyHashesByAddress();
    this.getQuestionHashesByAddress();
    this.getUsernameByAddress();
    this.getSBTsByUserAddress();
    this.checkIfBookmarked();
    this.checkIfSimulated();
    this.checkCacheLatestBlock();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateCache = (cacheData) => {
    // This is a stub function that will eventually modify the cache in SurveyTool.jsx
    // For now, we can console.log what the update would be
    console.log('Updating cache with data:', cacheData);
    localStorage.setItem('surveyCache', JSON.stringify(cacheData));
  }

  checkIfSimulated = async () => {
    try {
      const isSimulated = await contractScripts.userIsSimulated(this.props.viewAddress);
      if (this._isMounted) {
        this.setState({ isSimulated });
      }
    } catch (error) {
      console.error("Error checking if user is simulated:", error);
    }
  }

  // -----------------------------------------------------------
  //                    SURVEYS SECTION
  // -----------------------------------------------------------

  getSurveyHashesByAddress = async () => {
    const { viewAddress } = this.props;
    if (!viewAddress) return;
    this.setState({ loadingSurveys: true });

    try {
      let surveyCache = JSON.parse(localStorage.getItem('surveyCache')) || {
        surveyIDs: [],
        questionIDs: [],
        questionResponses: {},
        arweaveContent: {},
        latestBlock: 0,
        userSurveyResponses: {},
        userSurveysCreated: {},
        userQuestionResponses: {},
        userQuestionsCreated: {},
      };

      const latestBlock = await contractScripts.getLatestBlockNumber(this.props.provider);

      if (surveyCache.latestBlock < latestBlock) {
        this.setState({ isCacheUpToDate: false });
      } else {
        this.setState({ isCacheUpToDate: true });
      }

      // GET SURVEY RESPONSES (the user responded to) & remove duplicates
      let surveyResponseHashesRaw = await contractScripts.getSurveyResponsesByAddress(
        this.props.provider,
        viewAddress
      );
      let surveyResponseHashes = [...new Set(surveyResponseHashesRaw)];

      // Filter out surveys where user has no actual answers (all blank)
      const filteredSurveyResponseHashes = [];
      for (const surveyId of surveyResponseHashes) {
        const userHasNonBlank = await this.userHasNonBlankAnswersForSurvey(
          viewAddress,
          surveyId,
          surveyCache
        );
        if (userHasNonBlank) {
          filteredSurveyResponseHashes.push(surveyId);
        }
      }

      // GET SURVEYS CREATED (the user authored) & remove duplicates
      let surveyCreatedHashesRaw = await contractScripts.getSurveysCreatedByAddress(
        this.props.provider,
        viewAddress
      );
      let surveyCreatedHashes = [...new Set(surveyCreatedHashesRaw)];

      surveyCache.userSurveyResponses = surveyCache.userSurveyResponses || {};
      surveyCache.userSurveysCreated = surveyCache.userSurveysCreated || {};

      surveyCache.userSurveyResponses[viewAddress] = filteredSurveyResponseHashes;
      surveyCache.userSurveysCreated[viewAddress] = surveyCreatedHashes;

      this.updateCache(surveyCache);

      // Merge new survey IDs into global list
      surveyCache.surveyIDs = [
        ...new Set([
          ...surveyCache.surveyIDs,
          ...filteredSurveyResponseHashes,
          ...surveyCreatedHashes,
        ]),
      ];

      // Fetch data for new IDs
      for (let surveyId of [...filteredSurveyResponseHashes, ...surveyCreatedHashes]) {
        if (!surveyCache.arweaveContent[surveyId]) {
          const surveyData = await contractScripts.getSurveyDataById(
            this.props.provider,
            surveyId
          );
          if (surveyData) {
            surveyCache.arweaveContent[surveyId] = surveyData;
            this.updateCache(surveyCache);
          }
        }
      }

      if (this._isMounted) {
        this.setState({
          loadingSurveys: false,
        });
        this.setState({
          surveysResponded: filteredSurveyResponseHashes.length,
          surveysCreated: surveyCreatedHashes.length,
        });
        // Load the basic info
        this.getSurveyInfoFromHashes(filteredSurveyResponseHashes, surveyCreatedHashes);

        // NEW: Also load detailed question-level responses for each responded survey
        this.loadDetailedSurveyResponses(filteredSurveyResponseHashes);
      }

      // Finally, update cache's latestBlock
      surveyCache.latestBlock = latestBlock;
      this.updateCache(surveyCache);
    } catch (error) {
      console.error("Error fetching survey hashes: ", error);
      if (this._isMounted) {
        this.setState({ loadingSurveys: false });
      }
    }
  }

  userHasNonBlankAnswersForSurvey = async (address, surveyId, surveyCache) => {
    try {
      // Check if local storage has a detailed record of responses for that survey
      const networkID = this.props.network?.id?.toString();
      if (!networkID) return false;

      if (
        surveyCache[networkID] &&
        surveyCache[networkID].surveyResponses &&
        surveyCache[networkID].surveyResponses[surveyId.toLowerCase()] &&
        surveyCache[networkID].surveyResponses[surveyId.toLowerCase()][address.toLowerCase()]
      ) {
        const respObj =
          surveyCache[networkID].surveyResponses[surveyId.toLowerCase()][address.toLowerCase()];
        if (respObj && respObj.responses && respObj.responses.length > 0) {
          for (const r of respObj.responses) {
            // If any question was actually answered with a non-empty 'value'
            if (r.answer.value && r.answer.value !== '' && r.answer.value !== '*') {
              return true;
            }
          }
          return false;
        }
      }
      // If no detailed record is found in cache, do a direct chain call:
      const userResponse = await contractScripts.getSurveyResponse(
        this.props.provider,
        address,
        surveyId
      );
      if (userResponse && userResponse.responses && userResponse.responses.length > 0) {
        for (const r of userResponse.responses) {
          if (r.answer.value && r.answer.value !== '' && r.answer.value !== '*') {
            return true;
          }
        }
        return false;
      }
      return false;
    } catch (err) {
      console.error('Error in userHasNonBlankAnswersForSurvey:', err);
      return false;
    }
  }

  getSurveyInfoFromHashes = async (surveyResponseHashes, surveyCreatedHashes) => {
    const surveyResponseInfo = [];
    const surveyCreationInfo = [];

    try {
      let surveyCache = JSON.parse(localStorage.getItem('surveyCache')) || {
        surveyIDs: [],
        questionIDs: [],
        questionResponses: {},
        arweaveContent: {},
      };

      for (const surveyId of surveyResponseHashes) {
        const surveyData = surveyCache.arweaveContent[surveyId];
        if (surveyData) {
          surveyResponseInfo.push({
            title: surveyData.title,
            questionsCount: surveyData.questionIDs.length,
            id: surveyId,
          });
        } else {
          // fallback fetch
          const fetchedData = await contractScripts.getSurveyDataById(
            this.props.provider,
            surveyId
          );
          if (fetchedData) {
            surveyResponseInfo.push({
              title: fetchedData.title,
              questionsCount: fetchedData.questionIDs.length,
              id: surveyId,
            });
            surveyCache.arweaveContent[surveyId] = fetchedData;
            this.updateCache(surveyCache);
          }
        }
      }

      for (const surveyId of surveyCreatedHashes) {
        const surveyData = surveyCache.arweaveContent[surveyId];
        if (surveyData) {
          surveyCreationInfo.push({
            title: surveyData.title,
            questionsCount: surveyData.questionIDs.length,
            id: surveyId,
          });
        } else {
          // fallback fetch
          const fetchedData = await contractScripts.getSurveyDataById(
            this.props.provider,
            surveyId
          );
          if (fetchedData) {
            surveyCreationInfo.push({
              title: fetchedData.title,
              questionsCount: fetchedData.questionIDs.length,
              id: surveyId,
            });
            surveyCache.arweaveContent[surveyId] = fetchedData;
            this.updateCache(surveyCache);
          }
        }
      }

      if (this._isMounted) {
        this.setState({ surveyResponseInfo, surveyCreationInfo });
      }
    } catch (error) {
      console.error("Error fetching survey data: ", error);
    }
  }

  // Load the question-level responses from local storage for each responded survey
  loadDetailedSurveyResponses = async (surveyResponseHashes) => {
    if (!this._isMounted) return;
    const { viewAddress } = this.props;
    const networkID = this.props.network?.id?.toString();
    if (!networkID || !viewAddress) return;

    let sCacheStr = localStorage.getItem('surveysCache');
    let surveysCache = {};
    if (sCacheStr) {
      try {
        surveysCache = JSON.parse(sCacheStr);
      } catch (e) {
        console.error('Error parsing surveysCache in loadDetailedSurveyResponses:', e);
        return;
      }
    }
    if (!surveysCache[networkID] || !surveysCache[networkID].surveyResponses) {
      return;
    }

    // We'll build an object { surveyId: [ {questionData, responseData}, ... ] }
    const newDetailed = {};
    let qCacheStr = localStorage.getItem('questionsCache');
    let questionsCache = {};
    if (qCacheStr) {
      try {
        questionsCache = JSON.parse(qCacheStr);
      } catch (e) {
        console.error('Error parsing questionsCache in loadDetailedSurveyResponses:', e);
        return;
      }
    }
    if (!questionsCache[networkID] || !questionsCache[networkID].questions) {
      questionsCache[networkID] = { questions: {}, questionResponses: {} };
    }

    for (const sId of surveyResponseHashes) {
      const lowerSId = sId.toLowerCase();
      let userResponse =
        surveysCache[networkID].surveyResponses[lowerSId]?.[viewAddress.toLowerCase()];
      if (!userResponse || !userResponse.responses || userResponse.responses.length === 0) {
        // Might do a fallback chain fetch if needed, but let's skip for now
        continue;
      }
      let questionArray = [];
      for (const r of userResponse.responses) {
        // If empty or '*' let's skip
        if (!r.answer.value || r.answer.value === '') {
          continue;
        }
        // find question data in questionsCache
        const qIdLower = r.questionID.toLowerCase();
        const qData = questionsCache[networkID].questions[qIdLower] || {
          id: r.questionID,
          type: r.type,
          prompt: r.prompt,
        };
        questionArray.push({
          questionData: qData,
          responseData: r,
        });
      }
      newDetailed[sId] = questionArray;
    }
    this.setState({ detailedSurveyResponses: newDetailed });
  };

  // -----------------------------------------------------------
  //                    QUESTIONS SECTION
  // -----------------------------------------------------------

  getQuestionHashesByAddress = async () => {
    const { viewAddress } = this.props;
    if (!viewAddress) return;
    this.setState({ loadingQuestions: true });

    try {
      let surveyCache = JSON.parse(localStorage.getItem('surveyCache')) || {
        surveyIDs: [],
        questionIDs: [],
        questionResponses: {},
        arweaveContent: {},
        latestBlock: 0,
        userSurveyResponses: {},
        userSurveysCreated: {},
        userQuestionResponses: {},
        userQuestionsCreated: {},
      };

      const latestBlock = await contractScripts.getLatestBlockNumber(this.props.provider);

      if (surveyCache.latestBlock < latestBlock) {
        this.setState({ isCacheUpToDate: false });
      } else {
        this.setState({ isCacheUpToDate: true });
      }

      surveyCache.userQuestionResponses = surveyCache.userQuestionResponses || {};
      surveyCache.userQuestionsCreated = surveyCache.userQuestionsCreated || {};

      // GET QUESTIONS CREATED (the user authored)
      let questionCreationHashesRaw = await contractScripts.getQuestionsCreatedByAddress(
        this.props.provider,
        viewAddress
      );
      let questionCreationHashes = [...new Set(questionCreationHashesRaw)];

      // GET QUESTIONS RESPONDED (the user responded)
      let questionResponseHashesRaw = await contractScripts.getQuestionResponsesByAddress(
        this.props.provider,
        viewAddress
      );
      let questionResponseHashesSet = new Set();

      // Filter out truly blank answers
      for (const qId of questionResponseHashesRaw) {
        const userHasNonBlank = await this.userHasNonBlankAnswerForQuestion(
          viewAddress,
          qId,
          surveyCache
        );
        if (userHasNonBlank) {
          questionResponseHashesSet.add(qId);
        }
      }

      let questionResponseHashes = [...questionResponseHashesSet];

      surveyCache.userQuestionResponses[viewAddress] = questionResponseHashes;
      surveyCache.userQuestionsCreated[viewAddress] = questionCreationHashes;

      this.updateCache(surveyCache);

      // Merge new question IDs
      surveyCache.questionIDs = [
        ...new Set([
          ...surveyCache.questionIDs,
          ...questionCreationHashes,
          ...questionResponseHashes,
        ]),
      ];

      // Fetch question data for new IDs
      for (let questionId of [...questionCreationHashes, ...questionResponseHashes]) {
        if (!surveyCache.arweaveContent[questionId]) {
          const questionData = await contractScripts.getQuestionData(
            this.props.provider,
            questionId
          );
          if (questionData) {
            surveyCache.arweaveContent[questionId] = questionData;
            this.updateCache(surveyCache);
          }
        }
      }

      this.getQuestionInfoFromHashes(questionCreationHashes, questionResponseHashes);

      // Also fetch the actual responses so we can show them in SingleQuestionResponse (mini)
      await this.loadDetailedQuestionResponses(questionResponseHashes);

      // Update cache's latestBlock
      surveyCache.latestBlock = latestBlock;
      this.updateCache(surveyCache);

      if (this._isMounted) {
        this.setState({ loadingQuestions: false });
      }
    } catch (error) {
      console.error("Error fetching question hashes: ", error);
      if (this._isMounted) {
        this.setState({ loadingQuestions: false });
      }
    }
  }

  userHasNonBlankAnswerForQuestion = async (address, questionId, surveyCache) => {
    try {
      const networkID = this.props.network?.id?.toString();
      if (!networkID) return false;

      if (
        surveyCache[networkID] &&
        surveyCache[networkID].questionResponses &&
        surveyCache[networkID].questionResponses[questionId.toLowerCase()] &&
        surveyCache[networkID].questionResponses[questionId.toLowerCase()][address.toLowerCase()]
      ) {
        const respObj =
          surveyCache[networkID].questionResponses[questionId.toLowerCase()][
            address.toLowerCase()
          ];
        // Single question object or some structure
        if (respObj && respObj.answer && respObj.answer.value) {
          if (respObj.answer.value !== '' && respObj.answer.value !== '*') {
            return true;
          }
        }
        return false;
      }
      // Fallback: chain fetch
      const chainResp = await contractScripts.getResponse(
        this.props.provider,
        address,
        questionId
      );
      if (chainResp && chainResp.answer && chainResp.answer.value) {
        if (chainResp.answer.value !== '' && chainResp.answer.value !== '*') {
          return true;
        }
      }
      return false;
    } catch (err) {
      console.error('Error in userHasNonBlankAnswerForQuestion:', err);
      return false;
    }
  }

  getQuestionInfoFromHashes = async (questionCreationHashes, questionResponseHashes) => {
    const questionCreationInfo = [];
    const questionResponseInfo = [];

    try {
      let surveyCache = JSON.parse(localStorage.getItem('surveyCache')) || {
        surveyIDs: [],
        questionIDs: [],
        questionResponses: {},
        arweaveContent: {}
      };

      for (const questionId of questionCreationHashes) {
        const questionData = surveyCache.arweaveContent[questionId];
        if (questionData) {
          questionCreationInfo.push({
            prompt: questionData.prompt,
            type: questionData.type,
            id: questionId,
          });
        } else {
          // fallback fetch
          const fetchedData = await contractScripts.getQuestionData(
            this.props.provider,
            questionId
          );
          if (fetchedData) {
            questionCreationInfo.push({
              prompt: fetchedData.prompt,
              type: fetchedData.type,
              id: questionId,
            });
            surveyCache.arweaveContent[questionId] = fetchedData;
            this.updateCache(surveyCache);
          }
        }
      }

      for (const questionId of questionResponseHashes) {
        const questionData = surveyCache.arweaveContent[questionId];
        if (questionData) {
          questionResponseInfo.push({
            prompt: questionData.prompt,
            type: questionData.type,
            id: questionId,
          });
        } else {
          // fallback fetch
          const fetchedData = await contractScripts.getQuestionData(
            this.props.provider,
            questionId
          );
          if (fetchedData) {
            questionResponseInfo.push({
              prompt: fetchedData.prompt,
              type: fetchedData.type,
              id: questionId,
            });
            surveyCache.arweaveContent[questionId] = fetchedData;
            this.updateCache(surveyCache);
          }
        }
      }

      if (this._isMounted) {
        this.setState({ questionCreationInfo, questionResponseInfo });
      }
    } catch (error) {
      console.error("Error fetching question data: ", error);
    }
  }

  // Load the single-question user responses so we can show them inline in "mini" mode
  loadDetailedQuestionResponses = async (questionResponseHashes) => {
    if (!this._isMounted) return;
    const { viewAddress } = this.props;
    const networkID = this.props.network?.id?.toString();
    if (!networkID || !viewAddress) return;

    let qCacheStr = localStorage.getItem('questionsCache');
    let questionsCache = {};
    if (qCacheStr) {
      try {
        questionsCache = JSON.parse(qCacheStr);
      } catch (e) {
        console.error('Error parsing questionsCache in loadDetailedQuestionResponses:', e);
        return;
      }
    }
    if (!questionsCache[networkID]) {
      questionsCache[networkID] = { questions: {}, questionResponses: {} };
    }
    const newDetailedQR = { ...this.state.detailedQuestionResponses };

    // For each questionID, see if there's a response from the user
    for (const qId of questionResponseHashes) {
      const qIdLower = qId.toLowerCase();
      const userResp =
        questionsCache[networkID].questionResponses[qIdLower]?.[viewAddress.toLowerCase()];
      if (!userResp || !userResp.answer.value || userResp.answer.value === '*') {
        continue;
      }
      newDetailedQR[qId] = userResp; // Store the entire response object
    }

    this.setState({ detailedQuestionResponses: newDetailedQR });
  };

  // -----------------------------------------------------------
  //               CHECK CACHE LATEST BLOCK
  // -----------------------------------------------------------
  checkCacheLatestBlock = async () => {
    try {
      const latestBlock = await contractScripts.getLatestBlockNumber(this.props.provider);
      let surveyCache = JSON.parse(localStorage.getItem('surveyCache')) || {};

      if (surveyCache.latestBlock && surveyCache.latestBlock === latestBlock) {
        if (this._isMounted) {
          this.setState({ isCacheUpToDate: true });
        }
      } else {
        if (this._isMounted) {
          this.setState({ isCacheUpToDate: false });
        }
        surveyCache.latestBlock = latestBlock;
        this.updateCache(surveyCache);
      }
    } catch (error) {
      console.error('Error checking cache latest block:', error);
    }
  }

  // -----------------------------------------------------------
  //               SBTs SECTION
  // -----------------------------------------------------------

  getSBTsByUserAddress = async () => {
    const { viewAddress } = this.props;
    if (!viewAddress) return;
    this.setState({ loadingSBTs: true });

    try {
      const cache = JSON.parse(localStorage.getItem('sbtCache')) || {};
      const networkID = this.props.network?.id;
      if (!networkID) {
        console.warn('Network ID is undefined, cannot proceed with SBT fetching.');
        if (this._isMounted) {
          this.setState({ loadingSBTs: false });
        }
        return;
      }

      if (!cache[networkID]) {
        cache[networkID] = {
          lastBlock: 0,
          sbtList: {},
        };
      }

      let sbtList = cache[networkID].sbtList || {};
      let userSBTs = [];

      // Check if local cache is up to date with chain
      const latestBlock = await contractScripts.getLatestBlockNumber(this.props.provider);
      if (cache[networkID].lastBlock >= latestBlock) {
        console.log("SBT cache is up to date; skipping chain fetch for newly created SBTs.");
      } else {
        console.log("SBT cache is NOT up to date; optionally fetch new events if needed.");
      }

      // Check minted/burned arrays in cache first
      Object.entries(sbtList).forEach(([sbtAddress, sbtData]) => {
        const mintedAddresses = sbtData.mintedAddresses || [];
        const burnedAddresses = sbtData.burnedAddresses || [];
        if (
          mintedAddresses.includes(viewAddress.toLowerCase()) &&
          !burnedAddresses.includes(viewAddress.toLowerCase())
        ) {
          userSBTs.push({
            sbtInfo: {
              ...sbtData.sbtInfo,
              sbtAddress: sbtAddress, // keep reference for display
            },
          });
        }
      });

      // If still no SBT found in cache, or confirm from chain, do fallback:
      if (userSBTs.length === 0) {
        const sbtAddressesFromChain = await contractScripts.getSBTsByUserAddress(
          this.props.provider,
          viewAddress
        );

        for (const item of sbtAddressesFromChain) {
          const addressLower = item.sbtAddress.toLowerCase();
          if (!sbtList[addressLower] || !sbtList[addressLower].sbtInfo) {
            const sbtInfo = await contractScripts.getSbtMetadata(
              this.props.provider,
              addressLower
            );
            // Update or create entry in sbtList
            sbtList[addressLower] = {
              ...sbtList[addressLower],
              sbtInfo: {
                ...sbtInfo,
                sbtAddress: addressLower,
              },
              mintedAddresses: [
                ...(sbtList[addressLower]?.mintedAddresses || []),
                viewAddress.toLowerCase(),
              ],
              burnedAddresses: sbtList[addressLower]?.burnedAddresses || [],
              blockNumber: latestBlock,
            };

            userSBTs.push({
              sbtInfo: {
                ...sbtInfo,
                sbtAddress: addressLower,
              },
            });
          } else {
            // If info is already in cache
            userSBTs.push({
              sbtInfo: {
                ...sbtList[addressLower].sbtInfo,
                sbtAddress: addressLower,
              },
            });
          }
        }

        cache[networkID].sbtList = sbtList;
        cache[networkID].lastBlock = latestBlock;
        localStorage.setItem('sbtCache', JSON.stringify(cache));
      }

      if (this._isMounted) {
        this.setState({ sbtList: userSBTs });
      }
    } catch (error) {
      console.error("Error fetching SBTs: ", error);
    } finally {
      if (this._isMounted) {
        this.setState({ loadingSBTs: false });
      }
    }
  }

  // -----------------------------------------------------------
  //        COPY / BOOKMARK / COLLAPSE / USERNAME
  // -----------------------------------------------------------

  copyToClipboard = () => {
    navigator.clipboard.writeText(this.props.viewAddress).then(() => {
      if (this._isMounted) {
        this.setState({ copied: true }, () => {
          setTimeout(() => {
            if (this._isMounted) {
              this.setState({ copied: false });
            }
          }, 2500);
        });
      }
    });
  }

  toggleCollapse = () => {
    this.setState(prevState => ({
      collapseOpen: !prevState.collapseOpen
    }));
  }

  openFullPage = () => {
    window.open(`/u/${this.props.viewAddress}`);
  }

  getUsernameByAddress = async () => {
    try {
      const username = await contractScripts.getUsernameByAddress(
        this.props.provider,
        this.props.viewAddress
      );
      if (this._isMounted) {
        this.setState({ username });
      }
    } catch (error) {
      console.error("Error fetching username: ", error);
    }
  }

  handleUsernameChange = (event) => {
    this.setState({ username: event.target.value, usernameError: '' });
  }

  setUsername = async () => {
    const { username } = this.state;
    try {
      await contractScripts.setUsernameForAddress(this.props.provider, username);
      if (this._isMounted) {
        this.setState({ usernameError: '' });
      }
    } catch (error) {
      console.error("Error setting username: ", error);
      if (this._isMounted) {
        this.setState({ usernameError: 'Error setting username' });
      }
    }
  }

  toggleBookmark = () => {
    // Now store in localStorage.bookmarks.users
    let bookmarksObj = JSON.parse(localStorage.getItem('bookmarks')) || { users: [] };
    const addr = this.props.viewAddress;
    if (!bookmarksObj.users) {
      bookmarksObj.users = [];
    }
    if (bookmarksObj.users.includes(addr)) {
      const index = bookmarksObj.users.indexOf(addr);
      bookmarksObj.users.splice(index, 1);
    } else {
      bookmarksObj.users.push(addr);
    }
    localStorage.setItem('bookmarks', JSON.stringify(bookmarksObj));
    this.setState({ bookmarked: !this.state.bookmarked });
  }

  checkIfBookmarked = () => {
    let bookmarksObj = JSON.parse(localStorage.getItem('bookmarks')) || { users: [] };
    if (!bookmarksObj.users) {
      bookmarksObj.users = [];
    }
    if (bookmarksObj.users.includes(this.props.viewAddress)) {
      this.setState({ bookmarked: true });
    }
  }

  analyzeUser = () => {
    // const analysis = "This is a placeholder analysis. In the future, this will be generated by AI based on the user's survey responses and collected SBTs.";
    const analysis = "";
    if (this._isMounted) {
      this.setState({ aiAnalysis: analysis, showAnalysisModal: true });
    }
  }

  getExplorerUrl = () => {
    const network = this.props.network;
    const address = this.props.viewAddress;
    const chainId = network.chainId;

    const getLink = (addr, cId) => {
      if (cId === 8543) {
        return `https://basescan.org/address/${addr}`;
      } else if (cId === 84532) {
        return `https://sepolia.basescan.org/address/${addr}`;
      }
      return `https://sepolia.basescan.org/address/${addr}`;
    };

    return getLink(address, chainId);
  }

  // Toggle expanded state for a specific responded survey
  toggleSurveyResponses = (surveyId) => {
    this.setState((prevState) => ({
      expandedSurveyResponses: {
        ...prevState.expandedSurveyResponses,
        [surveyId]: !prevState.expandedSurveyResponses[surveyId],
      },
    }));
  };

  // -----------------------------------------------------------
  //                       RENDER
  // -----------------------------------------------------------

  render() {
    const {
      viewAddress,
      surveyResponseInfo,
      surveyCreationInfo,
      questionCreationInfo,
      questionResponseInfo,
      userStats,
      copied,
      collapseOpen,
      username,
      usernameError,
      bookmarked,
      sbtList,
      loadingSBTs,
      loadingSurveys,
      loadingQuestions,
      showAnalysisModal,
      aiAnalysis,
      showFullProfileModal,
      isSimulated,
      selectedTab,
      expandedSurveyResponses,
      detailedSurveyResponses,
      detailedQuestionResponses,
    } = this.state;

    const { minimized, account } = this.props;
    const addressDisplay = isSimulated
      ? username
      : proposalScripts.getShortenedAddress(viewAddress);

    const surveysQuestionsToggle = (
      <>
        {!minimized && (
          <div className={styles.tabContainer}>
            <button
              className={selectedTab === 'surveys' ? styles.activeTab : ''}
              onClick={() => this.setState({ selectedTab: 'surveys' })}
            >
              Surveys
            </button>
            <button
              className={selectedTab === 'questions' ? styles.activeTab : ''}
              onClick={() => this.setState({ selectedTab: 'questions' })}
            >
              Questions
            </button>
          </div>
        )}
      </>
    );

    return (
      <div className={`${styles.userPage} ${minimized ? styles.minimized : ''}`}>
        <div className={styles.header}>
          <div className={styles.userInfo}>
            <div className={styles.avatarContainer}>
              <div className={styles.avatar}></div>
            </div>
            <h1 id={styles.userPageAddress}>
              {addressDisplay}
              {isSimulated && (
                <span className={styles.simulatedBadge} id="simulatedUserTooltip">
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                </span>
              )}
              {isSimulated && (
                <UncontrolledTooltip placement="right" target="simulatedUserTooltip">
                  This is a simulated user whose answers are generated based on documents.
                </UncontrolledTooltip>
              )}
              {!isSimulated && (
                <button onClick={this.copyToClipboard} className={styles.copyButton}>
                  <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
                </button>
              )}
              {minimized && (
                <button onClick={this.openFullPage} className={styles.expandButton}>
                  <FontAwesomeIcon icon={faExpand} id={styles.expandIcon} />
                </button>
              )}

              {!minimized && (
                <a
                  href={this.getExplorerUrl()}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.explorerLink}
                >
                  <FontAwesomeIcon icon={faExternalLinkAlt} id={styles.externalLinkIcon} />
                </a>
              )}
            </h1>
          </div>

          {account === viewAddress && (
            <div className={styles.userActions}>
              <a href="/bookmarks" className={styles.bookmarksLink}>
                My Bookmarks <FontAwesomeIcon icon={faExternalLinkAlt} />
              </a>
              <div className={styles.usernameSection}>
                <input
                  id={styles.usernameInput}
                  type="text"
                  value={username}
                  onChange={this.handleUsernameChange}
                  placeholder="Enter username"
                />
                <button onClick={this.setUsername}>Set Username</button>
                {usernameError && <span className={styles.error}>{usernameError}</span>}
              </div>
            </div>
          )}
          {!minimized && (
            <button
              onClick={this.toggleBookmark}
              className={styles.bookmarkButton}
              style={{ color: bookmarked ? 'yellow' : undefined }}
            >
              <FontAwesomeIcon icon={faBookmark} />
            </button>
          )}
        </div>

        {!minimized && (
          <div className={styles.content}>
            {selectedTab === 'surveys' && (
              <div className={styles.leftColumn}>
                {surveysQuestionsToggle}
                <div className={styles.surveySection}>
                  <h2>Survey Responses:</h2>
                  {loadingSurveys ? (
                    <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
                  ) : (
                    surveyResponseInfo.map((survey, index) => {
                      const isExpanded = expandedSurveyResponses[survey.id] || false;
                      const questionArray = detailedSurveyResponses[survey.id] || []; // array of { questionData, responseData }

                      // We'll skip rendering anything if user had no non-empty responses:
                      const hasResponses = questionArray.length > 0;

                      return (
                        <div key={index} style={{ marginBottom: '15px' }}>
                          <div
                            className={styles.surveyPreview}
                            onClick={() => this.toggleSurveyResponses(survey.id)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div className={styles.surveyTitle}>{survey.title}</div>
                            <div className={styles.surveyInfo}>
                              Questions: {survey.questionsCount}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <FontAwesomeIcon
                                icon={isExpanded ? faChevronUp : faChevronDown}
                                style={{ marginRight: '5px' }}
                              />
                            </div>
                          </div>

                          {isExpanded && hasResponses && (
                            <div style={{ paddingLeft: '20px', marginTop: '5px' }}>
                              {questionArray.map((qObj, qIndex) => {
                                // qObj = { questionData, responseData }
                                // We'll show SingleQuestionResponse in "mini" mode
                                return (
                                  <SingleQuestionResponse
                                    key={qIndex}
                                    question={qObj.questionData}
                                    response={qObj.responseData}
                                    isOwnResponse={account === viewAddress}
                                    mode="mini"
                                    showImportance={true}
                                    onDecryptQuestion={() => {}}
                                  />
                                );
                              })}
                            </div>
                          )}
                          {isExpanded && !hasResponses && (
                            <div style={{ marginLeft: '20px', fontStyle: 'italic' }}>
                              No non-empty responses recorded for this survey.
                            </div>
                          )}
                        </div>
                      );
                    })
                  )}
                  <h2>Surveys Created:</h2>
                  {loadingSurveys ? (
                    <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
                  ) : (
                    surveyCreationInfo.map((survey, index) => (
                      <a
                        key={index}
                        href={`/survey/${survey.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.surveyPreview}
                      >
                        <div className={styles.surveyTitle}>{survey.title}</div>
                        <div className={styles.surveyInfo}>
                          Questions: {survey.questionsCount}
                        </div>
                      </a>
                    ))
                  )}
                </div>
              </div>
            )}

            {selectedTab === 'questions' && (
              <div className={styles.leftColumn}>
                {surveysQuestionsToggle}
                <div className={styles.questionSection}>
                  <h2>Question Responses:</h2>
                  {loadingQuestions ? (
                    <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
                  ) : (
                    questionResponseInfo.map((question, index) => {
                      // We'll see if we have a detailed response for this question
                      const userResp = detailedQuestionResponses[question.id];
                      if (!userResp) {
                        // Means user answered with blank or encrypted entirely
                        return null;
                      }

                      // We pass question as question, userResp as response, mini mode
                      const isOwnResponse = account === viewAddress;

                      return (
                        <div key={index} style={{ marginBottom: '15px' }}>
                          <SingleQuestionResponse
                            question={question}
                            response={userResp}
                            isOwnResponse={isOwnResponse}
                            mode="mini"
                            showImportance={true}
                            onDecryptQuestion={() => {}}
                          />
                        </div>
                      );
                    })
                  )}
                  <h2>Questions Created:</h2>
                  {loadingQuestions ? (
                    <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
                  ) : (
                    questionCreationInfo.map((question, index) => (
                      <a
                        key={index}
                        href={`/question/${question.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.questionPreview}
                      >
                        <div className={styles.questionPrompt}>{question.prompt}</div>
                        <div className={styles.questionInfo}>Type: {question.type}</div>
                        <div className={styles.questionID}>
                          ID: {proposalScripts.getShortenedQuestionID(question.id, false)}
                        </div>
                      </a>
                    ))
                  )}
                </div>
              </div>
            )}

            <div className={styles.rightColumn}>
              <div className={styles.sbtSection}>
                <h2>Collected SBTs:</h2>
                {loadingSBTs ? (
                  <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
                ) : (
                  <div className={styles.sbtGrid}>
                    {sbtList.map((sbtItem, index) => (
                      <SBTPage
                        key={index}
                        SBTAddress={sbtItem.sbtInfo.sbtAddress}
                        account={this.props.account}
                        provider={this.props.provider}
                        network={this.props.network}
                        miniaturized={true}
                        loginComplete={this.props.loginComplete}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {!minimized && (
          <>
            <div className={styles.actionButtons}>
              <button onClick={this.toggleCollapse} className={styles.collapseButton}>
                Compare Views{' '}
                {collapseOpen ? (
                  <FontAwesomeIcon icon={faChevronUp} />
                ) : (
                  <FontAwesomeIcon icon={faChevronDown} />
                )}
              </button>
              <button onClick={this.analyzeUser} className={styles.analyzeButton}>
                Analyze
                <sup className={styles.comingSoon}>soon</sup>
              </button>
            </div>

            <Collapse isOpen={collapseOpen}>
              <CompareAddressSection firstAddress={viewAddress} />
            </Collapse>
          </>
        )}

        {isSimulated && (
          <div className={styles.simulatedUserActions}>
            <button onClick={() => this.setState({ showFullProfileModal: true })}>
              View Simulated Responses
            </button>
            <button>View Simulated SBTs</button>
          </div>
        )}

        <Modal
          isOpen={showAnalysisModal}
          toggle={() => this.setState({ showAnalysisModal: false })}
          className={styles.modalContent}
        >
          <ModalHeader
            toggle={() => this.setState({ showAnalysisModal: false })}
            className={styles.modalHeader}
          >
            User Analysis (Placeholder)
          </ModalHeader>
          <ModalBody className={styles.modalBody}>
            <p className={styles.placeholderNote}>
              Note: This is a placeholder analysis. In the future, this feature will use AI to
              provide insights based on the user's survey responses and collected SBTs.
            </p>
            {aiAnalysis}
          </ModalBody>
        </Modal>

        <Modal
          isOpen={showFullProfileModal}
          toggle={() => this.setState({ showFullProfileModal: false })}
          size="lg"
          className={styles.modalContent}
        >
          <ModalHeader
            toggle={() => this.setState({ showFullProfileModal: false })}
            className={styles.modalHeader}
          >
            Full User Profile
          </ModalHeader>
          <ModalBody className={styles.modalBody}>
            <div className={styles.modalSummary}>
              <h3>User Summary</h3>
              <p>{aiAnalysis}</p>
            </div>
            <StatsSection
              userStats={userStats}
              collapseOpen={collapseOpen}
              toggleCollapse={this.toggleCollapse}
            />
            <div className={styles.modalSurveys}>
              <h3>Survey Responses</h3>
              {loadingSurveys ? (
                <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
              ) : (
                surveyResponseInfo.map((survey, index) => (
                  <div key={index} className={styles.surveyPreview}>
                    <div className={styles.surveyTitle}>{survey.title}</div>
                    <div className={styles.surveyInfo}>
                      Questions: {survey.questionsCount}
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className={styles.modalSBTs}>
              <h3>Collected SBTs</h3>
              {loadingSBTs ? (
                <FontAwesomeIcon icon={faSpinner} spin id={styles.loadingIcon} />
              ) : (
                sbtList.map((sbtItem, index) => (
                  <SBTPage
                    key={index}
                    SBTAddress={sbtItem.sbtInfo.sbtAddress}
                    provider={this.props.provider}
                    network={this.props.network}
                    miniaturized={true}
                    loginComplete={this.props.loginComplete}
                  />
                ))
              )}
            </div>
            {!minimized && (
              <div className={styles.modalActions}>
                <a href="/bookmarks" className={styles.bookmarksLink}>
                  My Bookmarks <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
                <a
                  href={this.getExplorerUrl()}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.explorerLink}
                >
                  View on Explorer <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
              </div>
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default UserPage;

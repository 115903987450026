import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateProposals } from '../../actions/lobbyActions.js';
import { toggleLoginModal } from '../../actions/sessionStateActions.js';

// react plugin used to create DropdownMenu for selecting items
import Select from 'react-select';
// import SubmitProposalButton from '../Buttons/SubmitProposalButton.jsx';

// Scripts
import proposalScripts from '../UpcomingMatches/proposalScripts.js';
import contractScripts from '../Buttons/contractScripts.js';

// CSS
import 'assets/css/m_w.css';
import styles from '../UpcomingMatches/UpcomingMatches.module.scss';

// Components

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardFooter, Collapse, Col } from 'reactstrap';

class MetricsCollapse extends Component {
  state = {
    visible: true,
    openedCollapses: [''],
    format: '',
    prompt: '',
  };

  componentDidMount() {}

  componentDidUpdate() {}

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
  };

  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: this.state.openedCollapses.filter(prop => prop !== collapse),
      });
    } else {
      this.setState({
        openedCollapses: [...this.state.openedCollapses, collapse],
      });
    }
  };


  launchLoginModal = (toggled) => {
    this.props.toggleLoginModal(toggled);
  }

  submitProposalTX = async () => {
    this.collapsesToggle('collapseOne');
    await contractScripts.submitProposal(
      this.props.account,
      this.props.provider,
      this.props.lobby,
      this.props.paid,
      this.state.format.value,
      this.state.prompt,
    );
  };

  

  render() {

    const TikTokIcon = proposalScripts.getProposalCardIcon('TIKTOK');
const TikTokLabel = (
  <>
    {TikTokIcon} {' TIKTOK'}
  </>
);

const ImageIcon = proposalScripts.getProposalCardIcon('IMAGE');
const ImageLabel = (
  <>
    {ImageIcon} {' IMAGE'}
  </>
); 
    var cardColor = this.state.format.value == '' ? 'blue' : proposalScripts.getProposalCardColor(this.state.format.value);

    // var warningMessageColor = this.state.format.value == 'IMAGE' ? 'image' : 'tiktok'; // TODO: for more formats
    var warningMessageColor = 'tiktok'; // TODO: for more formats

    const submitWarningMessage = this.props.loggedIn ? (
      <Button className="btn-link ml-1" color={warningMessageColor}>
        Choose format to submit
      </Button>
    ) : (
      <Button onClick={this.launchLoginModal} className="btn-link ml-1" color={warningMessageColor}>
        Log in to submit
      </Button>
    );

    const submitButton =
      this.state.format == '' || !this.props.loggedIn ? (
        submitWarningMessage
      ) : (
        <>
          <Button id={styles.submitProposalButton} className="btn-simple" color="primary" type="button" onClick={this.submitProposalTX}>
            SUBMIT
          </Button>
        </>
      );

    // relevantIcon = this.state.format != "TIKTOK" ? <i className="fas fa-image"></i> : <i className="fas fa-music"></i>; // TODO: update
    // var relevantText = this.state.format != "TIKTOK" ? "I" : "T";

    const promptPrompt = (
      <>
        <form onSubmit={this.onSubmit} id={styles.promptInputForm}>
          <label>
            Prompt 
            (Optional) - 
            {/* <div id={styles.pleaseBeKind}> Please Be Kind</div> */}
             
          </label>
          <input id={styles.promptInputField} type="text" name="prompt" onChange={this.onChange} value={this.state.prompt} />
        </form>
      </>
    );

    const selectBox = (
      <Select
        id={styles.proposalFormSelectBox}
        name="singleSelect"
        value={this.state.format}
        onChange={value => this.setState({ format: value })}
        options={[
          // { value: "0", label: ImageLabel },
          // { value: "1", label: TikTokLabel },
          { value: 'IMAGE', label: ImageLabel },
          { value: 'TIKTOK', label: TikTokLabel },
          { value: '', label: 'GIF', isDisabled: true },
          // { value: '', label: 'TWEET', isDisabled: true },
          // { value: '', label: 'VIDEO', isDisabled: true },
          // { value: "2", label: "Foobar" },
          // { value: "3", label: "Is great" }
        ]}
        placeholder="Format"
      />
    );

    // const proposalEntryDisplay = this.state.openedCollapses.includes("collapseOne") ?

    return (
      <div aria-multiselectable={true} className="card-collapse" id="accordion">
        <Card id={styles.proposalPromptCard}>
          <CardHeader id={styles.proposalPromptHeader} role="tab">
            <a
              className="d-flex"
              href="#mw"
              data-toggle="collapse"
              aria-expanded={this.state.openedCollapses.includes('collapseOne')}
              onClick={e => {
                e.preventDefault();
                this.collapsesToggle('collapseOne');
              }}
            >
              <h3 id={styles.collapseTitleProposal}>
                + ADD
                {/* + CREATE ONE */}
                {/* ADD PROPOSAL */}
                {/* <i id="collapseProposalIcon" className="fas fa-caret-down"></i>{' '} */}
              </h3>
            </a>
          </CardHeader>
          <Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes('collapseOne')}>
              <Card id={styles.enterProposalCard} data-background-color={cardColor}>
                <CardBody id={styles.collapseCardBody}>
                  {/* <h3 className="category-social"> */}
                    {selectBox}
                  {/* </h3> */}

                  {promptPrompt}

                  {/* </div> */}
                  <div className={styles.normalProposalFooter}>
                    <CardFooter id={styles.enterProposalCardFooter}>
                      {/* <span className="ml-1"> {"0x..."} </span> */}
                      <div className="">{submitButton}</div>
                    </CardFooter>
                  </div>
                </CardBody>
              </Card>
          </Collapse>
        </Card>
      </div>
    );
  }
}

MetricsCollapse.propTypes = {
  updateProposals: PropTypes.func.isRequired,
};

export default connect(null, { updateProposals, toggleLoginModal })(MetricsCollapse);

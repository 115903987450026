// QuestionFilter.jsx:

import React, { Component } from 'react';
import { FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styles from './QuestionFilter.module.scss';
import SBTFilter from '../SBTs/SBTFilter.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faChevronDown, faStar, faRobot, faSpinner } from '@fortawesome/free-solid-svg-icons';

/**
 * The QuestionFilter component can run in two modes:
 * 1) Normal “popup” mode (resultsMode = false), used in SurveyTool to filter questions in “Questions” tab.
 * 2) “resultsMode” (resultsMode = true), used inline in SurveyResults.jsx for “Questions” mode results filtering.
 *
 * When resultsMode = true, we embed this filter as a collapsible area inside SurveyResults, meaning:
 *   - We do not pop open a separate Modal; we display the filter inline.
 *   - Filter changes are applied immediately so the user sees the changes in real time.
 *
 * If you pass in a `filterState` prop (object) from the parent, we pick it up in the constructor (if resultsMode = true)
 * so that the user’s selected filters persist across opens/closes.
 *
 * The parent can pass `onFilter(filteredQuestions, newFilterState)` to receive the updated filtered set
 * plus the new filter state whenever changes occur. (We do this automatically now whenever a filter changes.)
 */

// We preserve your existing list of question types:
const questionTypes = ['freeform', 'multichoice', 'rating', 'binary'];

const modalStyles = {
  backgroundColor: 'white',
  fontSize: '16px',
};

class QuestionFilter extends Component {
  constructor(props) {
    super(props);

    // If we’re in resultsMode and have an existing filterState, initialize from that.
    const usingResultsMode = this.props.resultsMode || false;
    const existingFilterState =
      usingResultsMode && this.props.filterState ? this.props.filterState : {};

    this.state = {
      // Basic question type filtering
      selectedTypes: existingFilterState.selectedTypes || this.props.initialSelectedTypes || [],

      // Sorting by “importance”
      sortByImportance: existingFilterState.sortByImportance || false,

      // SBT-based filtered questions we store after applying an SBTFilter
      sbtFilteredQuestions: null,
      filterLoading: false,

      // For collapsible sections in the UI
      expandedSections: {
        types: true,
        sbts: false,
        popular: false,
        ai: false,
        tags: false // <--- New tags section
      },

      // AI search query
      aiSearchQuery: existingFilterState.aiSearchQuery || '',

      // “pending” states for popup mode
      pendingSelectedTypes:
        existingFilterState.selectedTypes || this.props.initialSelectedTypes || [],
      pendingSortByImportance: existingFilterState.sortByImportance || false,
      pendingSbtFilteredQuestions: null,

      // For “Most Popular” (i.e. top # of questions by total importance or total responses)
      pendingShowTopQuestions: existingFilterState.showTopQuestions || false,
      pendingTopQuestionsCount: existingFilterState.topQuestionsCount || 10,
      showTopQuestions: existingFilterState.showTopQuestions || false,
      topQuestionsCount: existingFilterState.topQuestionsCount || 10,

      // We add a new separate option for “Top X by # of responses”
      // For now, we integrate it under the same pendingShowTopQuestions check for brevity.
      // If you want them distinct, you can add separate fields.
      pendingShowTopQuestionsByResponses: existingFilterState.showTopQuestionsByResponses || false,
      showTopQuestionsByResponses: existingFilterState.showTopQuestionsByResponses || false,

      // For displaying the # of filtered questions in the title
      filteredQuestionsCount: 0,

      // We store the entire internal SBT filter state so that when the user closes and reopens the modal,
      // the chosen SBTs remain displayed.
      sbtFilterLocalState: existingFilterState.sbtFilterLocalState || null,

      // A guard to prevent repeated re-filter calls
      lastAppliedFilterState: null,

      // New local state for the tag-based filter
      selectedTags: existingFilterState.selectedTags || [],

      // We store questionResponses from props or load from local storage if not provided
      // to enable “top X by # of responses” in a top-level usage scenario
      cachedQuestionResponses: {},

      // For “AI Filter” demonstration
      aiModeEnabled: existingFilterState.aiModeEnabled || false
    };
  }

  componentDidMount() {
    console.log('QuestionFilter network:', this.props.network);

    /**
     * If the developer wants to persist filter data, they can pass `enableLocalStorage={true}`.
     * We’ll load from localStorage. If not, we skip it, which means each refresh resets filters.
     */
    if (this.props.enableLocalStorage) {
      this.loadFilterStateFromLocalStorage();
    }

    // Attempt to load question responses if not passed
    if (!this.props.questionResponses) {
      this.loadQuestionResponsesFromLocalStorage();
    } else {
      // If we do have questionResponses from props, store them in our state for convenience
      this.setState({ cachedQuestionResponses: this.props.questionResponses });
    }

    // Calculate initial # of filtered questions
    this.computeFilteredQuestionsCount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.questions !== this.props.questions ||
      prevState.pendingSelectedTypes !== this.state.pendingSelectedTypes ||
      prevState.pendingSortByImportance !== this.state.pendingSortByImportance ||
      prevState.pendingSbtFilteredQuestions !== this.state.pendingSbtFilteredQuestions ||
      prevState.aiSearchQuery !== this.state.aiSearchQuery ||
      prevState.pendingShowTopQuestions !== this.state.pendingShowTopQuestions ||
      prevState.pendingTopQuestionsCount !== this.state.pendingTopQuestionsCount ||
      prevState.pendingShowTopQuestionsByResponses !==
        this.state.pendingShowTopQuestionsByResponses ||
      prevState.selectedTags !== this.state.selectedTags ||
      prevState.aiModeEnabled !== this.state.aiModeEnabled
    ) {
      this.computeFilteredQuestionsCount();
    }

    // If localStorage is enabled, we can store the new state:
    if (this.props.enableLocalStorage) {
      this.saveFilterStateToLocalStorage();
    }
  }

  /**
   * loadFilterStateFromLocalStorage: reads any saved filter state from localStorage
   * under different keys for "questions" vs "results".
   */
  loadFilterStateFromLocalStorage() {
    try {
      // If the user wants different local storage for different modes, e.g. "questions" vs. "results"
      const modeKey =
        this.props.filterType === 'results'
          ? 'questionFilterState_results'
          : 'questionFilterState_questions'; // default to "questions" if not specified

      const saved = localStorage.getItem(modeKey);
      if (saved) {
        const parsed = JSON.parse(saved);

        // Merge relevant fields, but do NOT override props-based initial states if not present
        this.setState((prevState) => ({
          selectedTypes: parsed.selectedTypes || prevState.selectedTypes,
          sortByImportance:
            typeof parsed.sortByImportance === 'boolean'
              ? parsed.sortByImportance
              : prevState.sortByImportance,
          showTopQuestions:
            typeof parsed.showTopQuestions === 'boolean'
              ? parsed.showTopQuestions
              : prevState.showTopQuestions,
          topQuestionsCount: parsed.topQuestionsCount || prevState.topQuestionsCount,
          aiSearchQuery: parsed.aiSearchQuery || prevState.aiSearchQuery,
          sbtFilterLocalState: parsed.sbtFilterLocalState || prevState.sbtFilterLocalState,
          selectedTags: parsed.selectedTags || prevState.selectedTags,
          showTopQuestionsByResponses:
            typeof parsed.showTopQuestionsByResponses === 'boolean'
              ? parsed.showTopQuestionsByResponses
              : prevState.showTopQuestionsByResponses,
          aiModeEnabled:
            typeof parsed.aiModeEnabled === 'boolean'
              ? parsed.aiModeEnabled
              : prevState.aiModeEnabled,
          // For pending states (if we are in popup mode)
          pendingSelectedTypes: parsed.selectedTypes || prevState.pendingSelectedTypes,
          pendingSortByImportance:
            typeof parsed.sortByImportance === 'boolean'
              ? parsed.sortByImportance
              : prevState.pendingSortByImportance,
          pendingShowTopQuestions:
            typeof parsed.showTopQuestions === 'boolean'
              ? parsed.showTopQuestions
              : prevState.pendingShowTopQuestions,
          pendingTopQuestionsCount: parsed.topQuestionsCount || prevState.pendingTopQuestionsCount,
          pendingShowTopQuestionsByResponses:
            typeof parsed.showTopQuestionsByResponses === 'boolean'
              ? parsed.showTopQuestionsByResponses
              : prevState.pendingShowTopQuestionsByResponses,
        }));
      }
    } catch (error) {
      console.error('Error loading filter state from localStorage:', error);
    }
  }

  /**
   * saveFilterStateToLocalStorage: saves the current filter state whenever it changes.
   * If you'd like to separate "results" from "questions," we store them under different keys.
   */
  saveFilterStateToLocalStorage() {
    try {
      const {
        selectedTypes,
        sortByImportance,
        showTopQuestions,
        topQuestionsCount,
        aiSearchQuery,
        sbtFilterLocalState,
        selectedTags,
        showTopQuestionsByResponses,
        aiModeEnabled
      } = this.state;

      const dataToStore = {
        selectedTypes,
        sortByImportance,
        showTopQuestions,
        topQuestionsCount,
        aiSearchQuery,
        sbtFilterLocalState,
        selectedTags,
        showTopQuestionsByResponses,
        aiModeEnabled
      };

      // Distinguish by filterType if desired
      const modeKey =
        this.props.filterType === 'results'
          ? 'questionFilterState_results'
          : 'questionFilterState_questions';

      localStorage.setItem(modeKey, JSON.stringify(dataToStore));
    } catch (error) {
      console.error('Error saving filter state to localStorage:', error);
    }
  }

  // Attempt to load questionResponses from local storage if not provided by props
  loadQuestionResponsesFromLocalStorage() {
    console.log('loadQuestionResponsesFromLocalStorage() invoked');
    if (!this.props.network || !this.props.network.id) {
      console.warn('Network ID is undefined, cannot load questionResponses from local storage.');
      return;
    }
    try {
      const networkID = this.props.network.id.toString();
      let questionsCacheStr = localStorage.getItem('questionsCache');
      let questionsCache = {};
      if (questionsCacheStr) {
        try {
          questionsCache = JSON.parse(questionsCacheStr);
        } catch (e) {
          console.error('Error parsing questionsCache:', e);
          questionsCache = {};
        }
      }

      if (!questionsCache[networkID]) {
        console.warn('No questionResponses found in local storage for this network:', networkID);
        return;
      }

      // questionResponses is at questionsCache[networkID].questionResponses
      const cachedResponses = questionsCache[networkID].questionResponses || {};
      this.setState({ cachedQuestionResponses: cachedResponses });
    } catch (error) {
      console.error('Error in loadQuestionResponsesFromLocalStorage:', error);
    }
  }

  parseResponse = (responseData) => {
    try {
      return typeof responseData === 'string' ? JSON.parse(responseData) : responseData;
    } catch (error) {
      console.error('Error parsing response data in QuestionFilter:', error);
      return null;
    }
  };

  setFilterLoading = (loading) => {
    this.setState({ filterLoading: loading });
    if (this.props.setFilterLoading) {
      this.props.setFilterLoading(loading);
    }
  };

  // This method receives the result from SBTFilter. We can get an array of questions
  // or a combined object { filteredQuestions, filteredResponsesByQuestion } if mode=creatorAndResponder.
  handleFilteredQuestions = (filtered, newSbtFilterLocalState) => {
    let realFilteredQuestions = [];
    let filteredResponsesByQuestion = {};

    if (Array.isArray(filtered)) {
      // Normal case: just an array of question objects
      realFilteredQuestions = filtered;
    } else if (
      filtered &&
      filtered.filteredQuestions &&
      Array.isArray(filtered.filteredQuestions)
    ) {
      // "creatorAndResponder" combined object
      realFilteredQuestions = filtered.filteredQuestions;
      filteredResponsesByQuestion = filtered.filteredResponsesByQuestion || {};
    }

    // Store local state if needed:
    this.setState(
      {
        pendingSbtFilteredQuestions: realFilteredQuestions,
        sbtFilterLocalState: newSbtFilterLocalState,
      },
      () => {
        // Then apply normal question-type filters or AI filters, etc.
        this.handleApplyFilters(true);
      }
    );

    // Additionally, if our parent wants the raw combined result, we can pass it along:
    if (this.props.onFilter) {
      const newFilterState = {
        ...this.getCurrentFilterState(),
        sbtFilterLocalState: newSbtFilterLocalState
      };

      if (
        filteredResponsesByQuestion &&
        Object.keys(filteredResponsesByQuestion).length > 0
      ) {
        this.props.onFilter(
          {
            filteredQuestions: realFilteredQuestions,
            filteredResponsesByQuestion
          },
          newFilterState
        );
      } else {
        // Otherwise, pass the array
        this.props.onFilter(realFilteredQuestions, newFilterState);
      }
    }
  };

  // Additional callbacks if using “creatorAndResponder” mode (optional):
  handleFilterCreators = (allowedQuestions, filterState) => {
    console.log('handleFilterCreators received:', allowedQuestions);
  };

  handleFilterResponders = (allowedResponses, filterState) => {
    console.log('handleFilterResponders received:', allowedResponses);
  };

  getCurrentFilterState = () => {
    return {
      selectedTypes: this.state.selectedTypes,
      sortByImportance: this.state.sortByImportance,
      showTopQuestions: this.state.showTopQuestions,
      topQuestionsCount: this.state.topQuestionsCount,
      aiSearchQuery: this.state.aiSearchQuery,
      sbtFilterLocalState: this.state.sbtFilterLocalState,
      selectedTags: this.state.selectedTags,
      showTopQuestionsByResponses: this.state.showTopQuestionsByResponses,
      aiModeEnabled: this.state.aiModeEnabled
    };
  };

  /*
   * STUB AI filtering: In the future, we might do some advanced logic
   * with an LLM or external API. For now, we return the original list.
   */
  applyAISearchFilter = (questions, aiSearchQuery) => {
    if (!this.state.aiModeEnabled || !aiSearchQuery.trim()) {
      return questions;
    }
    // STUB: you could do advanced logic here
    // Right now, let’s just pretend we do something
    return questions; // returning unchanged
  };

  /**
   * Applies both question-type filtering (freeform, multichoice, rating, binary), AI search queries,
   * showTopQuestions logic, showTopQuestionsByResponses logic, and merges the new SBT filtering (pendingSbtFilteredQuestions),
   * also filters by tags if any selected, preserving old code’s approach to passing finalQuestions to onFilter.
   */
  handleApplyFilters = (immediate = false) => {
    const { questions } = this.props;
    if (!questions) {
      return;
    }

    const newSelectedTypes = this.state.pendingSelectedTypes;
    const newSortByImportance = this.state.pendingSortByImportance;
    const newShowTopQuestions = this.state.pendingShowTopQuestions;
    const newTopQuestionsCount = this.state.pendingTopQuestionsCount;
    const newAiSearchQuery = this.state.aiSearchQuery;
    const newPendingSbtFilteredQuestions = this.state.pendingSbtFilteredQuestions;
    const newShowTopQuestionsByResponses = this.state.pendingShowTopQuestionsByResponses;
    const newSelectedTags = this.state.selectedTags;
    const newAiModeEnabled = this.state.aiModeEnabled;

    // We'll create a snapshot so we don't re-run if everything is unchanged
    const potentialFilterStateObj = {
      newSelectedTypes: [...newSelectedTypes].sort(),
      newSortByImportance,
      newShowTopQuestions,
      newTopQuestionsCount,
      newAiSearchQuery,
      newPendingSbtFilteredQuestionsLength: newPendingSbtFilteredQuestions
        ? newPendingSbtFilteredQuestions.length
        : -1,
      newShowTopQuestionsByResponses,
      newSelectedTags: [...newSelectedTags].sort(),
      newAiModeEnabled
    };

    // Compare to lastAppliedFilterState to avoid repeated loops
    if (
      this.state.lastAppliedFilterState &&
      JSON.stringify(this.state.lastAppliedFilterState) ===
        JSON.stringify(potentialFilterStateObj)
    ) {
      return;
    }

    // Mark this new snapshot
    this.setState({ lastAppliedFilterState: potentialFilterStateObj });

    // If not applying immediately, store the new filter states in local state
    if (!immediate) {
      this.setState({
        selectedTypes: newSelectedTypes,
        sortByImportance: newSortByImportance,
        showTopQuestions: newShowTopQuestions,
        topQuestionsCount: newTopQuestionsCount,
        aiSearchQuery: newAiSearchQuery,
        sbtFilteredQuestions: newPendingSbtFilteredQuestions,
        showTopQuestionsByResponses: newShowTopQuestionsByResponses,
        selectedTags: newSelectedTags,
        aiModeEnabled: newAiModeEnabled
      });
    } else {
      // If immediate, update them in one pass
      this.setState({
        selectedTypes: newSelectedTypes,
        sortByImportance: newSortByImportance,
        showTopQuestions: newShowTopQuestions,
        topQuestionsCount: newTopQuestionsCount,
        aiSearchQuery: newAiSearchQuery,
        sbtFilteredQuestions: newPendingSbtFilteredQuestions,
        showTopQuestionsByResponses: newShowTopQuestionsByResponses,
        selectedTags: newSelectedTags,
        aiModeEnabled: newAiModeEnabled
      });
    }

    // If the user chose "Show top X questions by total importance" or "by # of responses" (we handle both):
    if (newShowTopQuestions || newShowTopQuestionsByResponses) {
      let finalQuestions = [...questions];
      if (newPendingSbtFilteredQuestions !== null) {
        const sbtIds = newPendingSbtFilteredQuestions.map((q) => q.id);
        finalQuestions = finalQuestions.filter((q) => sbtIds.includes(q.id));
      }

      // Filter by question type
      if (newSelectedTypes.length > 0) {
        finalQuestions = finalQuestions.filter((q) => newSelectedTypes.includes(q.type));
      }

      // Filter by tags if selected
      if (newSelectedTags.length > 0) {
        finalQuestions = finalQuestions.filter((q) => {
          if (!q.tags || !Array.isArray(q.tags)) return false;
          // “Any match” approach
          return q.tags.some((tag) => newSelectedTags.includes(tag));
        });
      }

      // If AI search is on, apply it
      finalQuestions = this.applyAISearchFilter(finalQuestions, newAiSearchQuery);

      if (newShowTopQuestionsByResponses) {
        // We’ll sort by # of question responses
        // For each question, get how many responders
        const { cachedQuestionResponses } = this.state; // loaded from local storage or props
        const questionsWithCount = finalQuestions.map((q) => {
          const questionId = q.id.toLowerCase();
          const responsesObj = cachedQuestionResponses[questionId] || {};
          // responsesObj is { [responderAddr]: responseData }
          const count = Object.keys(responsesObj).length;
          return { ...q, totalResponses: count };
        });
        // Sort descending by totalResponses
        questionsWithCount.sort((a, b) => b.totalResponses - a.totalResponses);
        const topQuestionsByResp = questionsWithCount.slice(0, newTopQuestionsCount);

        const filterState = this.buildFilterState();
        if (this.props.onFilter) {
          this.props.onFilter(topQuestionsByResp, filterState);
        }
        this.computeFilteredQuestionsCount();
        return;
      }

      // ELSE: newShowTopQuestions by importance
      const { questionResponses } = this.props; // might be undefined, but resultsMode might pass it
      // If we don’t have questionResponses from props, we fallback to the cachedQuestionResponses
      const relevantResponses =
        questionResponses || this.state.cachedQuestionResponses || {};

      const questionsWithImportance = finalQuestions.map((q) => {
        const qIdLower = q.id.toLowerCase();
        // If relevantResponses is shaped like { questionID: { address: {...} } }, we might do:
        const respondersObj = relevantResponses[qIdLower] || {};
        let totalImportance = 0;
        Object.keys(respondersObj).forEach((responder) => {
          const respData = respondersObj[responder];
          // If respData might be a direct single-question object or “{ answer, importance }” structure
          const parsed = this.parseResponse(respData);
          const imp = parsed?.importance || parsed?.importance === 0 ? parsed.importance : 0;
          totalImportance += imp;
        });
        return { ...q, totalImportance };
      });
      questionsWithImportance.sort((a, b) => b.totalImportance - a.totalImportance);
      const topQuestions = questionsWithImportance.slice(0, newTopQuestionsCount);

      const filterState = this.buildFilterState();
      if (this.props.onFilter) {
        this.props.onFilter(topQuestions, filterState);
      }
      this.computeFilteredQuestionsCount();
      return;
    }

    // If not showTopQuestions, we do normal filtering
    let finalQuestions = [...questions];

    // SBT filter results (new approach). If we have pendingSbtFilteredQuestions, that’s the subset from SBT logic
    if (newPendingSbtFilteredQuestions !== null) {
      const sbtIds = newPendingSbtFilteredQuestions.map((q) => q.id);
      finalQuestions = finalQuestions.filter((q) => sbtIds.includes(q.id));
    }

    // Filter by question type
    if (newSelectedTypes.length > 0) {
      finalQuestions = finalQuestions.filter((q) => newSelectedTypes.includes(q.type));
    }

    // Filter by tags
    if (newSelectedTags.length > 0) {
      finalQuestions = finalQuestions.filter((q) => {
        if (!q.tags || !Array.isArray(q.tags)) return false;
        // “Any match” approach
        return q.tags.some((tag) => newSelectedTags.includes(tag));
      });
    }

    // AI text search
    if (newAiSearchQuery.trim() !== '') {
      finalQuestions = this.applyAISearchFilter(finalQuestions, newAiSearchQuery);
    }

    // Sort by importance if requested
    if (newSortByImportance) {
      const { questionResponses } = this.props;
      const relevantResponses =
        questionResponses || this.state.cachedQuestionResponses || {};
      const questionsWithImportance = finalQuestions.map((q) => {
        const qIdLower = q.id.toLowerCase();
        const respondersObj = relevantResponses[qIdLower] || {};
        let totalImportance = 0;
        Object.keys(respondersObj).forEach((responder) => {
          const respData = this.parseResponse(respondersObj[responder]);
          totalImportance += respData?.importance || 0;
        });
        return { ...q, totalImportance };
      });
      questionsWithImportance.sort((a, b) => b.totalImportance - a.totalImportance);
      finalQuestions = questionsWithImportance;
    }

    // Build final filter state
    const filterState = this.buildFilterState();
    // Pass finalQuestions to parent
    if (this.props.onFilter) {
      this.props.onFilter(finalQuestions, filterState);
    }
    // Also recalc the # of filtered questions
    this.computeFilteredQuestionsCount();
  };

  buildFilterState() {
    return {
      selectedTypes: this.state.selectedTypes,
      sortByImportance: this.state.sortByImportance,
      showTopQuestions: this.state.showTopQuestions,
      topQuestionsCount: this.state.topQuestionsCount,
      aiSearchQuery: this.state.aiSearchQuery,
      sbtFilterLocalState: this.state.sbtFilterLocalState,
      selectedTags: this.state.selectedTags,
      showTopQuestionsByResponses: this.state.showTopQuestionsByResponses,
      aiModeEnabled: this.state.aiModeEnabled
    };
  }

  toggleSection = (section) => {
    this.setState((prevState) => ({
      expandedSections: {
        ...prevState.expandedSections,
        [section]: !prevState.expandedSections[section]
      }
    }));
  };

  computeFilteredQuestionsCount = () => {
    let { questions } = this.props;
    if (!questions) {
      if (this.state.filteredQuestionsCount !== 0) {
        this.setState({ filteredQuestionsCount: 0 });
      }
      return;
    }
    const {
      pendingSelectedTypes,
      pendingSbtFilteredQuestions,
      pendingShowTopQuestions,
      pendingTopQuestionsCount,
      pendingShowTopQuestionsByResponses,
      aiSearchQuery,
      selectedTags,
      aiModeEnabled
    } = this.state;

    // If topQuestions or topQuestionsByResponses is set, we approximate the count as that slice
    if (pendingShowTopQuestions || pendingShowTopQuestionsByResponses) {
      const newCount = Math.min(questions.length, pendingTopQuestionsCount);
      if (newCount !== this.state.filteredQuestionsCount) {
        this.setState({ filteredQuestionsCount: newCount });
      }
      return;
    }

    let finalList = [...questions];

    if (pendingSbtFilteredQuestions !== null) {
      const sbtIds = pendingSbtFilteredQuestions.map((q) => q.id);
      finalList = finalList.filter((q) => sbtIds.includes(q.id));
    }

    if (pendingSelectedTypes.length > 0) {
      finalList = finalList.filter((q) => pendingSelectedTypes.includes(q.type));
    }

    // Filter by tags
    if (selectedTags.length > 0) {
      finalList = finalList.filter((q) => {
        if (!q.tags || !Array.isArray(q.tags)) return false;
        return q.tags.some((tag) => selectedTags.includes(tag));
      });
    }

    // AI search
    if (aiSearchQuery.trim() !== '' && aiModeEnabled) {
      // We just pass finalList to stub method
      finalList = this.applyAISearchFilter(finalList, aiSearchQuery);
    }

    const newLength = finalList.length;
    if (newLength !== this.state.filteredQuestionsCount) {
      this.setState({ filteredQuestionsCount: newLength });
    }
  };

  handleTypeSelection = (type) => {
    let pendingSelectedTypes = [...this.state.pendingSelectedTypes];
    if (pendingSelectedTypes.includes(type)) {
      pendingSelectedTypes = pendingSelectedTypes.filter((t) => t !== type);
    } else {
      pendingSelectedTypes.push(type);
    }
    this.setState({ pendingSelectedTypes }, () => {
      this.handleApplyFilters(true);
    });
  };

  handleTagSelection = (tag) => {
    let updatedTags = [...this.state.selectedTags];
    if (updatedTags.includes(tag)) {
      updatedTags = updatedTags.filter((t) => t !== tag);
    } else {
      updatedTags.push(tag);
    }
    this.setState({ selectedTags: updatedTags }, () => {
      this.handleApplyFilters(true);
    });
  };

  handleSortByImportance = () => {
    this.setState(
      (prevState) => ({ pendingSortByImportance: !prevState.pendingSortByImportance }),
      () => {
        this.handleApplyFilters(true);
      }
    );
  };

  handleCancelFilters = () => {
    this.setState(
      {
        pendingSelectedTypes: this.state.selectedTypes,
        pendingSortByImportance: this.state.sortByImportance,
        pendingSbtFilteredQuestions: this.state.sbtFilteredQuestions,
        pendingShowTopQuestions: this.state.showTopQuestions,
        pendingTopQuestionsCount: this.state.topQuestionsCount,
        pendingShowTopQuestionsByResponses: this.state.showTopQuestionsByResponses,
        aiSearchQuery: this.state.aiSearchQuery,
        sbtFilterLocalState: null,
        selectedTags: this.state.selectedTags,
        aiModeEnabled: this.state.aiModeEnabled
      },
      () => {
        if (!this.props.resultsMode) {
          this.props.toggleFilterModal();
        }
      }
    );
  };

  // For toggling “top X questions by importance” or “top X questions by # of responses”
  toggleShowTopQuestions = (byResponses = false) => {
    if (byResponses) {
      this.setState(
        (prev) => ({
          pendingShowTopQuestionsByResponses: !prev.pendingShowTopQuestionsByResponses,
          pendingShowTopQuestions: false // turn off the other
        }),
        () => {
          this.handleApplyFilters(true);
        }
      );
    } else {
      this.setState(
        (prev) => ({
          pendingShowTopQuestions: !prev.pendingShowTopQuestions,
          pendingShowTopQuestionsByResponses: false
        }),
        () => {
          this.handleApplyFilters(true);
        }
      );
    }
  };

  renderCollapsibleSection = (title, sectionKey, icon, content) => {
    const { expandedSections } = this.state;
    return (
      <div className={styles.filterSection}>
        <div
          className={styles.sectionHeader}
          onClick={() => this.toggleSection(sectionKey)}
          style={{ cursor: 'pointer' }}
        >
          <h3>
            <FontAwesomeIcon icon={icon} className="me-2" />
            {title}
          </h3>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={`${styles.icon} ${expandedSections[sectionKey] ? styles.expanded : ''}`}
          />
        </div>
        {expandedSections[sectionKey] && <div className={styles.sectionContent}>{content}</div>}
      </div>
    );
  };

  // Helper function to gather all unique tags from the current question set
  getAllTagsFromQuestions = () => {
    const { questions } = this.props;
    if (!questions || !Array.isArray(questions)) return [];
    const tagSet = new Set();
    questions.forEach((q) => {
      if (q.tags && Array.isArray(q.tags)) {
        q.tags.forEach((tag) => tagSet.add(tag));
      }
    });
    return Array.from(tagSet);
  };

  // ======== RENDER METHOD ========
  render() {
    const {
      pendingSelectedTypes,
      aiSearchQuery,
      filteredQuestionsCount,
      pendingShowTopQuestions,
      pendingTopQuestionsCount,
      filterLoading,
      sbtFilterLocalState,
      pendingShowTopQuestionsByResponses,
      selectedTags,
      aiModeEnabled
    } = this.state;

    const isOtherFiltersDisabled = pendingShowTopQuestions || pendingShowTopQuestionsByResponses;
    const isInline = this.props.resultsMode;
    const usingCreatorAndResponder = !!this.props.creatorAndResponderMode;

    // Gather all unique tags from questions
    const allTags = this.getAllTagsFromQuestions();

    let questionFilterToggleButton = null;
    if (usingCreatorAndResponder && isInline) {
      questionFilterToggleButton = (
        <Button
          onClick={this.props.toggleFilterModal}
          style={{ marginBottom: '10px' }}
        >
          Question Filter
        </Button>
      );
    }

    // “bodyContent” is the collapsible filter UI
    const bodyContent = (
      <div>
        {this.renderCollapsibleSection(
          'Most Popular',
          'popular',
          faStar,
          <div>
            <FormGroup>
              <Label className={styles.filterOption}>
                <Input
                  type="checkbox"
                  checked={pendingShowTopQuestions}
                  onChange={() => {
                    this.toggleShowTopQuestions(false);
                  }}
                  disabled={false}
                />
                Show top
                <Input
                  type="number"
                  min="1"
                  value={pendingTopQuestionsCount}
                  onChange={(e) => {
                    const val = e.target.value ? parseInt(e.target.value, 10) : 1;
                    this.setState({ pendingTopQuestionsCount: val }, () => {
                      if (pendingShowTopQuestions || pendingShowTopQuestionsByResponses) {
                        this.handleApplyFilters(true);
                      }
                    });
                  }}
                  disabled={!pendingShowTopQuestions && !pendingShowTopQuestionsByResponses}
                  id={styles.topQuestionsCountInput}
                />
                questions (by total importance)
              </Label>
            </FormGroup>
            <FormGroup>
              <Label className={styles.filterOption}>
                <Input
                  type="checkbox"
                  checked={pendingShowTopQuestionsByResponses}
                  onChange={() => {
                    this.toggleShowTopQuestions(true);
                  }}
                  disabled={false}
                />
                Show top
                <Input
                  type="number"
                  min="1"
                  value={pendingTopQuestionsCount}
                  onChange={(e) => {
                    const val = e.target.value ? parseInt(e.target.value, 10) : 1;
                    this.setState({ pendingTopQuestionsCount: val }, () => {
                      if (pendingShowTopQuestions || pendingShowTopQuestionsByResponses) {
                        this.handleApplyFilters(true);
                      }
                    });
                  }}
                  disabled={!pendingShowTopQuestions && !pendingShowTopQuestionsByResponses}
                  id={styles.topQuestionsCountInput}
                />
                questions (by # of responses)
              </Label>
            </FormGroup>
            {(pendingShowTopQuestions || pendingShowTopQuestionsByResponses) && (
              <small className="text-muted">
                This option supersedes other filters (type, tag, AI search).
              </small>
            )}
          </div>
        )}

        <div className={isOtherFiltersDisabled ? styles.disabledSection : ''}>
          {this.renderCollapsibleSection(
            'Question Types',
            'types',
            faFilter,
            <FormGroup>
              {questionTypes.map((type, index) => (
                <Label key={index} className={styles.filterOption}>
                  <Input
                    type="checkbox"
                    value={type}
                    checked={pendingSelectedTypes.includes(type)}
                    onChange={() => this.handleTypeSelection(type)}
                    disabled={isOtherFiltersDisabled}
                  />
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </Label>
              ))}
            </FormGroup>
          )}

          {this.renderCollapsibleSection(
            usingCreatorAndResponder
              ? 'SBT Group(s) of Question Creator or Responder'
              : 'SBT Group(s) of Question Creator',
            'sbts',
            faStar,
            isOtherFiltersDisabled ? (
              <p className={styles.disabledText}>Disabled due to “Top X questions” selection.</p>
            ) : (
              <SBTFilter
                items={this.props.questions}
                provider={this.props.provider}
                network={this.props.network}
                mode={usingCreatorAndResponder ? 'creatorAndResponder' : 'creator'}
                onFilter={this.handleFilteredQuestions}
                onFilterCreators={(creatorSubset, filterState) => this.handleFilterCreators(creatorSubset, filterState)}
                onFilterResponders={(responderSubset, filterState) => this.handleFilterResponders(responderSubset, filterState)}
                setFilterLoading={this.setFilterLoading}
                autoExpand={true}
                externalSBTFilterState={sbtFilterLocalState}
              />
            )
          )}

          {this.renderCollapsibleSection(
            'Tags',
            'tags',
            faFilter,
            isOtherFiltersDisabled ? (
              <p className={styles.disabledText}>Disabled due to “Top X questions” selection.</p>
            ) : (
              <FormGroup>
                {allTags.length === 0 ? (
                  <p>No tags found in current questions.</p>
                ) : (
                  allTags.map((tag) => (
                    <Label key={tag} className={styles.filterOption}>
                      <Input
                        type="checkbox"
                        checked={selectedTags.includes(tag)}
                        onChange={() => this.handleTagSelection(tag)}
                      />
                      {tag}
                    </Label>
                  ))
                )}
              </FormGroup>
            )
          )}

          {this.renderCollapsibleSection(
            'AI Filter',
            'ai',
            faRobot,
            isOtherFiltersDisabled ? (
              <p className={styles.disabledText}>
                Disabled due to “Top X questions” selection.
              </p>
            ) : (
              <FormGroup>
                <Label className={styles.filterOption} style={{ marginBottom: '10px' }}>
                  <Input
                    type="checkbox"
                    checked={aiModeEnabled}
                    onChange={() => {
                      this.setState({ aiModeEnabled: !aiModeEnabled }, () => {
                        this.handleApplyFilters(true);
                      });
                    }}
                  />
                  Enable AI-based Search
                </Label>
                <Input
                  type="text"
                  className={styles.searchInput}
                  placeholder="Enter AI filter criteria..."
                  value={aiSearchQuery}
                  disabled={!aiModeEnabled}
                  onChange={(e) => {
                    if (isInline) {
                      this.setState({ aiSearchQuery: e.target.value }, () => {
                        if (aiModeEnabled) {
                          this.handleApplyFilters(true);
                        }
                      });
                    } else {
                      this.setState({ aiSearchQuery: e.target.value });
                    }
                  }}
                />
                <small className="text-muted">
                  Soon: Use AI to find specific questions based on context or meaning
                </small>
              </FormGroup>
            )
          )}
        </div>

        {filterLoading && (
          <div className={styles.loadingContainer}>
            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
            <p>Applying filter...</p>
          </div>
        )}
      </div>
    );

    const hiddenSBTFilter = isInline ? (
      <div style={{ display: 'none' }}>
        <SBTFilter
          items={this.props.questions}
          provider={this.props.provider}
          network={this.props.network}
          onFilter={this.handleFilteredQuestions}
          setFilterLoading={this.setFilterLoading}
          autoExpand={true}
          externalSBTFilterState={this.state.sbtFilterLocalState}
          mode={usingCreatorAndResponder ? 'creatorAndResponder' : 'creator'}
          hideUI={true}
        />
      </div>
    ) : null;

    if (isInline) {
      // resultsMode => inline layout
      if (!this.props.showQuestionFilter && usingCreatorAndResponder) {
        return (
          <div className={styles.questionFilterInline}>
            {hiddenSBTFilter}
            {questionFilterToggleButton}
          </div>
        );
      }

      return (
        <div className={styles.questionFilterInline}>
          {hiddenSBTFilter}
          {questionFilterToggleButton}

          {/* <h5>Question Filter ({filteredQuestionsCount} match)</h5>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={this.state.pendingSortByImportance}
                onChange={this.handleSortByImportance}
                disabled={isOtherFiltersDisabled}
              />
              Sort questions by total importance
            </Label>
          </FormGroup> */}
          {bodyContent}
        </div>
      );
    } else {
      // Normal popup mode
      return (
        <Modal
          isOpen={this.props.filterModalOpen}
          toggle={this.handleCancelFilters}
          style={modalStyles}
        >
          <ModalHeader toggle={this.handleCancelFilters} className={styles.modalHeader}>
            Filter Questions ({filteredQuestionsCount})
          </ModalHeader>
          <ModalBody className={styles.modalBody}>
            {/* <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={this.state.pendingSortByImportance}
                  onChange={this.handleSortByImportance}
                  disabled={isOtherFiltersDisabled}
                />
                Sort questions by total importance
              </Label>
            </FormGroup> */}

            {bodyContent}
          </ModalBody>

          <ModalFooter>
            <Button color="primary" onClick={this.props.toggleFilterModal}>
              See Questions
            </Button>
          </ModalFooter>
        </Modal>
      );
    }
  }
}

export default QuestionFilter;
